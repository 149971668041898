import React, { FC, useEffect, useRef, useState } from 'react'
import { ProjectStatusStep } from './steps/ProjectStatusStep'

import { DLPStep } from './steps/DLPStep'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'
import { TrafficPlanningStep } from './steps/TrafficPlanningStep'
import { FinalAcceptanceStep } from './steps/FinalAcceptanceStep'
import { actions } from 'react-table'
import { FormattedMessage } from 'react-intl'
import { ShifaScopeStep } from './steps/ShifaScopeStep'
import { CostStep } from './steps/CostStep'
import { ProvisionalAcceptanceStep } from './steps/ProvisionalAcceptanceStep'
import { DesignPhase } from './steps/DesignPhase'
import { TenderPhase } from './steps/TenderPhase'
import { ConstructionPhaseStep } from './steps/ConstructionPhaseStep'
import { InfrastructureConnectionsStep } from './steps/InfrastructureConnectionsStep'
import { ShifaImplementationTableStep } from './steps/ShifaImplementationTableStep'

const Horizontal: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    stepper.current.goPrev()
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  const stepChange = (step: number) => {
    if (!stepper.current) {
      return
    }
    stepper.current.goto(step)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column '
          id='kt_mhc_checklist_monitoring_site_info_stepper'
        >
          <div className='stepper-nav mb-1'>
            <div className='stepper-item current ' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(1) }}> <FormattedMessage id="SHIFA.SCOPE" /></a>   {/*onClick={()=>{stepChange()}}*/}
            </div>

            <div className='stepper-item ' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(2) }}>{<FormattedMessage id='PROJECT.STATUS' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(3) }}>{<FormattedMessage id='COST' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(4) }}>{<FormattedMessage id='DESIGN/TENDER.PHASE' />} </a>
            </div>

            { /*<div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(5) }}>{<FormattedMessage id='TENDER.PHASE' />} </a>
            </div> */ }
            
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(5) }}>{<FormattedMessage id='CONSTRUCTION.PHASE' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(6) }}>{<FormattedMessage id='POST.CONSTRUCTION' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(7) }}>{<FormattedMessage id='SHIFA.IMP.TABLE' />}  </a>
            </div>

            {/* <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(7) }}>{<FormattedMessage id='PROVISIONAL.ACCEPTANCE' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(8) }}>{<FormattedMessage id='DLP' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(9) }}>{<FormattedMessage id='FINAL.ACCEPTANCE' />} </a>
            </div> */}
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto  w-100 overflow-scroll ' id='kt_mhc_checklist_monitoring_site_info_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <ShifaScopeStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <ProjectStatusStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <CostStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <DesignPhase />
                </div>

                {/* <div data-kt-stepper-element='content'>
                  <TenderPhase />
                </div> */}

                <div data-kt-stepper-element='content'>
                  <ConstructionPhaseStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <InfrastructureConnectionsStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <ShifaImplementationTableStep />
                </div>

                {/* <div data-kt-stepper-element='content'>
                  <ProvisionalAcceptanceStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <DLPStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <FinalAcceptanceStep />
                </div> */}

              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { Horizontal }
