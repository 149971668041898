import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const ContractedConstructionCostEur: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.contractedConstructionCostEUR != null && site.contractedConstructionCostEUR != undefined && formatEUR(site.contractedConstructionCostEUR.toFixed(2))}
    </div>
  )
}
export { ContractedConstructionCostEur }
