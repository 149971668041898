import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'

import { SiteModel } from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { Electric } from './Electric'
import { Water } from './Water'
import { WasteWaterSewage } from './WasteWaterSewage'
import { NaturalGas } from './NaturalGas'
import { Telecom } from './Telecom'
import { ProvisionalAcceptance } from './ProvisionalAcceptance'
import { DefectLiabilityPeriod } from './DefectLiabilityPeriod'
import { FinalAcceptance } from './FinalAcceptance'
import { SiteNumberIdentificationCell } from '../../shifa-scope-table/columns/SiteNumberIdentificationCell'
import { BuildingNameCell } from '../../shifa-scope-table/columns/BuildingNameCell'


const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.SITE.NUMBER.IDENTIFICATION'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <SiteNumberIdentificationCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'ELECTRIC'/>}  className='min-w-200px' />,
    id: 'electric',
    Cell:({...props}) => <Electric site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'WATER'/>} className='min-w-200px' />,
    id: 'water',
    Cell:({...props}) => <Water site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'WASTE.WATER.SEWAGE'/>} className='min-w-200px' />,
    id: 'wasteWater',
    Cell:({...props}) => <WasteWaterSewage site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'NATURAL.GAS'/>} className='min-w-200px' />,
    id: 'naturalGas',
    Cell:({...props}) => <NaturalGas site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'TELECOM'/>} className='min-w-200px' />,
    id: 'telecom',
    Cell:({...props}) => <Telecom site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'TECHNICAL.INSPECTION.REMEDY.DEFECTS.AND.CERTIFICATIONS'/>}className='min-w-200px' />,
    id: 'tirdac',
    Cell:({...props}) => <ProvisionalAcceptance site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'DEFECT.LIABILITY.PERIOD'/>}className='min-w-200px' />,
    id: 'defectLiabilityPeriod',
    Cell:({...props}) => <DefectLiabilityPeriod site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'TECHNICAL.INSPECTION.REMEDY.DEFECTS.AND.CERTIFICATIONS'/>}className='min-w-200px' />,
    id: 'FinalAcceptance',
    Cell:({...props}) => <FinalAcceptance site={props.data[props.row.index]} />, 
  }
]

export {sitesColumns}
