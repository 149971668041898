import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {CommunicationStrategyListHeader} from './components/header/CommunicationStrategyListHeader'
import {CommunicationStrategyEditModal} from './communication-strategy-modal/CommunicationStrategyEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { CommunicationStrategyTable } from './table/CommunicationStrategyTable'
import { CommunicationStrategyDeleteModal } from '../CommunicationStrategyDeleteModal'

const CommunicationStrategyList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <CommunicationStrategyListHeader />
        <CommunicationStrategyTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <CommunicationStrategyEditModal />}
      {itemIdForDelete !== undefined && <CommunicationStrategyDeleteModal />}
    </>
  )
}

const CommunicationStrategyListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CommunicationStrategyList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CommunicationStrategyListWrapper}
