import { FC } from 'react'
import { ProjectReportsListModel } from '../../core/_models'

type Props = {
  approvalDate?: string
}

function formatDate(date: string | undefined) {
  if(date && date != "0001-01-01T00:00:00") {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [day, month, year].join('/');
  }
}

const ApprovalDateCell: FC<Props> = ({ approvalDate }) => (
  <div className='d-flex align-items-center'>
    <a className='text-gray-800 text-hover-primary mb-1'>
      {formatDate(approvalDate)}
    </a>
  </div>
)

export { ApprovalDateCell }
