import {Column} from 'react-table'
import {Contest_SiteEventDescriptionCell} from './Contest_SiteEventDescriptionCell'
import {Contest_SiteEventActionsCell} from './Contest_SiteEventActionsCell'
import {Contest_SiteEventCustomHeader} from './Contest_SiteEventCustomHeader'
import {Contest_SiteEventModel} from '../../core/_models'
import { Contest_SiteEventVersionDateCell } from './Contest_SiteEventVersionDateCell'
import { Contest_SiteEventFileNameCell } from './Contest_SiteEventFileNameCell'
import { FormattedMessage } from 'react-intl'
import { Contest_SiteEventLinkCell } from './Contest_SiteEventLinkCell'

var contest_SiteEventColumns: ReadonlyArray<Column<Contest_SiteEventModel>> = [

  {
    Header: (props) => <Contest_SiteEventCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <Contest_SiteEventDescriptionCell contest_SiteEvent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <Contest_SiteEventCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <Contest_SiteEventVersionDateCell contest_SiteEvent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <Contest_SiteEventCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <Contest_SiteEventFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <Contest_SiteEventCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <Contest_SiteEventLinkCell contest_SiteEvent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <Contest_SiteEventCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <Contest_SiteEventActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {contest_SiteEventColumns}
