import { Dispatch, SetStateAction } from 'react'
import { ID, Response } from '../../../../../_metronic/helpers'

export type BugReportingListModel = {
  id?: ID,
  siteName?: string,
  contractName?: string,
  lotName?: string,
  bugreportingTypeName?: string,
  bugreportingTypeEnglishName?: string,
  subject?: string,
  summary?: string,
  reportDate?: string,
  approvedDate?: string,
}


export type BugReportingModel = {
  id?: ID,
  title: string,
  context: string,
  addAttachments: Array<any>,
  files?:Array<FileInfoModel>,
  createdBy?: ID,
  updatedBy?: ID
}

export type BugReportingModelDTO = {
  id?: ID,
  title: string,
  context: string,
  files?:Array<FileInfoModel>,
  createdBy?: ID,
  updatedBy?: ID
}

export type  FileInfoModel = {
  fileId?: ID,
  fileName?:string,
  group?: number
}

export type  FileInfoList = {
  fileId?: ID,
  fileName?: string,
  group?: number
}


export type BugReportingsQueryResponse = Response<Array<BugReportingListModel>>

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  fileModelList: Array<FileInfoModel>
  setFileModelList: Dispatch<SetStateAction<Array<FileInfoModel>>>
  mainFile: FileInfoModel
  setMainFile: Dispatch<SetStateAction<FileInfoModel>>
  itemIdForDelete?: ID
  setItemIdForDelete: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDelete: () => { },
  isAllSelected: false,
  setFileModelList: () => { },
  fileModelList: [],
  mainFile: {
    fileId: "",
    fileName: "",
    group: 0
  },
  setMainFile: () => {}
}


