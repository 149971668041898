import { Column } from 'react-table'
import { ProjectReportsActionsCell } from './ProjectReportsActionsCell'
import { ProjectReportsCustomHeader } from './ProjectReportsCustomHeader'
import { ProjectReportsListModel } from '../../core/_models'
import { ReportTypeCell } from './ReportTypeCell'
import { SubmissionDateCell } from './SubmissionDateCell'
import { ReportNoCell } from './ReportNoCell'
import { ApprovalDateCell } from './ApprovalDateCell'
import { FormattedMessage, useIntl } from 'react-intl'
import { ReportingPeriodStartDateCell } from './ReportingPeriodStartDateCell'
import { ReportingPeriodEndDateCell } from './ReportingPeriodEndDateCell'


var cipsColumns: ReadonlyArray<Column<ProjectReportsListModel>> = [

  {
    Header: (props) => <ProjectReportsCustomHeader tableProps={props} title={<FormattedMessage id="REPORT.TYPE" />} className='min-w-200px' />,
    id: 'reportType',
    Cell: ({ ...props }) => <ReportTypeCell data={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <ProjectReportsCustomHeader tableProps={props} title={<FormattedMessage id="REPORT.NO" />} className='min-w-300px' />,
    id: 'reportNo',
    Cell: ({ ...props }) => <ReportNoCell reportNo={props.data[props.row.index].reportNo} />,
  },

  {
    Header: (props) => <ProjectReportsCustomHeader tableProps={props} title={<FormattedMessage id="SUBMISSION.DATE" />} className='min-w-300px' />,
    id: 'submissionDate',
    Cell: ({ ...props }) => <SubmissionDateCell submissionDate={props.data[props.row.index].submissionDate} />,
  },
  {
    Header: (props) => <ProjectReportsCustomHeader tableProps={props} title={<FormattedMessage id="APPROVAL.DATE" />} className='min-w-300px' />,
    id: 'approvalDate',
    Cell: ({ ...props }) => < ApprovalDateCell approvalDate={props.data[props.row.index].approvalDate} />,
  },
  {
    Header: (props) => <ProjectReportsCustomHeader tableProps={props} title={<FormattedMessage id="REPORTING.PERIOD.START.DATE" />} className='min-w-300px' />,
    id: 'reportingPeriodStartDate',
    Cell: ({ ...props }) => < ReportingPeriodStartDateCell reportingPeriodStartDate={props.data[props.row.index].reportingPeriodStartDate} />,
  },
  {
    Header: (props) => <ProjectReportsCustomHeader tableProps={props} title={<FormattedMessage id="REPORTING.PERIOD.END.DATE" />} className='min-w-300px' />,
    id: 'reportingPeriodEndDate',
    Cell: ({ ...props }) => < ReportingPeriodEndDateCell reportingPeriodEndDate={props.data[props.row.index].reportingPeriodEndDate} />,
  },
  {
    Header: (props) => (
      <ProjectReportsCustomHeader tableProps={props} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <ProjectReportsActionsCell id={props.data[props.row.index].id}  />,
  },
]


export { cipsColumns }
