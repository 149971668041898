import {Column} from 'react-table'
import {PrintedMaterialsDescriptionCell} from './PrintedMaterialsDescriptionCell'
import {PrintedMaterialsActionsCell} from './PrintedMaterialsActionsCell'
import {PrintedMaterialsCustomHeader} from './PrintedMaterialsCustomHeader'
import {PrintedMaterialsModel} from '../../core/_models'
import { PrintedMaterialsVersionDateCell } from './PrintedMaterialsVersionDateCell'
import { PrintedMaterialsFileNameCell } from './PrintedMaterialsFileNameCell'
import { FormattedMessage } from 'react-intl'
import { PrintedMaterialsLinkCell } from './PrintedMaterialsLinkCell'


var printedMaterialsColumns: ReadonlyArray<Column<PrintedMaterialsModel>> = [

  {
    Header: (props) => <PrintedMaterialsCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <PrintedMaterialsDescriptionCell printedMaterials={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <PrintedMaterialsCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <PrintedMaterialsVersionDateCell printedMaterials={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <PrintedMaterialsCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <PrintedMaterialsFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <PrintedMaterialsCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <PrintedMaterialsLinkCell printedMaterials={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <PrintedMaterialsCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <PrintedMaterialsActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {printedMaterialsColumns}
