import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const ContractSigned: FC<Props> = ({ site }) => {

    var formattedDate = '';

    if(site.contractSigned != null || site.contractSigned != undefined){
       formattedDate = site.contractSigned === '0001-01-01T00:00:00' ? '' : new Date(site.contractSigned).toLocaleDateString('tr-TR');
    }

    return (
        <div className='d-flex align-items-center'>
            {formattedDate}
        </div>
    )
}
export { ContractSigned }
