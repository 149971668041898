import {useQuery, useQueryClient} from 'react-query'
import {ParameterEditModalForm} from './ParameterEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { getPhotoFiles } from '../core/_requests'

const ParameterEditModalFormWrapper = () => {
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: photos,
    error,
  } = useQuery(
    `${QUERIES.PARAMETER_LIST}-parameter-${itemIdForUpdate}`,
    () => {
      return getPhotoFiles(itemIdForUpdate)
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  if (!isLoading && !error && photos)
  { 
     return <ParameterEditModalForm photos={photos}/>
  }


  return null
}

export {ParameterEditModalFormWrapper}
