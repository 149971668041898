import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import {useIntl, FormattedMessage} from 'react-intl'
import { ProgressPaymentBarChartWrapper } from './progress-payment-barchart/ProgressPaymentBarchart'



const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="PROGRESS.PAYMENT.GRAPH"/>,
    path: '',
    isSeparator: false,
    isActive: false,
  },

]

const ProgressPaymentBarchartPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='chart'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>sadsadsad</PageTitle>
              <ProgressPaymentBarChartWrapper />
              </>
          }
        />
      </Route>
      <Route index element={<Navigate to='chart' />} />
    </Routes>

    
  )
}

export default ProgressPaymentBarchartPage
