import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import {AsideMenuMain} from './AsideMenuMain'
import {DrawerComponent, ToggleComponent} from '../../../assets/ts/components'
import { useAuth } from 'app/modules/auth'
import { useIntl } from 'react-intl'
import { getUserGuide, getUserGuideTurkish } from 'app/modules/user-management/user-management-ceb/users-list/core/_requests'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const {pathname} = useLocation()
  const {currentUser} = useAuth()
  const intl = useIntl()

  const onDownloadPdfEng = () => {
    getUserGuide()
  }

  const onDownloadPdfTr = () => {
    getUserGuideTurkish()
  }
  
  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='kt_aside_menu_wrapper'
      // className='hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1'
      className='my-2 my-lg-5 pe-lg-n1'
      data-kt-scroll='true'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside, #kt_aside_menu'
      data-kt-scroll-offset='5px'
      style={{backgroundColor: "#D3D3D3"}}
    >
      <div
        className='btn btn-sm btn-bg-light btn-color-gray-500 btn-active-color-primary ms-lg-6 py-2' 
        style={{backgroundColor: "#D3D3D3", marginBottom: "10px", border: "1px solid"}}
        id='#kt_aside_menu'
        data-kt-menu='true'
      >
        <span className='fw-bolder' id='kt_dashboard_daterangepicker_date'>
                {/* <?php echo date('F j')?> */}
                {currentUser?.displayName}
        </span>
      </div>

      <div
        className='menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold'
        id='#kt_aside_menu'
        data-kt-menu='true'
      >
        <AsideMenuMain />
      </div>

      <div
        className='btn btn-sm py-2' style={{backgroundColor: "#D3D3D3", marginTop: "10px", color: "#9899ac"}}
        id='#kt_aside_menu'
        data-kt-menu='true'
        onClick={onDownloadPdfTr}
      >
        <span className='fw-bolder' id='kt_dashboard_daterangepicker_date'>
          {intl.formatMessage({ id: 'USER.GUIDE.TURKISH' })}
        </span>
      </div>

      <div
        className='btn btn-sm py-2' style={{backgroundColor: "#D3D3D3",marginTop: "10px", color: "#9899ac"}}
        id='#kt_aside_menu'
        data-kt-menu='true'
        onClick={onDownloadPdfEng}
      >
        <span className='fw-bolder' id='kt_dashboard_daterangepicker_date'>
          {intl.formatMessage({ id: 'USER.GUIDE.ENGLISH' })}
        </span>
      </div>
    </div>
    
    
  )
}

export {AsideMenu}
