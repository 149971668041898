import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {LogFrameListWrapper} from './log-frame/LogFrameList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="LOG.FRAME"/>,
    path: 'log-frame',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }
]

const LogFramePage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='lf'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'LOG.FRAME.LIST' })}</PageTitle>
              <LogFrameListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/log-frame/lf' />} />
    </Routes>
  )
}

export default LogFramePage
