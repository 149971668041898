import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { getFilesForControl } from '../../modules/file-control-management/file-control-list/core/_requests'
import { getProcurements} from '../../modules/procurement-management/procurements-list/core/_requests'
import { getAllCities, getDashboardKPISitesInfo, getSitesByCityId2, getSitesCount } from '../../modules/site-management/sites-list/core/_requests'
import { DashboardPage } from './DashboardPage'
import { getMinorsParameterTypeById } from 'app/modules/parameter-management/parameters-list/core/_requests'

const DashboardWrapper = () => {
  const intl = useIntl()


  // getProcurements

  const {
    isFetching: sitesCountLoding,
    data: sitesCount,
    error: sitesCountError,
  } = useQuery(
    `${QUERIES.SITE_COUNT}-site-count`,
    () => {
      return getSitesCount()
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const {
    isFetching: siteStatusLoading,
    data: siteStatus,
    error: siteStatusError,
  } = useQuery(
    `${QUERIES.SITE_BY_CITY}`,
    () => {
      return getSitesByCityId2("0")
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const {
    isFetching: lodingKPISiteInfo,
    data: kpiSiteInfo,
  } = useQuery(
    `${QUERIES.KPI_SITE_INFO}`,
    () => {
      return getDashboardKPISitesInfo('0')
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )


  const {
    isFetching: fileControlsLoding,
    data: fileControls,
    error: fileControlsError,
  } = useQuery(
    `${QUERIES.FILE_CONTROL_LIST}-dashboard-file-control`,
    () => {
      return getFilesForControl("")
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  // const {
  //   isFetching,
  //   data: procurement,
  //   error,
  // } = useQuery(
  //   `${QUERIES.PROCUREMENT_LIST}-all-procurements`,
  //   () => {
  //     return getProcurements("")
  //   },
  //   {
  //     cacheTime: 0,
  //     onError: (err) => {
  //       console.error(err)
  //     },
  //   }
  // )

  const {
    isFetching: cityStatusLoading,
    data: cities,
    error: cityStatusError,
  } = useQuery(
    `${QUERIES.CITIES}`,
    () => {
      return getAllCities()
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const {

    data: procurementStatus,
  } = useQuery(
    `${QUERIES.PROCUREMENT_STATUS_LIST}-parameter-procurement-status`,
    () => {
      return getMinorsParameterTypeById("01373e57-09cb-42d0-b942-40ea56bb356a")
    },
    {
      cacheTime: 0, refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  if (!sitesCountError && !sitesCountLoding && sitesCount && !lodingKPISiteInfo && kpiSiteInfo && procurementStatus && cities) 
  {
      return    <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
        <DashboardPage 
        mhcCount={sitesCount.mhcCount} 
        emhcCount={sitesCount.emhcCount} 
        ptrCount={sitesCount.ptrCount} 
        rehabCount={sitesCount.hospitalCount} 
        // procurementList={procurement.data}
        procurementStatusList={procurementStatus}
        fileControls={fileControls?.data ? fileControls.data : [] } 
        sitesInfoList={siteStatus!}
        kpiSiteInfo={kpiSiteInfo}
        cities={cities}
        />
    
      </>
  }
 

  return null
  
}

export { DashboardWrapper }
