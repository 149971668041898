import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAuth } from '../../../auth/core/AuthHelpers'
import { ParameterQueryResponse, ParameterModel } from './_models'
import { City, District, ID, Response } from "../../../../../_metronic/helpers"

const API_URL = "https://shifatr.org/api/parameter"

const getParameters = async (query: string): Promise<ParameterQueryResponse> => {
  return await axios
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<ParameterQueryResponse>) => d.data)
  }
const getParameterById = (id: ID): Promise<ParameterModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: Response<ParameterModel>) => response.data)
}

const createParameter = (parameter: ParameterModel): Promise<ParameterModel | undefined> => {
  return axios
    .post(API_URL, parameter)
    .then((response: Response<ParameterModel>) => response.data)
}

const updateParameter = (parameter: ParameterModel): Promise<ParameterModel | undefined> => {

  return axios
    .put(`${API_URL}/${parameter.id}`, parameter)
}

const deleteParameter = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${parameterId}`).then(() => { })
}

const getMajorParameters = (): Promise<Array<ParameterModel>> | any => {
  return axios
    .get(`${API_URL}/majors`)
    .then((response: Response<Array<ParameterModel>>) => response.data)

}

const getMinorsParameterTypeById = (id: ID): Promise<Array<ParameterModel>> | any => {
  return axios
    .get(`${API_URL}/minors/${id}`)
    .then((response: Response<Array<ParameterModel>>) => response.data )
}
const getCities = async (): Promise<Array<City>|undefined> => {
  return  axios
    .get(`${API_URL}/cities`)
    .then((response: Response<Array<City>>) => response.data )
}

const getCityById = async (id : string): Promise<City|undefined> => {
  return  axios
    .get(`${API_URL}/cities/${id}`)
    .then((response: Response<City>) => response.data )
}


const getCountries = async (): Promise<Array<City>|undefined> => {
  return  axios
    .get(`${API_URL}/countries`)
    .then((response: Response<Array<City>>) => response.data )

}

const getDistrictsByCityId = async (id : ID): Promise<Array<District>|undefined> => {
  return  axios
    .get(`${API_URL}/district/${id}`)
    .then((response: Response<Array<District>>) => response.data )

}

const getDistrictById = async (id : string): Promise<City|undefined> => {
  return  axios
    .get(`${API_URL}/districtDetail/${id}`)
    .then((response: Response<City>) => response.data )
}

const getHelpPdf = (): any => {
  return axios
    .get(`${API_URL}/getHelpDocument`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

export { getCountries, getCities, getDistrictById, getCityById, getDistrictsByCityId, getParameters, getParameterById, getMinorsParameterTypeById, createParameter, updateParameter, deleteParameter, getMajorParameters, getHelpPdf }