import {useQuery} from 'react-query'
import {BugReportingEditModalForm} from './BugReportingEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getBugReportingById} from '../core/_requests'

const BugReportingEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: bugreporting,
    error,
  } = useQuery(
    `${QUERIES.BUG_REPORTING_LIST}-bugreporting-${itemIdForUpdate}`,
    () => {
      return getBugReportingById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!isLoading  && bugreporting) {
    return <BugReportingEditModalForm isBugReportingLoading={isLoading} BugReporting={bugreporting}  />
  }

  if (!isLoading) {
    return <BugReportingEditModalForm isBugReportingLoading={isLoading} BugReporting = {{ id: undefined, addAttachments: [], title: "", context: ""}} />
  }

  return null
}

export {BugReportingEditModalFormWrapper}
