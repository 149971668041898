import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {PrintedMaterialsListHeader} from './components/header/PrintedMaterialsListHeader'
import {PrintedMaterialsEditModal} from './printed-materials-modal/PrintedMaterialsEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { PrintedMaterialsTable } from './table/PrintedMaterialsTable'
import { PrintedMaterialsDeleteModal } from '../PrintedMaterialsDeleteModal'

const PrintedMaterialsList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <PrintedMaterialsListHeader />
        <PrintedMaterialsTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <PrintedMaterialsEditModal />}
      {itemIdForDelete !== undefined && <PrintedMaterialsDeleteModal />}
    </>
  )
}

const PrintedMaterialsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PrintedMaterialsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {PrintedMaterialsListWrapper}
