import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const Electric: FC<Props> = ({ site }) => {

    var formattedDate = '';

    if(site.electric != null || site.electric != undefined){
       formattedDate = site.electric === '0001-01-01T00:00:00' ? '' : new Date(site.electric).toLocaleDateString('tr-TR');
    }

    return(
        <div className='d-flex align-items-center'>
        {formattedDate}
        </div>
    )

}
export { Electric }
