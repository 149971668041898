import React, { FC } from "react";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import {ShifaScopeTable } from "../../../tables/shifa-scope-table/ShifaScopeTable";

const ShifaScopeStep: FC = () => {
  return (
    <ShifaScopeTable/>
  );
};

export { ShifaScopeStep };
