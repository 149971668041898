import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {SiteModel} from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { FinalDesignTenderDossierSubmissionToMoh } from './FinalDesignTenderDossierSubmissionToMoh'
import { FinalDesignTenderDossierApprovalOfMoh } from './FinalDesignTenderDossierApprovalOfMoh'
import { TenderLaunchRelaunch } from './TenderLaunchRelaunch'
import { ContractSigned } from './ContractSigned'
import { SiteNumberIdentificationCell } from '../../shifa-scope-table/columns/SiteNumberIdentificationCell'
import { BuildingNameCell } from '../../shifa-scope-table/columns/BuildingNameCell'

const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.SITE.NUMBER.IDENTIFICATION'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <SiteNumberIdentificationCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'FINAL.DESIGN.&.TENDER.DOSSIER.SUBMISSION.TO.MOH'/>}className='min-w-200px' />,
    id: 'finalDesignTenderDossierSubmissionToMoh',
    Cell:({...props}) => <FinalDesignTenderDossierSubmissionToMoh site={props.data[props.row.index]} />,
    
  },  
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'FINAL.DESIGN.&.TENDER.DOSSIER.APPROVAL.OF.MOH'/>} className='min-w-200px' />,
    id: 'finalDesignTenderDossierApprovalOfMoh',
    Cell:({...props}) => <FinalDesignTenderDossierApprovalOfMoh site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'TENDER.LAUNCH.RELAUNCH'/>} className='min-w-200px' />,
    id: 'tenderLaunchRelaunch',
    Cell:({...props}) => <TenderLaunchRelaunch site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACT.SIGNED'/>} className='min-w-200px' />,
    id: 'contractSigned',
    Cell:({...props}) => <ContractSigned site={props.data[props.row.index]} />,
  }, 

]

export {sitesColumns}
