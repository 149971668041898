import { Column } from 'react-table'
import { BugReportingActionsCell } from './BugReportingActionsCell'
import { BugReportingCustomHeader } from './BugReportingCustomHeader'
import { BugReportingListModel } from '../../core/_models'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROLES, getUserRole } from '_metronic/helpers'
import { BugReportingTitleCell } from './BugReportingTitleCell'
import { BugReportingContextCell } from './BugReportingContextCell'
import { BugReportingCreatedByCell } from './BugReportingCreatedByCell'

//const role = getUserRole();

var cipsColumns: ReadonlyArray<Column<BugReportingListModel>> = [
  {
    Header: (props) => <BugReportingCustomHeader tableProps={props} title={<FormattedMessage id="BUGREPORTING.TITLE.UC" />} className='min-w-300px' />,
    id: 'title',
    Cell: ({ ...props }) => <BugReportingTitleCell title={props.data[props.row.index].title} />,
  },
  {
    Header: (props) => <BugReportingCustomHeader tableProps={props} title={<FormattedMessage id="BUGREPORTING.CONTEXT.UC" />} className='min-w-300px' />,
    id: 'context',
    Cell: ({ ...props }) => < BugReportingContextCell context={props.data[props.row.index].context} />,
  },
  {

    Header: (props) => <BugReportingCustomHeader tableProps={props} title={<FormattedMessage id="BUGREPORTING.CREATEDBY" />} className='min-w-300px' />,
    id: 'createdBy',
    Cell: ({ ...props }) => <BugReportingCreatedByCell createdBy={props.data[props.row.index].createdBy} />,
  },
  {
    Header: (props) => (<BugReportingCustomHeader tableProps={props} className='text-end min-w-100px' />),
    id: 'actions',
    Cell: ({ ...props }) => <BugReportingActionsCell id={props.data[props.row.index].id} createdBy={props.data[props.row.index].createdBy} />,
    
  }
]

export { cipsColumns }
