import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const DefectLiabilityPeriod: FC<Props> = ({site}) => {
  
  var formattedDate = '';

  if (site.defectLiabilityPeriod != null || site.defectLiabilityPeriod != undefined) {
      formattedDate = site.defectLiabilityPeriod === '0001-01-01T00:00:00' ? '' : new Date(site.defectLiabilityPeriod).toLocaleDateString('tr-TR');
  }

  return (
      <div className='d-flex align-items-center'>
          {formattedDate}
      </div>
  )
}
export {DefectLiabilityPeriod}
