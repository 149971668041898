import { PageLink, PageTitle } from '_metronic/layout/core'
import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import { FinancialOverviewTable } from './FinancalOverviewTable'
import { KTCard } from '_metronic/helpers'
import { FinancalOverviewHeader } from './FinancalOverviewHeader'

// import {MHCListWrapper} from './checklist-list/MHCList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title:<FormattedMessage id ="FINANCIAL.OVERVIEW"/>,
    path: 'shifa-implementation',
    isSeparator: false,
    isActive: false,
  },
  {
    title: <FormattedMessage id ="FINANCIAL.OVERVIEW"/>,
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FinancialOverviewPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet/>}>
        <Route
          path='financialoverview'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'FINANCIAL.OVERVIEW' })}</PageTitle>
              <KTCard>
                <FinancalOverviewHeader/>
                <FinancialOverviewTable/>
              </KTCard>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='financialoverview'/>} />
    </Routes>
  )
}

export default FinancialOverviewPage
