import { FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const ProvisionalAcceptance: FC<Props> = ({ site }) => {

  var formattedDate = '';

    if (site.provisionalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications != null || site.provisionalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications != undefined) {
        formattedDate = site.provisionalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications === '0001-01-01T00:00:00' ? '' : new Date(site.provisionalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications).toLocaleDateString('tr-TR');
    }

    return (
        <div className='d-flex align-items-center'>
            {formattedDate}
        </div>
    )
}
export { ProvisionalAcceptance }
