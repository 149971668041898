import { FormattedMessage } from 'react-intl'
import {InformationCampaignListToolbar} from './InformationCampaignListToolbar'

const InformationCampaignListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="INFORMATION.CAMPAIGN.LIST"/>}</h2>
      {/* <ParametersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<InformationCampaignListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {InformationCampaignListHeader}
