import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {EstimatedCostListWrapper} from './estimated-cost/EstimatedCostList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="ESTIMATED.COST"/>,
    path: 'estimated-cost',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }
]

const EstimatedCostPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='ec'
          element={
            <>
                <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'ESTIMATED.COST.SITE.LIST' })}</PageTitle>
                <EstimatedCostListWrapper /> 
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/estimated-cost/ec' />} />
    </Routes>
  )
}

export default EstimatedCostPage
