import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { FILEGROUP, FILETYPES, getInstituionId, getUserId, getUserRole,  ID,  isNotEmpty, QUERIES, SITETYPES, } from '../../../../../_metronic/helpers'
import { BugReportingModel, FileInfoModel, BugReportingModelDTO } from '../core/_models'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { BugReportingListLoading } from '../components/loading/BugReportingListLoading'
import { createBugReporting, updateBugReporting } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { ParameterModel } from '../../../parameter-management/parameters-list/core/_models'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLang } from '../../../../../_metronic/i18n/Metronici18n'
import { createFileUpload } from '../../../file-upload-page/core/_requests'
import { FilesTable } from './table/FilesTable'
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from "uuid";

type Props = {
  isBugReportingLoading: boolean
  BugReporting: BugReportingModel
}

const BugReportingEditModalForm: FC<Props> = ({ BugReporting, isBugReportingLoading }) => {

  const { refetch } = useQueryResponse()
  const userId = getUserId();
  const { setItemIdForUpdate, setFileModelList, fileModelList,setMainFile, mainFile, itemIdForUpdate} = useListView()
  const [BugReportingForEdit] = useState<BugReportingModel>({
    ...BugReporting
  })

  useEffect(() => {

    if( itemIdForUpdate && BugReportingForEdit.files !== null &&  BugReportingForEdit.files !== undefined )
      {
        setFileModelList(BugReportingForEdit.files)
        var mainFile = BugReportingForEdit.files.filter(x => x.group !== 2)
        setMainFile(mainFile[0])
      }

  },[BugReportingForEdit, itemIdForUpdate, setFileModelList, setMainFile]);
  

  const intl = useIntl();
  let files: Array<any> = []
  let fileInfoModelList: Array<FileInfoModel> = []
  let attachments: Array<FileInfoModel> = []

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setFileModelList([])
    setMainFile({})
    setItemIdForUpdate(undefined)
  }

  const choiceSchema = () => {
    return Yup.object().shape({
      title: Yup.string()
        .min(5, "Min 5 character")
        .max(100, "Max 50 character.")
        .required(<FormattedMessage id='AUTH.VALIDATION.REQUIRED_FIELD' /> as any),
      context: Yup.string()
        .min(5, "Min 5 character")
        .required(<FormattedMessage id='AUTH.VALIDATION.REQUIRED_FIELD' /> as any)
      // uploadedFile: Yup.mixed()
      //   .required(<FormattedMessage id='AUTH.VALIDATION.REQUIRED_FIELD' /> as any),
      // addAttachments: Yup.mixed()
      //   .required(<FormattedMessage id='AUTH.VALIDATION.REQUIRED_FIELD' /> as any),
    })
  }
  const editBugReportingSchema = choiceSchema();
  const formik = useFormik({
    initialValues: BugReportingForEdit,
    validationSchema: editBugReportingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {

        if (isNotEmpty(values.id)) {

          if(formik.values.addAttachments !== null && formik.values.addAttachments !== undefined)
             await sendFilesUplaodRequst(formik.values.addAttachments, values.id)

            fileModelList && fileModelList.forEach(file => {
            file.fileId && fileInfoModelList.push(file)
          });
          let BugReportingModelDTO: BugReportingModelDTO = {
            id: values.id,
            title: values.title,
            context: values.context,
            files: fileInfoModelList
          };
          await updateBugReporting(BugReportingModelDTO)
        } else {
          values.id=uuidv4()

          let BugReportingModelDTO: BugReportingModelDTO = {
            id: values.id,
            title: values.title,
            context: values.context,
            files: fileInfoModelList
          };
          var result = await createBugReporting(BugReportingModelDTO)

          await sendFilesUplaodRequst(formik.values.addAttachments,values.id)
        }
      } catch (ex) {
        console.error(ex)
        toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
      } finally {
        setSubmitting(true)
        cancel(true)
        toast.success(intl.formatMessage({ id: 'SUCCESS_TOASTER' }));
      }
    },
  })

  const sendFilesUplaodRequst = async (fileList: Array<any>, bugReportId:any) => {
    let i = 0;
    for (i; i < fileList.length; i++) {
      let formData = new FormData()
      let file: FileInfoModel = {}

      formData.append("uploadedFile", fileList[i])
      formData.append("fileName", fileList[i].name)
      formData.append("fileTypeId", FILETYPES.BUG_REPORTING.id)
      formData.append("bugReportId", bugReportId)
      

      var result = await createFileUpload(formData)
      file.fileId = result?.data;
      file.group = FILEGROUP.ATTACHMENT
      fileInfoModelList.push(file);
    }
  }

  const handleChangeMultipleFile = async (e: any) => {

    if(!formik.values.id)
    {
      setFileModelList([])
    }
    else
      attachments = [...fileModelList];
    files.push(e.target.files)
    formik.setFieldValue("addAttachments", files[0])


    if(!formik.values.id && mainFile.fileName !== "" && mainFile !== null  && mainFile !== undefined)
      attachments.push(mainFile)

    let i:number = 0
    for(i =0 ; i<files[0].length;i++)
    {
      var isValid = validateFile(files[0][i]);
      if(isValid) {
        let file: FileInfoModel = {
          fileName:files[0][i].name,
          group: FILEGROUP.ATTACHMENT
        
        }
        attachments.push(file)
        setFileModelList(attachments)
      }
    }
  }
  const lang = useLang()

  const validateFile = (file: any) => {
    var allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf'];

    var extension = file.name.substring(file.name.indexOf('.'));

    if (!allowedExtensions.includes(extension)) {
      formik.setFieldValue("procurementFile", null)
      formik.setFieldValue("fileName", null)
      toast.warning(intl.formatMessage({ id: 'VALID_FILE_UPLOAD_TYPE' }) + file.name + intl.formatMessage({ id: 'FILE_UPLOAD_TYPE' }));
      return false;
    }
    
    return true;
  }
  
  return (
    <>
      <form id='kt_modal_add_Stakeholder_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-6'>
            <div className='row'>
              <div className="mb-10 col-6">
                <label className="required fw-bold fs-6 mb-2">
                  {<FormattedMessage id='BUGREPORTING.TITLE' />}
                </label>
                <textarea
                  placeholder={intl.formatMessage({ id: 'BUGREPORTING.ADD.WTITE.TITLE' })}
                  {...formik.getFieldProps("title")}
                  name="title"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.title &&
                        formik.errors.title,
                    },
                    {
                      "is-valid":
                        formik.touched.title &&
                        !formik.errors.title,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isBugReportingLoading}
                />
                {formik.touched.title &&
                  formik.errors.title && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="mb-10 col-6">
                <label className="fw-bold fs-6 mb-2">
                  {<FormattedMessage id='BUGREPORTING.CONTEXT' />}
                </label>
                <textarea
                  placeholder={intl.formatMessage({ id: 'BUGREPORTING.ADD.WTITE.CONTEXT' })}
                  {...formik.getFieldProps("context")}
                  name="context"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.context &&
                        formik.errors.context,
                    },
                    {
                      "is-valid":
                        formik.touched.context &&
                        !formik.errors.context,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isBugReportingLoading}
                />
                {formik.touched.context &&
                  formik.errors.context && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.context}</span>
                      </div>
                    </div>
                  )}
              </div>

              <div className="mb-10 col-6">
                <label className='fw-bold fs-6 mb-2'> {<FormattedMessage id='BUGREPORTING.ADD.ATTACHMENT' />}</label>
                <label
                    htmlFor="add-attachments"
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.addAttachments && formik.errors.addAttachments },
                      {
                        'is-valid': formik.touched.addAttachments && !formik.errors.addAttachments,
                      }
                    )}
                    style={{ cursor: 'pointer' }}
                  >
                    { formik.values.addAttachments && formik.values.addAttachments.length > 0 && formik.values.addAttachments[0].name
                    ? formik.values.addAttachments[0].name
                    : <FormattedMessage id="SELECT.FILE" />}
                  </label>
                <input
                  id="add-attachments"
                  style={{ display: 'none' }}
                  placeholder='addAttachments'
                  onChange={handleChangeMultipleFile}
                  type='file'
                  name='addAttachments'
                  multiple
                  
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.addAttachments && formik.errors.addAttachments && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{""}</span>
                    </div>
                  </div>
                )}
              </div>

            </div>

            <div className="text-center pt-15">
              <button
                type="reset"
                onClick={() => cancel()}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                disabled={formik.isSubmitting || isBugReportingLoading}
              >
                {<FormattedMessage id='DISCARD' />}
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  isBugReportingLoading
                }
              >
                <span className="indicator-label">{<FormattedMessage id='SUBMIT' />}</span>
                {(formik.isSubmitting || isBugReportingLoading) && (
                  <span className="indicator-progress">
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-6'>
            <FilesTable />
          </div>

        </div>


      </form>
      {(formik.isSubmitting || isBugReportingLoading) && <BugReportingListLoading />}
    </>
  )
}

export { BugReportingEditModalForm }
