import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {BugReportingListHeader} from './components/header/BugReportingListHeader'
import {BugReportingTable} from './table/BugReportingTable'
import {BugReportingEditModal} from './bug-reporting-edit-modal/BugReportingEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { BugReportingDeleteModal } from '../BugReportingDeleteModal'

const BugReportingList = () => {
  const {itemIdForUpdate, itemIdForDelete} = useListView()
  return (
    <>
      <KTCard>
        <BugReportingListHeader />
        <BugReportingTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <BugReportingEditModal />}
      {itemIdForDelete !== undefined && <BugReportingDeleteModal />}
    </>
  )
}

const BugReportingListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BugReportingList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BugReportingListWrapper}
