import axios, { AxiosResponse } from 'axios'
import { SiteQueryResponse } from '../../core/_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import { SiteModel } from 'app/modules/site-management/sites-list/core/_models'

const API_URL = "https://shifatr.org/api/procurement-site"

const getSites = async (query: string): Promise<SiteQueryResponse | undefined> => {
  return  await axios
    .get(`${API_URL}?${query}`)
    .then((response: Response<SiteQueryResponse>) => response.data)

}

const getSitesNoPaging = async (query: string): Promise<SiteQueryResponse | undefined> => {
  return  await axios
    .get(`${API_URL}/nopaging?${query}`)
    .then((response: Response<SiteQueryResponse>) => response.data)

}

const getSitesByLotId = (id: ID): Promise<SiteQueryResponse | undefined> => {
  return axios
  .get(`${API_URL}/lot/${id}`)
  // .then((response: Response<SiteQueryResponse>) => response.data)
   
}
const getSitesByContractId = (id: ID): Promise<SiteQueryResponse | undefined> => {
  return axios
  .get(`${API_URL}/contract/${id}`)
   
}

const getSitesByProcurementId = (id: ID): Promise<SiteModel[] | undefined> => {
  return axios
  .get(`${API_URL}/procurement/${id}`)
  .then((response: Response<SiteModel[]>) => response.data)
   
}
const getSitesBySiteTypeId = (id: ID): Promise<SiteQueryResponse | undefined> => {
  return axios
  .get(`${API_URL}/${id}`)
}

const getSitesBySiteTypeIdAndLotId = (id: ID, lotId: ID): Promise<SiteQueryResponse | undefined> => {
  return axios
  .get(`${API_URL}/${id}/${lotId}`)
}

const removeSiteFromLot = (siteId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${siteId}`).then(() => { })
}

export { getSitesBySiteTypeIdAndLotId, getSites, getSitesNoPaging, getSitesByLotId, getSitesByContractId, removeSiteFromLot, getSitesBySiteTypeId, getSitesByProcurementId }