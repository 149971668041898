import {useEffect, useState} from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, initialQueryState, QUERIES} from "../../../../../../_metronic/helpers"
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import { PrintedMaterialsModel } from '../../core/_models'

const PrintedMaterialsListFilter = () => {
  const { query } = useQueryResponse()
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [filterType, setFilterType] = useState<any>(-1)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({ ...initialQueryState})
  }

  const filterData = () => {
    updateState(
      {
        filter: {filterType: filterType,},
        ...initialQueryState}   
    )
  }

  return (
     <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        {<FormattedMessage id ="FILTER"/>}
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{<FormattedMessage id ="FILTER.OPTIONS"/>}</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Duruma göre:</label>
            <select
              className='form-select form-select-solid fw-bolder'
            
              data-kt-user-table-filter='status'
              data-hide-search='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value={-1}> All</option>
              <option value={0}>Pasive</option>
              <option value={1}>Active</option>
              
            </select>
          </div> */}
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>{<FormattedMessage id ="VERSION.DATE"/>}</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              name='majorId'
              data-hide-search='true'
              onChange={(e) => setFilterType(e.target.value)}
              value={filterType}
            >
             <option value={"0"} >{<FormattedMessage id ="SELECT"/>}</option>
             <option value={"1"} >{<FormattedMessage id ="LOW.TO.HIGH"/>}</option>
             <option value={"2"} >{<FormattedMessage id ="HIGH.TO.LOW"/>}</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              {<FormattedMessage id ="DISCARD"/>}
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              {<FormattedMessage id ="FILTER"/>}
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>

  )
}

export {PrintedMaterialsListFilter}
