import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const SiteArchitecturalCell: FC<Props> = ({ site }) => {

  var formattedDate = '';

    if(site.siteHandover != null || site.siteHandover != undefined){
       formattedDate = site.siteHandover === '0001-01-01T00:00:00' ? '' : new Date(site.siteHandover).toLocaleDateString('tr-TR');
    }

  return (
    <div className='d-flex align-items-center'>
      {formattedDate}
    </div>
  )
}
export { SiteArchitecturalCell }
