import axios from 'axios'
import {  LotModel, ContractQueryResponse, ContractModel, ProcurementQueryResponse, ListSite} from '../../core/_models'
import { ID, Response } from "../../../../../_metronic/helpers"

const API_URL = "https://shifatr.org/api/procurement-contract"
const API_SITE_URL = "https://shifatr.org/api/procurement-site"

const getContractsByProcurementId = async (id: ID): Promise<ContractQueryResponse | undefined> => {
  return  await axios
    .get(`${API_URL}/${id}`)
     
  }
const getContracts = async (): Promise<ContractQueryResponse | undefined> => {
  return await axios
    .get(`${API_URL}`)
    
  }

const getContractsByProcurementStatus = async (): Promise<ContractQueryResponse | undefined> => {
    return await axios
      .get(`${API_URL}/filter`)
    }

  const getContractsByProcurementTypeId = async (id: ID): Promise<ContractQueryResponse | undefined> => {
    return  await axios
      .get(`${API_URL}/byprocurementtype/${id}`)
       
    }

    const getContractBySites = async (id: ID): Promise<ListSite | undefined> => {
      try {
          const response = await axios.get(`${API_SITE_URL}/contract2/${id}`);
          return response.data; 
      } catch (error) {
          console.error("Hata oluştu:", error);
          return undefined; 
      }
  }

  const getContractBySites2 = async (id: ID): Promise<ListSite[] | undefined> => {
    try {
        const response = await axios.get(`${API_SITE_URL}/contract2/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Hata oluştu:", error);
        return undefined; 
    }
}

  const getContractsByStakeholderId = async (stakeholderId:ID): Promise<ContractQueryResponse | undefined> => {
  return await axios
    .get(`${API_URL}/bystakeholder/${stakeholderId}`)
    
  }

const getContractById = async (query: string): Promise<ProcurementQueryResponse | undefined> => {
  return await axios
    .get(`${API_URL}?${query}`)
    .then((response: Response<ProcurementQueryResponse>) => response.data)
}

const getContractByContractId = async (id: ID): Promise<ContractModel | undefined> => {
  return await axios
    .get(`${API_URL}/getcontract/${id}`)
    .then((response: Response<ContractModel>) => response.data)
}


const createContract = (lot: ContractModel): Promise<LotModel | undefined> => {
  return axios
    .post(API_URL, lot)
    .then((response: Response<LotModel>) => response.data)
}
const updateContractStatus = (contract: ContractModel): Promise<ContractModel | undefined> => {
  return axios
    .post(`${API_URL}/updatestatus`, contract)
    .then((response: Response<ContractModel>) => response.data)
}

const deleteContract = (contractId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${contractId}`).then(() => { })
}

const getDateOfBidAwardByProcurementId = async (id: ID): Promise<string> => {
  return  await axios
    .get(`${API_URL}/${id}/dateOfBidAward`)
     
  }

export { getContractBySites2,getContractBySites, getDateOfBidAwardByProcurementId, getContractByContractId, getContractsByStakeholderId,getContractsByProcurementId,getContractsByProcurementTypeId, getContracts, getContractsByProcurementStatus, getContractById, updateContractStatus, createContract, deleteContract }