import { useEffect, useMemo, useState } from 'react'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import {KTSVG, ROLES, getUserRole, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ProjectReportsFilter} from './ProjectReportsFilter'
import { getChecklistAsExcel, getChecklistAsPdf } from '../../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'

const ProjectReportsListToolbar = () => {
  const role = getUserRole();
  const intl = useIntl()
  const {setItemIdForUpdate, setHelpPageOn} = useListView()
  const openAddStakeholderModal = () => {
    setItemIdForUpdate(null)
  }
  const {state} = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])
  const onDownloadPdf = () => {
    getChecklistAsPdf(query)
  }
  const onDownloadExcel = () => {
    getChecklistAsExcel(query)
  }
  const openHelpPage = () => {
    setHelpPageOn(true)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
    <button type='button' className='btn btn-light-primary me-3' onClick={onDownloadPdf}>
      <KTSVG path='/media/icons/duotune/arrows/arr044.svg' className='svg-icon-2' />
      <FormattedMessage id = 'DOWNLOAD.CHECKLIST.PDFSHORT'/>
    </button>
    <button type='button' className='btn btn-light-primary me-3' onClick={onDownloadExcel}> 
      <KTSVG path='/media/icons/duotune/arrows/arr044.svg' className='svg-icon-2' />
      <FormattedMessage id = 'DOWNLOAD.CHECKLIST.EXCELSHORT'/> 
    </button>
      {/* <button type='button' className='btn btn-light-primary me-3' onClick={openHelpPage}>
        <KTSVG path='/media/svg/icons/Code/Question-circle.svg' className='svg-icon-2' />
        Help
      </button> */}
      <ProjectReportsFilter />
      
      {(role == ROLES.MOH_PIU.name || role == ROLES.Superadmin.name || role == ROLES.TUMAS_TeamLeader.name || role == ROLES.MOH_PD.name || role == ROLES.SYGM_EPED.name || role == ROLES.TUMAS_RegionalDirector.name || role == ROLES.TUMAS_Supervisor.name || role == ROLES.MOH_Directorate.name) && <button type='button' className='btn btn-primary' onClick={openAddStakeholderModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        <FormattedMessage id ="PROJECT.REPORT.ADD"/>
      </button>}
    </div>
  )
}

export {ProjectReportsListToolbar}
