import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const SiteIndicativeConstructionAreaCell: FC<Props> = ({ site }) => {

    return (
        <div className='d-flex align-items-center'>
            {site.worksType}
        </div>
    )
}




export { SiteIndicativeConstructionAreaCell }
