import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const SiteMechanicalCell: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.constructionWorksDuration}
    </div>
  )
}
export { SiteMechanicalCell }
