import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const FinalDesignTenderDossierApprovalOfMoh: FC<Props> = ({ site }) => {

  var formattedDate = '';

  if(site.finalDesignAndTenderDossierApprovalOfMoH != null || site.finalDesignAndTenderDossierApprovalOfMoH != undefined){
     formattedDate = site.finalDesignAndTenderDossierApprovalOfMoH === '0001-01-01T00:00:00' ? '' : new Date(site.finalDesignAndTenderDossierApprovalOfMoH).toLocaleDateString('tr-TR');
  }

  return (
    <div className='d-flex align-items-center'>
      {formattedDate}
    </div>
  )
}
export { FinalDesignTenderDossierApprovalOfMoh }
