import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../../core/QueryResponseProvider'
import {sitesColumns} from './columns/_columns'

import {ChecklistListLoading} from '../../components/loading/ChecklistListLoading'
import {ChecklistListPagination} from '../../components/pagination/ChecklistListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import { SiteModel } from '../../core/_models'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import { FormattedMessage } from 'react-intl'
import { ThemeModeComponent } from '_metronic/assets/ts/layout/ThemeMode'
import { useThemeMode } from '_metronic/partials/layout/theme-mode/ThemeModeProvider'



const ShifaImplementationTable = () => {
  const mhcSites = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => mhcSites,[mhcSites])
  const [anyData, setAnyData] = useState(false)
  const {mode} = useThemeMode()
  const textColor = mode === "dark" ? ['#FFFFFF'] : ['#333'];

  

  
  // const data= data1.filter(x => x.siteTypeName === "New Construction")
  const columns = useMemo(() => sitesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })



  type StateSeriesModel = {
    name?: string;
    data: {
      x: string;
      y: number[];
      goals?: {
        name: string;
        value: number;
        strokeColor: string;
      }[];
    }[];
  };

  const [derivedData, setDerivedData] = useState<SiteModel[]>([]);
  const [stateSeries, setStateSeries] = useState<StateSeriesModel[]>([]);

  useEffect(() => {
    // let anyData=false;
    setAnyData(false)
    if (rows && rows.length > 0) {
      const newData = rows.map(row => ({
        siteId: row.original.siteId,
        siteName: row.original.buildingName,
        siteHandover: row.original.siteHandover,
        expConstWorksComp: row.original.expectedConstructionWorksCompletionDate,
        provTechIRDAndC: row.original.provisionalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications,
        defPeriod: row.original.defectLiabilityPeriod,
        finalAcceptanceTechInsRemDefAndCert: row.original.finalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications
      })
    );

    const newStateSeries: StateSeriesModel[] = [];
    newData.forEach(element => {
      if (element.siteName) { 

        if(element.siteHandover!=undefined && element.siteHandover!=undefined &&
          element.expConstWorksComp!=null && element.expConstWorksComp!=null
        ){
        const siteData = {
          x: element.siteName,
          y:  [
            new Date(element.siteHandover).getTime(),
            new Date(element.expConstWorksComp).getTime()
          ]
        };
        newStateSeries.push({ name: 'Construction', data: [siteData] });
        setAnyData(true);
        }
      }});

      newData.forEach(element => {
        if (element.siteName) { 
        if(element.expConstWorksComp!=null && element.expConstWorksComp!=null &&
          element.provTechIRDAndC!=null && element.provTechIRDAndC!=null)
        {
          const siteData = {
            x: element.siteName,
            y:  [
              new Date(element.expConstWorksComp).getTime(),
              new Date(element.provTechIRDAndC).getTime()
            ]
          };
          newStateSeries.push({ name: 'Provisional Acceptance', data: [siteData] });
          setAnyData(true);
        }
        }});
        
        newData.forEach(element => {
          if (element.siteName) { 
        if(element.provTechIRDAndC!=null && element.provTechIRDAndC!=null &&
          element.defPeriod!=null && element.defPeriod!=null)
        {
          const siteData = {
            x: element.siteName,
            y:  [
              new Date(element.provTechIRDAndC).getTime(),
              new Date(element.defPeriod).getTime()
            ]
          };
          newStateSeries.push({ name: 'Dlp', data: [siteData] });
          setAnyData(true);
        }
        }});

        newData.forEach(element => {
          if (element.siteName) { 
          if(element.defPeriod!=null && element.defPeriod!=null &&
            element.finalAcceptanceTechInsRemDefAndCert!=null && element.finalAcceptanceTechInsRemDefAndCert!=null
          )
          {
            const siteData = {
              x: element.siteName,
              y:  [
                new Date(element.defPeriod).getTime(),
                new Date(element.finalAcceptanceTechInsRemDefAndCert).getTime(),
              ]
            };
            newStateSeries.push({ name: 'Final Acceptance', data: [siteData] });
            setAnyData(true);
          }
          
          /*else{
            const siteData = {
              x: element.siteName,
              y:  [
                new Date().getTime(),
                new Date().getTime()
              ],
              goals:element.provTechIRDAndC ? [
                {
                  name: 'provTechIRDAndC',
                  value: new Date(element.provTechIRDAndC).getTime(),
                  strokeColor: '#CD2F2A'
                }
              ] : undefined
            };
        newStateSeries.push({ name: ' ', data: [siteData] });
      }*/
      }
    });

    setStateSeries(newStateSeries)
    }

  }, [rows]);


const state = {
  series: stateSeries,
  options: {
    chart: {
    height: 350,
  },
  legend: {
    show: false 
},
  plotOptions: {
    bar: {
      borderRadius: 10,
      horizontal: true,
      barHeight: '100%'
    }
  },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '14px',
      fontFamily: 'Arial, sans-serif',
      colors: textColor
    },
    formatter: function(val: any) {
      var a = moment(val[0])
      var b = moment(val[1])
      var diff = b.diff(a, 'days')
      return diff + (diff > 1 ? ' days' : ' day')
    }
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'light',
      type: 'vertical',
      shadeIntensity: 0.25,
      gradientToColors: undefined,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [50, 0, 100, 100]
    }
  },
  xaxis: {
    type: 'datetime' as const,
    tickAmount: 12,
      min: new Date().setFullYear(new Date().getFullYear() - 3), 
      max: new Date().setFullYear(new Date().getFullYear() + 3),
      labels: {
        style: {
          colors: textColor,
          fontSize: '14px'

        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor,
          fontSize: '14px'
          
        }
      }
    }

  },
};

// console.log(stateSeries)
  return (
    <KTCardBody className='py-1'>


    <div style={{ margin: "15px", marginBottom: "5%" }}>

    
    

    {anyData ?
    <>
    <div className='text-end'>
    {<FormattedMessage id='SHIFA.IMP.TABLE.Details' />}
    </div>
    <ReactApexChart options={state.options} series={state.series} type="rangeBar" height={500}  />
    </>
    :
    <>{<FormattedMessage id='NO.RECORD.FOUND' />}</>
    }
    </div>

      <ChecklistListPagination />
      {isLoading && <ChecklistListLoading />}

    </KTCardBody>
  )
}

export {ShifaImplementationTable}
