/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import { useLang } from '_metronic/i18n/Metronici18n'

const ProjectReportsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (pageNumber: number | null) => {
    if (!pageNumber || isLoading || pagination.pageNumber === pageNumber) {
      return
    }

    updateState({pageNumber, pageSize: pagination.pageSize || 10})
  }

  const eng = useLang()
  return (

    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {pagination.links?.map((link, index) => (
              <li
                key={link.label || index}
                className={clsx('page-item', {
                  active: pagination.pageNumber === link.pageNumber,
                  disabled: isLoading,
                  previous: link.label === '&laquo; Previous',
                  next: link.label === 'Next &raquo;',
                })}
              >
                <a
                  className='page-link'
                  onClick={() => updatePage(link.pageNumber)}
                  dangerouslySetInnerHTML={{
                    __html:
                      eng === 'en'
                        ? link.label
                        : link.label === '&laquo; Previous'
                        ? 'Geri'
                        : link.label === 'Next &raquo;'
                        ? 'İleri'
                        : link.label,
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

  )
}

export {ProjectReportsListPagination}
