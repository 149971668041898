import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'

type Props = {
  site: SiteModel
}
const NationalContribution: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.nationalContributionEur}
  </div>

)
export { NationalContribution }
