import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProjectReportsListHeader} from './components/header/ProjectReportsListHeader'
import {ProjectReportsTable} from './table/ProjectReportsTable'
import {ProjectReportsEditModal} from './project-reports-edit-modal/ProjectReportsEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { HelpModal } from './project-reports-help/ProjectReportsHelpModal'
import { ProjectReportsDeleteModal } from '../ProjectReportsDeleteModal'

const ProjectReportsList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  const close = () => {
    setHelpPageOn(false)
  }
  return (
    <>
      <KTCard>
        <ProjectReportsListHeader />
        <ProjectReportsTable />
      </KTCard>
      {helpPageOn && <HelpModal closeFunction={() => close} />}
      {itemIdForUpdate !== undefined && <ProjectReportsEditModal />}
      {itemIdForDelete !== undefined && <ProjectReportsDeleteModal />}
    </>
  )
}

const MAFsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProjectReportsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MAFsListWrapper}
