import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR, formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const EstimatedProcurementCostEur: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
        {site.estimatedProcurementCostEUR != null && site.estimatedProcurementCostEUR != undefined && formatEUR(site.estimatedProcurementCostEUR.toFixed(2))}
    </div>
  )
}
export { EstimatedProcurementCostEur }
