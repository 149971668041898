/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  className: string
}

const TotalProgressOverviewLine: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const intl = useIntl()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(intl, height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    // <div className={`card ${className}`}>
    //   {/* begin::Header */}
    //   <div className='card-header border-0 pt-5'>
    //     {/* begin::Title */}
    //     <h3 className='card-title align-items-start flex-column'>
    //       <span className='card-label fw-bold fs-3 mb-1'>{<FormattedMessage id = 'TOT.PROGRESS.OVERVIEW'/>}</span>
    //     </h3>
    //     {/* end::Title */}
    //   </div>
    //   {/* end::Header */}

    //   {/* begin::Body */}
    //   <div className='card-body'>
    //     {/* begin::Chart */}
    //     <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '300px'}} />
    //     {/* end::Chart */}
    //   </div>
    //   {/* end::Body */}
    // </div>
    <div>
      
    </div>
  )
}

export {TotalProgressOverviewLine}

function getChartOptions(intl: any, height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  return {
    series: [
      {
        name: 'Payment Progress',
        data: [175000, 35000, 10100, 98000],
      },
      {
        name: 'Total Progress Payment',
        data: [500000, 61500, 100057, 200056],
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [intl.formatMessage({ id: 'DASHBOARD.MHC' }), intl.formatMessage({ id: 'DASHBOARD.EMHC' }), intl.formatMessage({ id: 'DASHBOARD.PTR' }), intl.formatMessage({ id: 'DASHBOARD.HOSPITAL' })],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' thousands'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
