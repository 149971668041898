/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ProcurementModel } from '../../../../app/modules/procurement-management/core/_models'
import { useLang } from '../../../i18n/Metronici18n'
import { ParameterModel } from 'app/modules/parameter-management/parameters-list/core/_models'
import { ID, KTSVG } from '_metronic/helpers'
import { getProcurementsForDashboard } from 'app/modules/procurement-management/procurements-list/core/_requests'
import SiteList from 'app/pages/dashboard/SiteList'

type Props = {
  className: string,
  procurementStatusList: Array<ParameterModel>,
  procurementTypes: Array<ParameterModel>
}

const checkProcurementType = (procurement: ProcurementModel, lang: string) => {
  if (lang === 'en') {
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          {procurement.procurementTypeEnglishName}
        </div>
      </div>
    )
  }
  if (lang === 'tr') {
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          {procurement.procurementTypeName}
        </div>
      </div>
    )
  }
  return null;
}
const checkProcurementMethod = (procurement: ProcurementModel, lang: string) => {
  if (lang === 'en') {
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          {procurement.procurementMethodEnglishName}
        </div>
      </div>
    )
  }
  if (lang === 'tr') {
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          {procurement.procurementMethodName}
        </div>
      </div>
    )
  }
  return null;

}

const checkProcurementStatus = (procurement: ProcurementModel, lang: string) => {

  if (lang == "en") {
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
          {procurement.procurementStatusEnglishName}
        </div>
      </div>
    )
  }
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {procurement.procurementStatusName}
      </div>
    </div>
  )
}

const formatTRY = (value: any) => {
  var formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });

  return formattedOutput.format(value);
}

const formatEUR = (value: any) => {
  var formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  });

  return formattedOutput.format(value);
}

const formatGlobal = (value: any) => {
  var formattedOutput = new Intl.NumberFormat('tr-TR', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });

  return formattedOutput.format(value);
}



const TenderListWidget: React.FC<Props> = ({ className, procurementTypes, procurementStatusList }) => {
  const intl = useIntl()
  const lang = useLang()
  const langString = lang;
  const [procurementId, setProcurementId] = useState<ID>(undefined);
  const [filter, setFilter] = useState({ contractor: "", procurementType: "", procurementStatus: "" });
  const [procurementList, setProcurementList] = useState<Array<ProcurementModel> | undefined>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    let query: string = "";
    if (filter.contractor && filter.contractor != "" && filter.contractor != " " && filter.contractor.length > 3) {
      if (query == "") {
        query += "contractor=" + filter.contractor;
      }
      else {
        query += "&contractor=" + filter.contractor;
      }
    }

    if (filter.procurementStatus && filter.procurementStatus != "" && filter.procurementStatus != " ") {
      if (query == "") {
        query += "procurementStatus=" + filter.procurementStatus;
      }
      else {
        query += "&procurementStatus=" + filter.procurementStatus;
      }
    }

    if (filter.procurementType && filter.procurementType != "" && filter.procurementType != " ") {
      if (query == "") {
        query += "procurementType=" + filter.procurementType;
      }
      else {
        query += "&procurementType=" + filter.procurementType;
      }
    }

    getProcurementsForDashboard(query).then(data => {
      data?.forEach(dt => {
        dt.contractorList2 = dt.contractorList?.split(" , ");
      })
      setProcurementList(data)
    })
  }, [filter])


  useEffect(() => {
    if(procurementId) {
      setOpen(true)
    }
    else{
      setOpen(false)
    }
  }, [procurementId])

  const openSiteListModal = (procurementId: ID) => {
    setProcurementId(undefined);
    setTimeout(() => {
      setProcurementId(procurementId);
    }, 250)
  }

  return (
    <>
      <SiteList procurementId={procurementId} open={open}/>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{<FormattedMessage id='TENDER.LIST' />}</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <div className="row">
            <>
              <div className="col-4">
                <div className='mb-10'>
                  <input
                    placeholder={intl.formatMessage({ id: 'VENDOR' }) + " " + intl.formatMessage({ id: 'MIN.FOUR.CHAR' })}
                    type="text"
                    name="contractor"
                    className={"form-control form-control-solid mb-3 mb-lg-0"}
                    autoComplete="off"
                    onChange={(e) => setFilter({ ...filter, contractor: e.target.value })}
                    value={filter.contractor}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className='mb-10'>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    name='procurementType'
                    data-hide-search='true'
                    onChange={(e) => setFilter({ ...filter, procurementType: e.target.value })}
                    value={filter.procurementType}
                  >
                    <option value={""} >{<FormattedMessage id='PROCUREMENT.TYPE' />}</option>

                    {procurementTypes && procurementTypes.map((procurementType: any) => (
                      procurementType.id && <option value={procurementType.id} key={procurementType.id}>{lang === 'en' ? procurementType.englishName : procurementType.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className='mb-10'>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    name='procurementStatus'
                    data-hide-search='true'
                    onChange={(e) => setFilter({ ...filter, procurementStatus: e.target.value })}
                    value={filter.procurementStatus}
                  >
                    <option value={""} >{<FormattedMessage id='PROCUREMENT.STATUS' />}</option>

                    {procurementStatusList && procurementStatusList.map((procurementStatus: any) => (
                      procurementStatus.id && <option value={procurementStatus.id} key={procurementStatus.id}>{lang === 'en' ? procurementStatus.englishName : procurementStatus.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          </div>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>{<FormattedMessage id='PROCUREMENT.TYPE' />}</th>
                  <th className='min-w-325px'>{<FormattedMessage id='DESCRIPTION' />}</th>
                  <th className='min-w-125px'>{<FormattedMessage id='TENDER.STATUS' />}</th>
                  <th className='min-w-125px'>{<FormattedMessage id='CONTRACT.VALUE' />}</th>
                  <th className='min-w-125px'>{<FormattedMessage id='VENDOR' />}</th>
                  <th></th>
                  {/* <th className='min-w-125px'>{<FormattedMessage id='METHOD' />}</th> */}
                  {/* <th className='min-w-150px'>{<FormattedMessage id='COST.IN.LOCAL.CURRENCY' />}</th> */}
                  {/* <th className='min-w-150px'>{<FormattedMessage id='COST.EURO' />}</th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {procurementList && procurementList.length !== 0 ? procurementList.map((procurement) => {

                  return (
                    <Fragment key={procurement.id}>
                      <tr>

                        <td>
                          <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {checkProcurementType(procurement, langString)}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {procurement.procurementDescription}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {checkProcurementStatus(procurement, langString)}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {formatTRY(procurement.contractValue)}
                          </span>
                        </td>

                        <td>
                          <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {procurement.contractorList2?.map((contractor) => {
                              return <>{contractor} <br /></>
                            })}
                          </span>
                        </td>
                        <td>
                          <button type='button' className='btn btn-sm btn-primary me-3' onClick={() => openSiteListModal(procurement?.id)}>
                            <span className='indicator-label'>
                              {<FormattedMessage id='SITE.LIST' />}

                            </span>
                          </button>
                        </td>
                        {/* <td>
                        <span className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {checkProcurementMethod(procurement, langString)}
                        </span>
                    </td> */}

                        {/* <td>
                        <span className='badge badge-light-primary mb-1 fs-6'>
                          {procurement.estimatedCostInLocalCurrency}
                        </span>
                      </td> */}

                        {/* <td>
                        <span className='badge badge-light-primary mb-1 fs-6'>
                          {procurement.estimatedCostEURO}
                        </span>
                      </td> */}


                      </tr>
                    </Fragment>
                  );
                }) : <tr>
                  <td></td>
                  <td>
                    <div className='d-flex text-center w-00 align-content-center justify-content-center'>
                      {<FormattedMessage id="NO.RECORD.FOUND" />}
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>}

              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export { TenderListWidget, formatTRY, formatEUR, formatGlobal }
