import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {AuthoritiesQueryResponse, PermissionModel, PermissionsQueryResponse, UserModel, UserPermissionModel, UsersQueryResponse} from './_models'
import fileDownload from 'js-file-download'

const API_URL = "https://shifatr.org/api/user-management"
const API_PERMISSION_URL = "https://shifatr.org/api/permission-management"

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}


const getUserById = (id: ID): Promise<UserModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: Response<UserModel>) => response.data)
}

const createUser = (user: UserModel): Promise<UserModel | undefined> => {
  return axios
    .post(API_URL, user)
    .then((response: AxiosResponse<Response<UserModel>>) => response.data)
    .then((response: Response<UserModel>) => response.data)
}


const updateUser = (user: UserModel): Promise<UserModel | undefined> => {
  return axios
    .put(`${API_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<UserModel>>) => response.data)
    .then((response: Response<UserModel>) => response.data)
}


const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${API_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getAllAuthorities = (): Promise<AuthoritiesQueryResponse> => {
  return axios
    .get(`${API_URL}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const createPermission = (permission: UserPermissionModel): Promise<UserPermissionModel | undefined> => {
  return axios
    .post(API_PERMISSION_URL, permission)
    .then((response: AxiosResponse<Response<UserPermissionModel>>) => response.data)
    .then((response: Response<UserPermissionModel>) => response.data)
}

const getUserPermissions = (Id: ID): Promise<PermissionsQueryResponse> => {
  return axios
  .get(`${API_PERMISSION_URL}/${Id}`)
  
}

const deletePermission = (userId: ID): Promise<void> => {
  return axios.delete(`${API_PERMISSION_URL}/${userId}`).then(() => {})
}

const getHelpPdf = (): any => {
  return axios
    .get(`${API_URL}/getHelpDocument`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

const getChecklistAsPdf = (query: string) => {
  console.log(query)
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/pdfreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (query: string) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/excelreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}

const getUserGuide = () => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/userguide/eng`,
  }).then((response) => {
    fileDownload(response.data, "UserGuide.pdf", "application/pdf");
  })
}

const getUserGuideTurkish = () => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/userguide/tr`,
  }).then((response) => {
    fileDownload(response.data, "KullanimKilavuzu.pdf", "application/pdf");
  })
}

export {getUserPermissions, deletePermission, getUsers, deleteUser, deleteSelectedUsers, getAllAuthorities, 
  getUserById, createUser, updateUser, createPermission, getChecklistAsPdf, getChecklistAsExcel, getHelpPdf, getUserGuide, getUserGuideTurkish}
