import {Column} from 'react-table'
import {EstimatedCostSiteNameCell} from './EstimatedCostSiteNameCell'
import {EstimatedCostCustomHeader} from './EstimatedCostCustomHeader'
import {EstimatedCostModel} from '../../core/_models'
import { EstimatedCostTLCell } from './EstimatedCostTLCell'
import { FormattedMessage } from 'react-intl'
import { EstimatedCostActionsCell } from './EstimatedCostActionsCell'
import { EstimatedCostEURCell } from './EstimatedCostEURCell'


var EstimatedCostsColumns: ReadonlyArray<Column<EstimatedCostModel>> = [

  {
    Header: (props) => <EstimatedCostCustomHeader tableProps={props} title={<FormattedMessage id ="ESTIMATED.COST.SITE"/>} className='min-w-650px' />,
    id: 'site',
    Cell: ({...props}) => <EstimatedCostSiteNameCell estimatedCost={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <EstimatedCostCustomHeader tableProps={props} title={<FormattedMessage id ="ESTIMATED.COST.TL"/>} className='min-w-250px' />,
    id: 'estimatedCostTL',
    Cell: ({...props}) => <EstimatedCostTLCell estimatedCost={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <EstimatedCostCustomHeader tableProps={props} title={<FormattedMessage id ="ESTIMATED.COST.EUR"/>} className='min-w-250px' />,
    id: 'estimatedCostEUR',
    Cell: ({...props}) => <EstimatedCostEURCell estimatedCost={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <EstimatedCostCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    //düzelt
    Cell: ({...props}) => <EstimatedCostActionsCell id={props.data[props.row.index].id} site={props.data[props.row.index].site }
    estimatedCostEUR={props.data[props.row.index].estimatedCostEUR }
    estimatedCostTL={props.data[props.row.index].estimatedCostTL } />,
  }
]


export {EstimatedCostsColumns}
