import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatGlobal, formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const ExchangeRateAtContractSignatureDate: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.exchangeRateAtContractSignatureDate != null && site.exchangeRateAtContractSignatureDate != undefined && formatGlobal(site.exchangeRateAtContractSignatureDate.toFixed(2))}
    </div>
  )
}
export { ExchangeRateAtContractSignatureDate }
