import { UserModel } from "../../app/modules/user-management/user-management-ceb/users-list/core/_models"
import { SiteModel } from "../../app/modules/mhc-checklist-monitoring/checklist-list/core/_models"
export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  time: string
  template?: boolean
}

const defaultMessages: Array<MessageModel> = [
  {
    user: 4,
    type: 'in',
    text: 'How likely are you to recommend our company to your friends and family ?',
    time: '2 mins',
  },
  {
    user: 2,
    type: 'out',
    text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
    time: '5 mins',
  },
  {
    user: 4,
    type: 'in',
    text: 'Ok, Understood!',
    time: '1 Hour',
  },
  {
    user: 2,
    type: 'out',
    text: 'You’ll receive notifications for all issues, pull requests!',
    time: '2 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'You can unwatch this repository immediately by clicking here: <a href="https://keenthemes.com">Keenthemes.com</a>',
    time: '3 Hours',
  },
  {
    user: 2,
    type: 'out',
    text: 'Most purchased Business courses during this sale!',
    time: '4 Hours',
  },
  {
    user: 4,
    type: 'in',
    text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
    time: '5 Hours',
  },
  {
    template: true,
    user: 2,
    type: 'out',
    text: '',
    time: 'Just now',
  },
  {
    template: true,
    user: 4,
    type: 'in',
    text: 'Right before vacation season we have the next Big Deal for you.',
    time: 'Just now',
  },
]

export interface UserInfoModel {
  initials?: {label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info'}
  firstname: string
  avatar?: string
  email: string
  position: string
  online: boolean
}

const defaultUserInfos: Array<UserInfoModel> = [
  {
    firstname: 'Emma Smith',
    avatar: 'avatars/300-6.jpg',
    email: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    online: false,
  },
  {
    firstname: 'Melody Macy',
    initials: {label: 'M', state: 'danger'},
    email: 'melody@altbox.com',
    position: 'Marketing Analytic',
    online: true,
  },
  {
    firstname: 'Max Smith',
    avatar: 'avatars/300-1.jpg',
    email: 'max@kt.com',
    position: 'Software Enginer',
    online: false,
  },
  {
    firstname: 'Sean Bean',
    avatar: 'avatars/300-5.jpg',
    email: 'sean@dellito.com',
    position: 'Web Developer',
    online: false,
  },
  {
    firstname: 'Brian Cox',
    avatar: 'avatars/300-25.jpg',
    email: 'brian@exchange.com',
    position: 'UI/UX Designer',
    online: false,
  },
  {
    firstname: 'Mikaela Collins',
    initials: {label: 'M', state: 'warning'},
    email: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    online: true,
  },
  {
    firstname: 'Francis Mitcham',
    avatar: 'avatars/300-9.jpg',
    email: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
    online: false,
  },

  {
    firstname: 'Olivia Wild',
    initials: {label: 'O', state: 'danger'},
    email: 'olivia@corpmail.com',
    position: 'System Admin',
    online: true,
  },
  {
    firstname: 'Neil Owen',
    initials: {label: 'N', state: 'primary'},
    email: 'owen.neil@gmail.com',
    position: 'Account Manager',
    online: true,
  },
  {
    firstname: 'Dan Wilson',
    avatar: 'avatars/300-23.jpg',
    email: 'dam@consilting.com',
    position: 'Web Desinger',
    online: false,
  },
  {
    firstname: 'Emma Bold',
    initials: {label: 'E', state: 'danger'},
    email: 'emma@intenso.com',
    position: 'Corporate Finance',
    online: true,
  },
  {
    firstname: 'Ana Crown',
    avatar: 'avatars/300-12.jpg',
    email: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    online: false,
  },
  {
    firstname: 'Robert Doe',
    initials: {label: 'A', state: 'info'},
    email: 'robert@benko.com',
    position: 'Marketing Executive',
    online: true,
  },
  {
    firstname: 'John Miller',
    avatar: 'avatars/300-13.jpg',
    email: 'miller@mapple.com',
    position: 'Project Manager',
    online: false,
  },
  {
    firstname: 'Lucy Kunic',
    initials: {label: 'L', state: 'success'},
    email: 'lucy.m@fentech.com',
    position: 'SEO Master',
    online: true,
  },
  {
    firstname: 'Ethan Wilder',
    avatar: 'avatars/300-21.jpg',
    email: 'ethan@loop.com.au',
    position: 'Accountant',
    online: true,
  },
]
const UserInfos: Array<UserModel> = [
  { id:1,
    firstname: 'Emma Smith',
    mail: 'e.smith@kpmg.com.au',
    position: 'Art Director',
    
  },
  { id:2,
    firstname: 'Melody Macy', 
    mail: 'melody@altbox.com',
    position: 'Marketing Analytic',
   
  },
  { id:3,
    firstname: 'Max Smith',
    mail: 'max@kt.com',
    position: 'Software Enginer',
    
  },
  { id:4,
    firstname: 'Sean Bean',
    mail: 'sean@dellito.com',
    position: 'Web Developer',
  
  },
  {
    firstname: 'Brian Cox',
    mail: 'brian@exchange.com',
    position: 'UI/UX Designer',
  
  },
  {
    firstname: 'Mikaela Collins',
    mail: 'mikaela@pexcom.com',
    position: 'Head Of Marketing',
    
  },
  {
    firstname: 'Francis Mitcham',
    mail: 'f.mitcham@kpmg.com.au',
    position: 'Software Arcitect',
  
  },

  {
    firstname: 'Olivia Wild',
    mail: 'olivia@corpmail.com',
    position: 'System Admin',
   
  },
  {
    firstname: 'Neil Owen',
    mail: 'owen.neil@gmail.com',
    position: 'Account Manager',
    
  },
  {
    firstname: 'Dan Wilson',
    mail: 'dam@consilting.com',
    position: 'Web Desinger',
    
  },
  {
    firstname: 'Emma Bold',
    mail: 'emma@intenso.com',
    position: 'Corporate Finance',
   
  },
  {
    firstname: 'Ana Crown',
    mail: 'ana.cf@limtel.com',
    position: 'Customer Relationship',
    
  },
  {
    firstname: 'Robert Doe',
    mail: 'robert@benko.com',
    position: 'Marketing Executive',
   
  },
  {
    firstname: 'John Miller',
    mail: 'miller@mapple.com',
    position: 'Project Manager',
   
  },
  {
    firstname: 'Lucy Kunic',
    mail: 'lucy.m@fentech.com',
    position: 'SEO Master',
    
  },
  {
    firstname: 'Ethan Wilder',
    mail: 'ethan@loop.com.au',
    position: 'Accountant',
  
  },
]
// const MHCSiteList: Array<SiteModel>=[
//   {
//     //Main Data//
//     id:0,
//     nameOfTheProject:"Yenidoğan No 2 Migrant Health Center",
//     city:"Adana",
//     province:"Yüreğir",
//     district:"Karşıyaka",
//     lot:111392,
//     block:4,
//     blockArea:1424,
//     landTypeName:"Arsa",
//     owner:"Maliye Hazinesi",
//     numberOfDoctors:"4+4",
//     projectType:"MHC-8",

//     indicativeConstructionArea:850,

//     notes:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",
    

//      //Site Information//

  
//      demolitionApprovalFileDate:undefined,
//      geologicalSurveyFileId:null,
//      geologicalSurveyFileDate:undefined,
//      landTopographicalSurveyPlanType:"ORTOMETRİK",
//      landTopographicalSurveyFileId:1,
//      landTopographicalSurveyFileDate:"01.01.2022",
//     //Legal Documentation Fields//
//     titleDeedFileId:0,
//     titleDeedFileDate:"11.11.2023",
//     zoningStatusCertificateWithCoordinatesFileId:1,
//      zoningStatusCertificateWithCoordinatesFileDate:"11.12.2023",
//      zoningDiameterCertificateWithCoordinatesFileId:1,
//      zoningDiameterCertificateWithCoordinatesFileDate:"23.12.2023",
//      zoningDiameterCertificateWithCoordinatesFileComment:" Zonin diameter Certificate With Coordinates Comment",
//      surroundingRoadLevelsFileId:1,
//      surroundingRoadLevelsFileDate:"09.01.2022",
//      surroundingRoadLevelsFileComment:"Surrounding Road Levels Comment",
//      zoningPlanScale5000FileId:1,
//      zoningPlanScale5000FileDate:"08.02.2022",
//      zoningPlanScale5000FileComment:"Zonin Plan Scale 5000 Comment", 
//      zoningPlanScale1000FileId:1,
//      zoningPlanScale1000FileDate: "02.02.2022" ,
//      zoningPlanScale1000FileComment:"Zoning Plan Scale 1000 Comment",
//      cadastralDocumentWithCoordinatesFileId:1,
//      cadastralDocumentWithCoordinatesFileDate:"03.06.2022",
//      upToDateCertificateOfAllocationFileId:1,
//      upToDateCertificateOfAllocationFileDate:"03.04.2022",
//      upToDateCertificateOfAllocationFileExpireDate:"01.12.2022",
//      upToDateCertificateOfAllocationFileComment:"Up To date Certificate of Allocation Comment",
//      constructionPermitFileId:1,
//      constructionPermitFileDate:"05.06.2022",
//      occupancyPermitFileId:1,
//      occupancyPermitFileDate:"04.09.2022" ,
//      // Trafic Planinng Fields//
//   sufficientNumberOfParkingLotsFileId:1,
//   sufficientNumberOfParkingLotsFileDate:"21.09.20",
//   sufficientNumberOfParkingLotsFileComment:"string",
//        // Design Status Fields//
//   architecturalFileId:1,
//   architecturalFileCode:"KT-9",
//   architecturalFileDate:"21.01.21",
//   structuralFileId:1,
//   structuralFileDate:"01.09.21",
//   technicalSpecificationFileId:1,
//   technicalSpecificationFileDate:"08.09.21",
//   boQFileId:1,
//   boQFileDate:"07.06.21",
//  },
//  {
//   //Main Data//
//   id:0,
//   nameOfTheProject:"Yenidoğan No 2 Migrant Health Center",
//   city:"Adana",
//   province:"Yüreğir",
//   district:"Karşıyaka",
//   lot:111392,
//   block:4,
//   blockArea:1424,
//   landTypeName:"Arsa",
//   owner:"Maliye Hazinesi",
//   numberOfDoctors:"4+4",
//   projectType:"MHC-8",
//   readinessID:4,
//   indicativeConstructionArea:850,
//   projectIntitiationDate:"21.12.22",
//   notes:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",
//   statusNoteUpdated:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",

//    //Site Information//
//    currentStatusOfLand:"Empty",
//    demolitionApprovalDocumentFileId:null,
//    demolitionApprovalFileDate:undefined,
//    geologicalSurveyFileId:null,
//    geologicalSurveyFileDate:undefined,
//    landTopographicalSurveyPlanType:"ORTOMETRİK",
//    landTopographicalSurveyFileId:1,
//    landTopographicalSurveyFileDate:"01.01.2022",
//   //Legal Documentation Fields//
//   titleDeedFileId:0,
//   titleDeedFileDate:"11.11.2023",
//   zoningStatusCertificateWithCoordinatesFileId:1,
//    zoningStatusCertificateWithCoordinatesFileDate:"11.12.2023",
//    zoningDiameterCertificateWithCoordinatesFileId:1,
//    zoningDiameterCertificateWithCoordinatesFileDate:"23.12.2023",
//    zoningDiameterCertificateWithCoordinatesFileComment:" Zonin diameter Certificate With Coordinates Comment",
//    surroundingRoadLevelsFileId:1,
//    surroundingRoadLevelsFileDate:"09.01.2022",
//    surroundingRoadLevelsFileComment:"Surrounding Road Levels Comment",
//    zoningPlanScale5000FileId:1,
//    zoningPlanScale5000FileDate:"08.02.2022",
//    zoningPlanScale5000FileComment:"Zonin Plan Scale 5000 Comment", 
//    zoningPlanScale1000FileId:1,
//    zoningPlanScale1000FileDate: "02.02.2022" ,
//    zoningPlanScale1000FileComment:"Zoning Plan Scale 1000 Comment",
//    cadastralDocumentWithCoordinatesFileId:1,
//    cadastralDocumentWithCoordinatesFileDate:"03.06.2022",
//    upToDateCertificateOfAllocationFileId:1,
//    upToDateCertificateOfAllocationFileDate:"03.04.2022",
//    upToDateCertificateOfAllocationFileExpireDate:"01.12.2022",
//    upToDateCertificateOfAllocationFileComment:"Up To date Certificate of Allocation Comment",
//    constructionPermitFileId:1,
//    constructionPermitFileDate:"05.06.2022",
//    occupancyPermitFileId:1,
//    occupancyPermitFileDate:"04.09.2022" ,
//    // Trafic Planinng Fields//
//    sufficientNumberOfParkingLotsFileId:1,
//    sufficientNumberOfParkingLotsFileDate:"21.09.20",
//    sufficientNumberOfParkingLotsFileComment:"string",
//    // Design Status Fields//
//    technicalSpecificationFileId:0,
//    boQFileId:0,
// },
// {
//   //Main Data//
//   id:0,
//   nameOfTheProject:"Yenidoğan No 2 Migrant Health Center",
//   city:"Adana",
//   province:"Yüreğir",
//   district:"Karşıyaka",
//   lot:111392,
//   block:2,
//   blockArea:1424,
//   landTypeName:"Arsa",
//   owner:"Maliye Hazinesi",
//   numberOfDoctors:"4+4",
//   projectType:"MHC-8",
//   readinessID:3,
//   indicativeConstructionArea:850,
//   projectIntitiationDate:"21.12.22",
//   notes:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",
//   statusNoteUpdated:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",

//    //Site Information//
//    currentStatusOfLand:"Empty",
//    demolitionApprovalDocumentFileId:null,
//    demolitionApprovalFileDate:undefined,
//    geologicalSurveyFileId:null,
//    geologicalSurveyFileDate:undefined,
//    landTopographicalSurveyPlanType:"ORTOMETRİK",
//    landTopographicalSurveyFileId:1,
//    landTopographicalSurveyFileDate:"01.01.2022",
//   //Legal Documentation Fields//
//   titleDeedFileId:0,
//   titleDeedFileDate:"11.11.2023",
//   zoningStatusCertificateWithCoordinatesFileId:1,
//    zoningStatusCertificateWithCoordinatesFileDate:"11.12.2023",
//    zoningDiameterCertificateWithCoordinatesFileId:1,
//    zoningDiameterCertificateWithCoordinatesFileDate:"23.12.2023",
//    zoningDiameterCertificateWithCoordinatesFileComment:" Zonin diameter Certificate With Coordinates Comment",
//    surroundingRoadLevelsFileId:1,
//    surroundingRoadLevelsFileDate:"09.01.2022",
//    surroundingRoadLevelsFileComment:"Surrounding Road Levels Comment",
//    zoningPlanScale5000FileId:1,
//    zoningPlanScale5000FileDate:"08.02.2022",
//    zoningPlanScale5000FileComment:"Zonin Plan Scale 5000 Comment", 
//    zoningPlanScale1000FileId:1,
//    zoningPlanScale1000FileDate: "02.02.2022" ,
//    zoningPlanScale1000FileComment:"Zoning Plan Scale 1000 Comment",
//    cadastralDocumentWithCoordinatesFileId:1,
//    cadastralDocumentWithCoordinatesFileDate:"03.06.2022",
//    upToDateCertificateOfAllocationFileId:1,
//    upToDateCertificateOfAllocationFileDate:"03.04.2022",
//    upToDateCertificateOfAllocationFileExpireDate:"01.12.2022",
//    upToDateCertificateOfAllocationFileComment:"Up To date Certificate of Allocation Comment",
//    constructionPermitFileId:1,
//    constructionPermitFileDate:"05.06.2022",
//    occupancyPermitFileId:1,
//    occupancyPermitFileDate:"04.09.2022" ,
//   // Trafic Planinng Fields//
//   sufficientNumberOfParkingLotsFileId:1,
//   sufficientNumberOfParkingLotsFileDate:"21.09.20",
//   sufficientNumberOfParkingLotsFileComment:"string",
//    // Design Status Fields//
//    technicalSpecificationFileId:0,
//    boQFileId:0,
// },
//  {
//   //Main Data//
//   id:0,
//   readinessID:2,
//   nameOfTheProject:"Project firstname",
//   city:"Ankara",
//   province:"Çankaya",
//   district:"Güvenevler",
//   lot:1,
//   block:4,
//   blockArea:2,
//    //Site Information//
//   currentStatusOfLand:"deneme",
//   demolitionApprovalDocumentFileId:0,
//   //Legal Documentation Fields//
//   titleDeedFileId:0,
//   zoningStatusCertificateWithCoordinatesFileId:0,
//   zoningDiameterCertificateWithCoordinatesFileId:0,  
//   // Trafic Planinng Fields//
//   sufficientNumberOfParkingLotsFileId:1,
//   sufficientNumberOfParkingLotsFileDate:"21.09.20",
//   sufficientNumberOfParkingLotsFileComment:"string",
//   // Design Status Fields//
//   architecturalFileId:1,
//   architecturalFileCode:"KT-9",
//   architecturalFileDate:"21.01.21",
//   structuralFileId:1,
//   structuralFileDate:"01.09.21",
//   technicalSpecificationFileId:1,
//   technicalSpecificationFileDate:"08.09.21",
//   boQFileId:1,
//   boQFileDate:"07.06.21",
// },{
//   //Main Data//
//   id:0,
//   readinessID:4,
//   nameOfTheProject:"deneme",
//   city:"İstanbul",
//   province:"Küçükçekmece",
//   district:"Atakent",
//   lot:1,
//   block:3,
//   blockArea:2,
//    //Site Information//
//   currentStatusOfLand:"deneme",
//   demolitionApprovalDocumentFileId:0,
//   //Legal Documentation Fields//
//   titleDeedFileId:0,
//   zoningStatusCertificateWithCoordinatesFileId:0,
//   zoningDiameterCertificateWithCoordinatesFileId:0,  
//   // Trafic Planinng Fields//
//   needForRehabilitationOfExistingRoadsFileID:1,
//   needForRehabilitationOfExistingRoadsFileDate:"21.09.12",
//      // Design Status Fields//
//   architecturalFileId:1,
//   architecturalFileCode:"KT-9",
//   architecturalFileDate:"21.01.21",
//   structuralFileId:1,
//   structuralFileDate:"01.09.21",
//   technicalSpecificationFileId:1,
//   technicalSpecificationFileDate:"08.09.21",
//   boQFileId:1,
//   boQFileDate:"07.06.21",
// },
// {
//   //Main Data//
//   id:0,
//   nameOfTheProject:"Yenidoğan No 2 Migrant Health Center",
//   city:"İzmi",
//   province:"Buca",
//   district:"Kızılçullu",
//   lot:111392,
//   block:4,
//   blockArea:1424,
//   landTypeName:"Arsa",
//   owner:"Maliye Hazinesi",
//   numberOfDoctors:"4+4",
//   projectType:"MHC-8",
//   readinessID:3,
//   indicativeConstructionArea:850,
//   projectIntitiationDate:"21.12.22",
//   notes:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",
//   statusNoteUpdated:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis.",

//    //Site Information//
//    currentStatusOfLand:"Empty",
//    demolitionApprovalDocumentFileId:null,
//    demolitionApprovalFileDate:undefined,
//    geologicalSurveyFileId:null,
//    geologicalSurveyFileDate:undefined,
//    landTopographicalSurveyPlanType:"ORTOMETRİK",
//    landTopographicalSurveyFileId:1,
//    landTopographicalSurveyFileDate:"01.01.2022",
//   //Legal Documentation Fields//
//   titleDeedFileId:0,
//   titleDeedFileDate:"11.11.2023",
//   zoningStatusCertificateWithCoordinatesFileId:1,
//    zoningStatusCertificateWithCoordinatesFileDate:"11.12.2023",
//    zoningDiameterCertificateWithCoordinatesFileId:1,
//    zoningDiameterCertificateWithCoordinatesFileDate:"23.12.2023",
//    zoningDiameterCertificateWithCoordinatesFileComment:" Zonin diameter Certificate With Coordinates Comment",
//    surroundingRoadLevelsFileId:1,
//    surroundingRoadLevelsFileDate:"09.01.2022",
//    surroundingRoadLevelsFileComment:"Surrounding Road Levels Comment",
//    zoningPlanScale5000FileId:1,
//    zoningPlanScale5000FileDate:"08.02.2022",
//    zoningPlanScale5000FileComment:"Zonin Plan Scale 5000 Comment", 
//    zoningPlanScale1000FileId:1,
//    zoningPlanScale1000FileDate: "02.02.2022" ,
//    zoningPlanScale1000FileComment:"Zoning Plan Scale 1000 Comment",
//    cadastralDocumentWithCoordinatesFileId:1,
//    cadastralDocumentWithCoordinatesFileDate:"03.06.2022",
//    upToDateCertificateOfAllocationFileId:1,
//    upToDateCertificateOfAllocationFileDate:"03.04.2022",
//    upToDateCertificateOfAllocationFileExpireDate:"01.12.2022",
//    upToDateCertificateOfAllocationFileComment:"Up To date Certificate of Allocation Comment",
//    constructionPermitFileId:1,
//    constructionPermitFileDate:"05.06.2022",
//    occupancyPermitFileId:1,
//    occupancyPermitFileDate:"04.09.2022" ,
//   // Trafic Planinng Fields//
//   publicTransportationFileId:1,
//   publicTransportationFileDate:"05.06.21",
//     // Design Status Fields//
//     architecturalFileId:1,
//     architecturalFileCode:"KT-9",
//     architecturalFileDate:"21.01.21",
//     structuralFileId:1,
//     structuralFileDate:"01.09.21",
//     technicalSpecificationFileId:1,
//     technicalSpecificationFileDate:"08.09.21",
//     boQFileId:1,
//     boQFileDate:"07.06.21",
// },
// ]
const messageFromClient: MessageModel = {
  user: 4,
  type: 'in',
  text: 'Thank you for your awesome support!',
  time: 'Just now',
}

export interface AlertModel {
  title: string
  description: string
  time: string
  icon: string
  state: 'primary' | 'danger' | 'warning' | 'success' | 'info'
}

const defaultAlerts: Array<AlertModel> = [
  {
    title: 'Project Alice',
    description: 'Phase 1 development',
    time: '1 hr',
    icon: 'icons/duotune/technology/teh008.svg',
    state: 'primary',
  },
  {
    title: 'HR Confidential',
    description: 'Confidential staff documents',
    time: '2 hrs',
    icon: 'icons/duotune/general/gen044.svg',
    state: 'danger',
  },
  {
    title: 'Company HR',
    description: 'Corporeate staff profiles',
    time: '5 hrs',
    icon: 'icons/duotune/finance/fin006.svg',
    state: 'warning',
  },
  {
    title: 'Project Red',
    description: 'New frontend admin theme',
    time: '2 days',
    icon: 'icons/duotune/files/fil023.svg',
    state: 'success',
  },
  {
    title: 'Project Breafing',
    description: 'Product launch status update',
    time: '21 Jan',
    icon: 'icons/duotune/maps/map001.svg',
    state: 'primary',
  },
  {
    title: 'Banner Assets',
    description: 'Collection of banner images',
    time: '21 Jan',
    icon: 'icons/duotune/general/gen006.svg',
    state: 'info',
  },
  {
    title: 'Icon Assets',
    description: 'Collection of SVG icons',
    time: '20 March',
    icon: 'icons/duotune/art/art002.svg',
    state: 'warning',
  },
]
export interface LogModel {
  code: string
  state: 'success' | 'danger' | 'warning'
  message: string
  time: string
}

const defaultLogs: Array<LogModel> = [
  {code: '200 OK', state: 'success', message: 'New order', time: 'Just now'},
  {code: '500 ERR', state: 'danger', message: 'New customer', time: '2 hrs'},
  {code: '200 OK', state: 'success', message: 'Payment process', time: '5 hrs'},
  {code: '300 WRN', state: 'warning', message: 'Search query', time: '2 days'},
  {code: '200 OK', state: 'success', message: 'API connection', time: '1 week'},
  {code: '200 OK', state: 'success', message: 'Database restore', time: 'Mar 5'},
  {code: '300 WRN', state: 'warning', message: 'System update', time: 'May 15'},
  {code: '300 WRN', state: 'warning', message: 'Server OS update', time: 'Apr 3'},
  {code: '300 WRN', state: 'warning', message: 'API rollback', time: 'Jun 30'},
  {code: '500 ERR', state: 'danger', message: 'Refund process', time: 'Jul 10'},
  {code: '500 ERR', state: 'danger', message: 'Withdrawal process', time: 'Sep 10'},
  {code: '500 ERR', state: 'danger', message: 'Mail tasks', time: 'Dec 10'},
]

export {defaultMessages, defaultUserInfos, UserInfos, defaultAlerts, defaultLogs, messageFromClient}
