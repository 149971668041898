/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'


type Props = {
  fileName: string
}

const FileNameCell: FC<Props> = ({fileName}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
    {fileName !== undefined ? <span>{fileName}</span>: null}
    </div>
  </div>
)

export {FileNameCell}
