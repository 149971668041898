import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const SiteProjectInfoCell: FC<Props> = ({ site }) => (

  <div className='d-flex align-items-center'>
    {site.contractorName}
  </div>

)
export { SiteProjectInfoCell }
