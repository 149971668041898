import { FormattedMessage } from 'react-intl'
import { useListView } from '../file-upload-page/core/ListViewProvider'

const FinancalOverviewHeader = () => {
//   const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="FINANCIAL.OVERVIEW"/>}</h2>
      <div className='card-toolbar'>
      </div>
    </div>
  )
}

export {FinancalOverviewHeader}
