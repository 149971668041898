import {useQuery, useQueryClient} from 'react-query'
import {RiskMatrixEditModalForm} from './RiskMatrixEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'

const RiskMatrixEditModalFormWrapper = () => {
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  // const {
  //   isLoading,
  //   data: riskMatrix,
  //   error,
  // } = useQuery(
  //   `${QUERIES.PARAMETER_LIST}-parameter-${itemIdForUpdate}`,
  //   () => {
  //     return getParameterById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )


  if (!itemIdForUpdate) {
    return <RiskMatrixEditModalForm  riskMatrix = {{ id: undefined}} />
  }

  return null
}

export {RiskMatrixEditModalFormWrapper}
