/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES, ROLES, getUserRole } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { downloadFileById, getFileById } from '../../core/_requests'

type Props = {
  id: ID,
  filePath: string
}

                                                          //Procurement Plan List

const RiskMatrixActionsCell: FC<Props> = ({ id, filePath }) => {
  const { setItemIdForDelete } = useListView()
  const role = getUserRole();

  const viewRiskMatrixFile = () => {
    var fileName = filePath.split("/").pop();
    getFileById(id, fileName)
  }

  const downloadRiskMatrixFile = () => {
    var fileName = filePath.split("/").pop();
    downloadFileById(id, fileName)
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const deleteItem = () => {
    setItemIdForDelete(id)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {<FormattedMessage id="ACTIONS" />}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={downloadRiskMatrixFile}>
          {<FormattedMessage id="FILE.DOWNLOAD" />}
          </a>
        </div>
        {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={viewRiskMatrixFile}>
          {<FormattedMessage id="VIEW" />}
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {(role == ROLES.Superadmin.name || role == ROLES.CEB.name) &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={deleteItem}
            >
              {<FormattedMessage id ="DELETE"/>}
            </a>
          </div>
        }
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { RiskMatrixActionsCell }
