import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {InformationCampaignListHeader} from './components/header/InformationCampaignListHeader'
import {InformationCampaignEditModal} from './information-campaign-modal/InformationCampaignEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { InformationCampaignTable } from './table/InformationCampaignTable'
import { InformationCampaignDeleteModal } from '../InformationCampaignDeleteModal'

const InformationCampaignList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <InformationCampaignListHeader />
        <InformationCampaignTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <InformationCampaignEditModal />}
      {itemIdForDelete !== undefined && <InformationCampaignDeleteModal />}
    </>
  )
}

const InformationCampaignListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <InformationCampaignList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {InformationCampaignListWrapper}
