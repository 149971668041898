import {Column} from 'react-table'
import {InformationCampaignDescriptionCell} from './InformationCampaignDescriptionCell'
import {InformationCampaignActionsCell} from './InformationCampaignActionsCell'
import {InformationCampaignCustomHeader} from './InformationCampaignCustomHeader'
import {InformationCampaignModel} from '../../core/_models'
import { InformationCampaignVersionDateCell } from './InformationCampaignVersionDateCell'
import { InformationCampaignFileNameCell } from './InformationCampaignFileNameCell'
import { FormattedMessage } from 'react-intl'
import { InformationCampaignLinkCell } from './InformationCampaignLinkCell'


var informationCampaignColumns: ReadonlyArray<Column<InformationCampaignModel>> = [

  {
    Header: (props) => <InformationCampaignCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <InformationCampaignDescriptionCell informationCampaign={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <InformationCampaignCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <InformationCampaignVersionDateCell informationCampaign={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <InformationCampaignCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <InformationCampaignFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <InformationCampaignCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <InformationCampaignLinkCell informationCampaign={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <InformationCampaignCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <InformationCampaignActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {informationCampaignColumns}
