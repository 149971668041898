import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
// import {createUser, updateUser} from '../core/_requests'
import { useQueryResponse } from "../core/QueryResponseProvider";
import { FormattedMessage, useIntl } from "react-intl";
import { FileModel } from "../../../file-upload-page/core/_models";
import { getFileDocumentById } from "../core/_requests";
type Props = {
  photos: Array<FileModel>
}
const ParameterEditModalForm: FC<Props> = ({photos}) => {
 
 const downloadAll = (photos:  Array<FileModel>) =>{
  photos.forEach((photo)=> getFileDocumentById(photo.fileName, photo.id)
  )
  
 }
  return (
    
    <>
    <button className='btn btn-sm btn-light-primary fw-bolder m-10 ' onClick={()=>downloadAll(photos)}> Download All Photos </button>
     {photos.map((photo)=> <div className="row"> <button  className='btn m-5 btn-sm btn-light-primary fw-bolder'onClick={()=> getFileDocumentById(photo.fileName, photo.id) }>{photo.fileName}</button></div>)}
    </>
  );
};

export { ParameterEditModalForm };
