import {Column} from 'react-table'
import {Audio_VisualToolsDescriptionCell} from './Audio_VisualToolsDescriptionCell'
import {Audio_VisualToolsActionsCell} from './Audio_VisualToolsActionsCell'
import {Audio_VisualToolsCustomHeader} from './Audio_VisualToolsCustomHeader'
import {Audio_VisualToolsModel} from '../../core/_models'
import { Audio_VisualToolsVersionDateCell } from './Audio_VisualToolsVersionDateCell'
import { Audio_VisualToolsFileNameCell } from './Audio_VisualToolsFileNameCell'
import { FormattedMessage } from 'react-intl'
import { Audio_VisualToolsLinkCell } from './Audio_VisualToolsLinkCell'


var audio_VisualToolsColumns: ReadonlyArray<Column<Audio_VisualToolsModel>> = [

  {
    Header: (props) => <Audio_VisualToolsCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <Audio_VisualToolsDescriptionCell audio_VisualTools={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <Audio_VisualToolsCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <Audio_VisualToolsVersionDateCell audio_VisualTools={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <Audio_VisualToolsCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <Audio_VisualToolsFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <Audio_VisualToolsCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <Audio_VisualToolsLinkCell audio_VisualTools={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <Audio_VisualToolsCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <Audio_VisualToolsActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {audio_VisualToolsColumns}
