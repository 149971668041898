import {FC} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  QuickLinks,
  Search,
  ThemeModeSwitcher,
} from '../../../partials'
import {useLayout} from '../../core'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'app/modules/auth'
import { useLang } from '_metronic/i18n/Metronici18n'

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {config} = useLayout()
  const today = new Date()
  const lang = useLang()
  
  const month = lang === 'en' ? today.toLocaleString('en', { month: 'long' }) : today.toLocaleString('tr', { month: 'long' });
  const day = lang === 'en' ? today.toLocaleString('en', { weekday: 'long' }) : today.toLocaleString('tr', { weekday: 'long' });

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* Activities */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
        {/* begin::Drawer toggle */}
        {/* <div className={btnClass} id='kt_activities_toggle'>
          <i className='bi bi-bell fs-2' />
        </div> */}
        {/* end::Drawer toggle */}
      {/* </div> */}

      {/* Quick links */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
        {/* begin::Menu wrapper */}
        {/* <div
          className={btnClass}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-clipboard-check fs-2' />
        </div>
        <QuickLinks /> */}
        {/* end::Menu wrapper */}
      {/* </div> */}

      {/* CHAT */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
        {/* begin::Menu wrapper */}
        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            btnClass,
            'pulse pulse-success'
          )}
          id='kt_drawer_chat_toggle'
        >
          <i className='bi bi-app-indicator fs-2' />
          <span className='pulse-ring w-45px h-45px' />
        </div> */}
        {/* end::Menu wrapper */}
      {/* </div> */}

      {/* NOTIFICATIONS */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}> */}
        {/* begin::Menu- wrapper */}
        {/* <div
          className={clsx(btnClass, 'position-relative')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-grid fs-2' />
        </div>
        <HeaderNotificationsMenu /> */}
        {/* end::Menu wrapper */}
      {/* </div> */}
      
      <div className='d-flex align-items-center'>
            <a
              href='#'
              className='btn btn-sm btn-bg-light btn-color-gray-500 btn-active-color-primary me-2'
              id='kt_dashboard_daterangepicker'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-trigger='hover'
              title='Select dashboard daterange'
            >
              {/* <span className='fw-bold me-1' id='kt_dashboard_daterangepicker_title'>
                Range:
              </span> */}
              
              <span className='fw-bolder' id='kt_dashboard_daterangepicker_date'>
                {/* <?php echo date('F j')?> */}
                {/* {<FormattedMessage id="TODAY" />}: {today.getDate()} {today.toLocaleString('default', { month: 'long' })} */}
                {<FormattedMessage id="TODAY" />}: {today.getDate()} {month}
              </span>
            </a>
      </div>
      
      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={btnClass} />
      </div>
      {/* end::Theme mode */}

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx(btnClass, 'position-relative')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-grid fs-2' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export {Topbar}
