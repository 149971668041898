import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const ContractedConstructionCost_m2Eur: FC<Props> = ({ site }) => {


  return (
    <div className='d-flex align-items-center'>
      {site.contractedConstructionCostPerM2EUR != null && site.contractedConstructionCostPerM2EUR != undefined && formatEUR(site.contractedConstructionCostPerM2EUR.toFixed(2))}
    </div>
  )
}
export { ContractedConstructionCost_m2Eur }
