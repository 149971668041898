import { FC } from 'react'
import { useLang } from '../../../../../../_metronic/i18n/Metronici18n'
import { ProjectReportsListModel } from '../../core/_models'

type Props = {
  reportNo?:string
}

const ReportNoCell: FC<Props> = ({ reportNo }) => {
  const lang = useLang()

  return (
    <div className='d-flex align-items-center'>
      <a className='text-gray-800 text-hover-primary mb-1'>
        {reportNo}
      </a>
    </div>
  )
}

export { ReportNoCell }
