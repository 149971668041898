import { FC } from 'react'
import { ProjectReportsListModel } from '../../core/_models'
import { useIntl } from 'react-intl'
// import { useLang } from '../../../../../../_metronic/i18n/Metronici18n'

type Props = {
  data: ProjectReportsListModel
}

const ReportTypeCell: FC<Props> = ({ data }) => {
     const intl = useIntl();
  // const lang = useLang()
  // const langString = lang;
  return (
    <div className='d-flex align-items-center'>
      <a className='text-gray-800 text-hover-primary mb-1'>
        {data.reportType}
      </a>
    </div>
  )
}
export { ReportTypeCell }
