import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'

type Props = {
  site: SiteModel
}

const SiteDemolitionApprovalDocumentCell: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.worksCompletionPercentage}
    </div>
  )
}

export { SiteDemolitionApprovalDocumentCell }
