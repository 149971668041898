import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { ProgressPaymentListHeader } from './components/header/ProgressPaymentListHeader'
import { ProgressPaymentCivilBarchart } from './barchart/ProgressPaymentCivilBarchart'
import { KTCard } from '../../../../_metronic/helpers'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../../_metronic/helpers'
import { getContractsByProcurementTypeId } from '../../procurement-management/contract-list/core/_requests'

import { getInstituionId, getUserId, getUserRole } from '../../../../_metronic/helpers'


const ProgressPaymentBarchart = () => {
  const { itemIdForUpdate, procurementTypeId, isReportPage, isUploadPage, isChoiceContractPage, isChoiceContractTypePage, progressPaymentIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn } = useListView()
  const close = () => {
    setHelpPageOn(false)
  }

  const userId = getUserId();
  const enabledQueryAllContractList: boolean = (true) //role == "CEB"

  const {
    isLoading: isLodingAllContracts,
    data: allContracts,
    error: errorAllContracts,
  } = useQuery(
    `${QUERIES.CONTRACT_LIST}-contracts-for-file-upload-${userId}`,
    () => {
      return getContractsByProcurementTypeId("257dd6c0-0367-4d56-9772-6fe609c5216b")
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      enabled: enabledQueryAllContractList
    }
  )
  if (allContracts && !isLodingAllContracts && allContracts.data) {
    return (
      <>
        <KTCard>
          <ProgressPaymentListHeader />
          <ProgressPaymentCivilBarchart contractList={allContracts.data} />
        </KTCard>

      </>
    )
  }else{
    return (
      <>
        <KTCard>
          <ProgressPaymentListHeader />
          
        </KTCard>

      </>
    )
  }
}

const ProgressPaymentBarChartWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProgressPaymentBarchart />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ProgressPaymentBarChartWrapper }
