import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatEUR } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const Budget: FC<Props> = ({ site }) => {


  return (
    <div className='d-flex align-items-center'>
      {site.budgetEUR != null && site.budgetEUR != undefined && formatEUR(site.budgetEUR.toFixed(2))}
    </div>
  )
}
export { Budget }
