import ReactApexChart from "react-apexcharts"
import { getFinancialOverviewGraphic, getFinancialOverviewProgressPayment } from "./core/_requests"
import { useLang } from "_metronic/i18n/Metronici18n"
import { useEffect, useState } from "react"
import { useThemeMode } from "_metronic/partials"
import { FormattedMessage } from "react-intl"

const FinancialOverviewTable = () => {
  const { mode } = useThemeMode()
  const textColor = mode === "dark" ? "white" : "black";
  const lang = useLang()
  const [data, setData] = useState();
  const [dataPP, setDataPP] = useState();
  const [stateLabelsTR, setStateLabelsTR] = useState<string[]>(['Yeni İnşaat', 'GSM & G/GSM Rehabilitasyon', 'FTR Renovasyon', 'Hastane Rehabilitasyonu']);
  const [stateLabelsEN, setStateLabelsEN] = useState<string[]>(['New Construction', 'MHC&E/MHC Rehabilitation', 'PTR Renovation', 'Hospital Rehabilitation']);

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '30px 0 0 0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  useEffect(() => {
   getFinancialOverviewData();
   getFinancialOverviewProgressPaymentData();
  }, )

  const getFinancialOverviewData = async ()=>{
    const dataForGraphic = await getFinancialOverviewGraphic(lang)
    setData(dataForGraphic.data)
    console.log("grafik: "+dataForGraphic.data)

  }

  const getFinancialOverviewProgressPaymentData = async ()=>{
    const dataForProgressPayment = await getFinancialOverviewProgressPayment(lang)
    setDataPP(dataForProgressPayment.data)
    console.log("pp: "+dataForProgressPayment.data)
  }



  // const {
  //   isLoading,
  //   data: maf,
  //   error,
  // } = useQuery(
  //   `Financial-Overview-Graphic`,
  //   () => {
  //     return getFinancialOverviewGraphic(lang)
  //   },
  //   {
  //     cacheTime: 0,
  //   }
  // )


  // APEXCHART - START
  var state = {
    series: data,
    options: {
      title: {
        text: '',
      },
      xaxis: {
        categories: lang === 'en'? stateLabelsEN : stateLabelsTR,
        labels: {
          rotate: -60,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginLeft: "25%",
            colors: textColor
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: textColor
          }
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60
        },
      }
    },
  };

  var statePP = {
    series: dataPP,
    options: {
      title: {
        text: '',
      },
      xaxis: {
        categories: lang === 'en'? stateLabelsEN : stateLabelsTR,
        labels: {
          rotate: -60,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginLeft: "25%",
            colors: textColor
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: textColor
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val:any) {
            return val + " €"
          }
        }
      }
    },
  };

  //APEXCHART - END

  return (
    <>
      <style>
        {`
          .apexcharts-legend-text {
            color: ${textColor} !important;
          }
        `}
      </style>
      {(!data && !dataPP) && (
        <div style={{...styles, position: 'absolute', textAlign: 'center'}}>{<FormattedMessage id ="PROCESSING"/>}</div>
      )}
  
      {data && (
        <div style={{margin:"15px"}}>
          <div id="chart">
            <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
          </div>

        </div>
      )}
          <br></br>

      {dataPP && (
        <div style={{margin:"15px"}}>
          <div id="chart">
            <ReactApexChart options={statePP.options} series={statePP.series} type="bar" height={350} />
          </div>
        </div>
      )}
        

    </>
  );  
}

export { FinancialOverviewTable }