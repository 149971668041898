import {useQuery} from 'react-query'
import {ProjectReportsEditModalForm} from './ProjectReportsEditModalForm'
import {getUserId, getUserRole, isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getProjectReportById} from '../core/_requests'

const ProjectReportsEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const userId = getUserId()
  const userRole = getUserRole()
  const {
    isLoading,
    data: projectReport,
    error,
  } = useQuery(
    `${QUERIES.PROJECT_REPORTS_MANAGEMENT_LIST}-pr-management-${itemIdForUpdate}`,
    () => {
      return getProjectReportById(itemIdForUpdate,userId, userRole)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  
  // const {
  //   isLoading: mafTypesLoding,
  //   data: MAFTypes,
  //   error: mafTypesErr,
  // } = useQuery(
  //   `${QUERIES.PARAMETER_LIST}-parameters-${"d1d314cd-dca6-478f-b1cc-6a30ea89409a"}`,
  //   () => {
  //     return getMinorsParameterTypeById("d1d314cd-dca6-478f-b1cc-6a30ea89409a")
  //   },
  //   {
  //     cacheTime: 0,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  if (!isLoading  && projectReport) {
    return <ProjectReportsEditModalForm isLoading={isLoading} ProjectReport={projectReport}  />
  }

  if (!isLoading) {
    return <ProjectReportsEditModalForm isLoading={isLoading} ProjectReport = {{ id: undefined, reportType:''}} />
  }

  return null
}

export {ProjectReportsEditModalFormWrapper}
