import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {SiteModel} from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { EstimatedProcurementCostTry } from './EstimatedProcurementCostTry'
import { EstimatedProcurementCostEur } from './EstimatedProcurementCostEur'
import { ContractedConstrucitonCostTry } from './ContractedConstrucitonCostTry'
import { ExchangeRateAtContractSignatureDate } from './ExchangeRateAtContractSignatureDate'
import { ContractedConstructionCostEur } from './ContractedConstructionCostEur'
import { ContractedConstructionCost_m2Eur } from './ContractedConstructionCost_m2Eur'
import { Budget } from './Budget'
import { SiteNumberIdentificationCell } from '../../shifa-scope-table/columns/SiteNumberIdentificationCell'
import { BuildingNameCell } from '../../shifa-scope-table/columns/BuildingNameCell'



const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.SITE.NUMBER.IDENTIFICATION'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <SiteNumberIdentificationCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'ESTIMATED.PROCUREMENT.COST.TRY'/>}  className='min-w-200px' />,
    id: 'epcTry',
    Cell:({...props}) => <EstimatedProcurementCostTry site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'ESTIMATED.PROCUREMENT.COST.EUR'/>} className='min-w-200px' />,
    id: 'epcEur',
    Cell:({...props}) => <EstimatedProcurementCostEur site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACTED.CONSTRUCTION.COST.TRY'/>}  className='min-w-200px' />,
    id: 'cccTry',
    Cell:({...props}) => <ContractedConstrucitonCostTry site={props.data[props.row.index]} />,
  }, 

  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'EXCHANGE.RATE.AT.CONTRACT.SIGNATURE.DATE'/>} className='min-w-200px' />,
    id: 'exchangeRateAtContractSignatureDate',
    Cell:({...props}) => <ExchangeRateAtContractSignatureDate site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACTED.CONSTRUCTION.COST.EUR'/>} className='min-w-200px' />,
    id: 'contractedConstructionCostEur',
    Cell:({...props}) => <ContractedConstructionCostEur site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACTED.CONSTRUCTION.COST/M2.EUR'/>}  className='min-w-200px' />,
    id: 'contractedConstructionCost/m2Eur',
    Cell:({...props}) => <ContractedConstructionCost_m2Eur site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'BUDGET.EUR'/>}  className='min-w-200px' />,
    id: 'budgetEur',
    Cell:({...props}) => <Budget site={props.data[props.row.index]} />,
  }
]

export {sitesColumns}
