import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { BugReportingListWrapper } from './bug-reporting-list/BugReportingList'


const BugReportingPage = () => {
  const intl = useIntl()

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: <FormattedMessage id ="BUGREPORTING.LIST"/>,
      path: 'bug-reporting',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='bug-reportings'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'BUGREPORTING.LIST' })}</PageTitle>
              <BugReportingListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/bug-reporting/bug-reportings' />} />
    </Routes>
  )
}

export default BugReportingPage
