import { FC ,useState} from 'react'
import {SiteModel} from '../../../core/_models'
import { useLang } from '_metronic/i18n/Metronici18n'
type Props = {
    site: SiteModel
}
const SiteCurrentStatusOfLandCell: FC<Props> = ({site}) => {
  const lang = useLang()
  return(
      <div className='d-flex align-items-center'>
          {(lang == "tr") && <span>{site.contractStatus}</span>}
          {(lang == "en") && <span>{site.contractStatusEng}</span>}
      </div>
  )
}
export { SiteCurrentStatusOfLandCell }
