/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'

type Props = {
  context: String
}

const BugReportingContextCell: FC<Props> = ({context}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <span>{context}</span>
    </div>
  </div>     

)}
export {BugReportingContextCell}
