/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'


type Props = {
  filePath: string
}

const LogFrameFileNameCell: FC<Props> = ({filePath}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {filePath.split("/").pop()}
      </a>
    </div>
  </div>     

)}
export {LogFrameFileNameCell}
