import axios, { AxiosResponse } from 'axios'
import { EstimatedCostQueryResponse, EstimatedCostModel } from './_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import fileDownload from 'js-file-download'

//const API_URL = "http://localhost:5010/api/log-frame"
//const API_FILE_URL = "http://localhost:5010/api/log-frame"

const API_URL = "https://shifatr.org/api/estimatedcost"
const API_FILE_URL = "https://shifatr.org/api/estimatedcost"
const getSitesWithEstimatedCosts = async (query: string): Promise<EstimatedCostQueryResponse> => {
    return await axios
    .get(`${API_URL}?${query}`)
  }

const createLogFrameFile = (logFrame: any): Promise<EstimatedCostModel | undefined> => {
  return axios

    .post(`${API_FILE_URL}`, logFrame,{
      headers: {
        "Content-Type": "multipart/form-data",
      }})
    .then((response: Response<EstimatedCostModel>) => response.data)
}

const getEstimatedCostById = (id: ID): Promise<EstimatedCostModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: Response<EstimatedCostModel>) => response.data)
}

const updateEstimatedCost = (estimatedCost: EstimatedCostModel): Promise<EstimatedCostModel | undefined> => {
  return axios
    .put(`${API_URL}/${estimatedCost.id}`, estimatedCost)
    .then((response: AxiosResponse<Response<EstimatedCostModel>>) => response.data)
    .then((response: Response<EstimatedCostModel>) => response.data)
}


export { getSitesWithEstimatedCosts, createLogFrameFile, getEstimatedCostById, updateEstimatedCost }