import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const FinalAcceptance: FC<Props> = ({ site }) => {

  var formattedDate = '';

    if (site.finalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications != null || site.finalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications != undefined) {
        formattedDate = site.finalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications === '0001-01-01T00:00:00' ? '' : new Date(site.finalAcceptanceTechnicalInspectionRemedyDefectsAndCertifications).toLocaleDateString('tr-TR');
    }

    return (
        <div className='d-flex align-items-center'>
            {formattedDate}
        </div>
    )
}
export { FinalAcceptance }
