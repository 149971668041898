/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {EstimatedCostModel} from '../../core/_models'

type Props = {
  estimatedCost: EstimatedCostModel
}

const EstimatedCostSiteNameCell: FC<Props> = ({estimatedCost}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {estimatedCost.site}
      </a>
      
    </div>
  </div>     

)}
export {EstimatedCostSiteNameCell}
