import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {SiteModel} from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { WorksRenovationCell } from './WorksRenovationCell'
import { WorksNewEmhcCell } from './WorksNewEmhcCell'
import { WorksRehabilitionCell } from './WorksRehabilitionCell'



const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'WORKS.FOR.THE.CONSTRUCTION.OF.NEW.E.MHCS'/>}  className='min-w-200px' />,
    id: 'WORKSFORTHECONSTRUCTIONOFNEWEMHCS',
    Cell:({...props}) => <WorksNewEmhcCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'WORKS.FOR.THE.REHABILITATION.OF.EXISTING.E.MHCS'/>}  className='min-w-200px' />,
    id: 'WORKSFORTHEREHABILITATIONOFEXISTINGEMHCS',
    Cell:({...props}) => <WorksRehabilitionCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'WORKS.FOR.THE.RENOVATION.OF.PTR.UNITS'/>}  className='min-w-200px' />,
    id: 'WORKSFORTHERENOVATIONOFPTRUNITS',
    Cell:({...props}) => <WorksRenovationCell site={props.data[props.row.index]} />,
  }
]

export {sitesColumns}
