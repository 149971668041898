import {Column} from 'react-table'
import {CommunicationStrategyDescriptionCell} from './CommunicationStrategyDescriptionCell'
import {CommunicationStrategyActionsCell} from './CommunicationStrategyActionsCell'
import {CommunicationStrategyCustomHeader} from './CommunicationStrategyCustomHeader'
import {CommunicationStrategyModel} from '../../core/_models'
import { CommunicationStrategyVersionDateCell } from './CommunicationStrategyVersionDateCell'
import { CommunicationStrategyFileNameCell } from './CommunicationStrategyFileNameCell'
import { FormattedMessage } from 'react-intl'
import { CommunicationStrategyLinkCell } from './CommunicationStrategyLinkCell'

var communicationStrategysColumns: ReadonlyArray<Column<CommunicationStrategyModel>> = [

  {
    Header: (props) => <CommunicationStrategyCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <CommunicationStrategyDescriptionCell communicationStrategy={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CommunicationStrategyCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <CommunicationStrategyVersionDateCell communicationStrategy={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CommunicationStrategyCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <CommunicationStrategyFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <CommunicationStrategyCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <CommunicationStrategyLinkCell communicationStrategy={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CommunicationStrategyCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <CommunicationStrategyActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {communicationStrategysColumns}
