/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OnlineToolsModel} from '../../core/_models'

type Props = {
  onlineTools: OnlineToolsModel
}

const OnlineToolsDescriptionCell: FC<Props> = ({onlineTools}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {onlineTools.description}
      </a>
      
    </div>
  </div>     

)}
export {OnlineToolsDescriptionCell}
