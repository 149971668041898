import { FormattedMessage } from 'react-intl'
import {CommunicationStrategyListToolbar} from './CommunicationStrategyListToolbar'

const CommunicationStrategyListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="COMMUNICATION.STRATEGY.LIST"/>}</h2>
      {/* <ParametersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<CommunicationStrategyListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CommunicationStrategyListHeader}
