import { FormattedMessage } from "react-intl"
import {KTSVG, ROLES, getUserRole, stringifyRequestQuery} from "../../../../../../_metronic/helpers"
import {useListView} from '../../core/ListViewProvider'
import {PrintedMaterialsListFilter} from './PrintedMaterialsListFilter'
import { useQueryRequest } from "../../core/QueryRequestProvider"
import { useMemo, useState } from "react"
import { getChecklistAsExcel, getChecklistAsPdf } from "../../core/_requests"

const PrintedMaterialsListToolbar = () => {
  const role = getUserRole();
  const {setItemIdForUpdate, setHelpPageOn} = useListView()
  const openAddParameterModal = () => {
    setItemIdForUpdate(null)
  }

  const {state} = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  
  
  const openHelpPage = () => {
    setHelpPageOn(true)
  }
  const onDownloadPdf = () => {
    getChecklistAsPdf(query)
  }
  const onDownloadExcel = () => {
    getChecklistAsExcel(query)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-users-table-toolbar='base'>
      <button type='button' className='btn btn-light-primary me-3' onClick={onDownloadPdf}>
      <KTSVG path='/media/icons/duotune/arrows/arr044.svg' className='svg-icon-2' />
      <FormattedMessage id = 'DOWNLOAD.CHECKLIST.PDFSHORT'/>
    </button>
    <button type='button' className='btn btn-light-primary me-3' onClick={onDownloadExcel}> 
      <KTSVG path='/media/icons/duotune/arrows/arr044.svg' className='svg-icon-2' />
      <FormattedMessage id = 'DOWNLOAD.CHECKLIST.EXCELSHORT'/> 
    </button>
      {/* <button type='button' className='btn btn-light-primary me-3' onClick={openHelpPage}>
        <KTSVG path='/media/svg/icons/Code/Question-circle.svg' className='svg-icon-2' />
        Help
      </button> */}
      <PrintedMaterialsListFilter />


      {/* begin::Add Parameter */}
      {(role == ROLES.Superadmin.name || role == ROLES.CEB.name || role == ROLES.GOPA.name) &&
      <button type='button' className='btn btn-primary' onClick={openAddParameterModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {<FormattedMessage id ="ADD.NEW.PRINTED.MATERIAL"/>}
      </button>
      }
      {/* end::Add Parameter */}
    </div>
  )
}

export {PrintedMaterialsListToolbar}
