import { FC, useEffect, useRef, useState } from 'react'
import { ProjectStatusStep } from './steps/ProjectStatusStep'

import { StepperComponent } from '../../../../../../_metronic/assets/ts/components'
import { Formik, Form, FormikValues } from 'formik'
import { createAccountSchemas, ICreateAccount, inits } from './CreateAccountWizardHelper'
import { FormattedMessage } from 'react-intl'
import { ShifaScopeStep } from './steps/ShifaScopeStep'
import { CostStep } from './steps/CostStep'
import { DesignPhase } from './steps/DesignPhase'

const Horizontal: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    stepper.current.goPrev()
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1)
    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  const stepChange = (step: number) => {
    if (!stepper.current) {
      return
    }
    stepper.current.goto(step)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column '
          id='kt_mhc_checklist_monitoring_site_info_stepper'
        >
          <div className='stepper-nav mb-1'>
            <div className='stepper-item current ' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(1) }}> <FormattedMessage id="COST.INCURRED.UNDER.CONTRACTS" /></a>   {/*onClick={()=>{stepChange()}}*/}
            </div>

            <div className='stepper-item ' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(2) }}>{<FormattedMessage id='PAYMENTS' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(3) }}>{<FormattedMessage id='BUDGET.ITEM' />} </a>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <a href="#" className="fs-5 fw-bold" onClick={() => { stepChange(4) }}>{<FormattedMessage id='SOURCE.OF.FUNDING' />} </a>
            </div>
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto  w-100 overflow-scroll ' id='kt_mhc_checklist_monitoring_site_info_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <ShifaScopeStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <ProjectStatusStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <CostStep />
                </div>

                <div data-kt-stepper-element='content'>
                  <DesignPhase />
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { Horizontal }
