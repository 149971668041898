import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const Water: FC<Props> = ({ site }) => {

    var formattedDate = '';

    if (site.water != null || site.water != undefined) {
        formattedDate = site.water === '0001-01-01T00:00:00' ? '' : new Date(site.water).toLocaleDateString('tr-TR');
    }

    return (
        <div className='d-flex align-items-center'>
            {formattedDate}
        </div>
    )

}
export { Water }
