import {Column} from 'react-table'
import {LogFrameDescriptionCell} from './LogFrameDescriptionCell'
import {LogFrameActionsCell} from './LogFrameActionsCell'
import {LogFrameCustomHeader} from './LogFrameCustomHeader'
import {LogFrameModel} from '../../core/_models'
import { LogFrameVersionDateCell } from './LogFrameVersionDateCell'
import { LogFrameFileNameCell } from './LogFrameFileNameCell'
import { FormattedMessage } from 'react-intl'


var logFramesColumns: ReadonlyArray<Column<LogFrameModel>> = [

  {
    Header: (props) => <LogFrameCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <LogFrameDescriptionCell logFrame={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <LogFrameCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <LogFrameVersionDateCell logFrame={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <LogFrameCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <LogFrameFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => (
      <LogFrameCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <LogFrameActionsCell id={props.data[props.row.index].id} filePath={props.data[props.row.index].filePath } />,
  }
]


export {logFramesColumns}
