import React, {FC} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import { ConstructionPhaseTable } from '../../../tables/construction-phase-table/ConstructionPhaseTable'

const ConstructionPhaseStep: FC = () => {
  return (
 <ConstructionPhaseTable/>
  )
}

export {ConstructionPhaseStep}
