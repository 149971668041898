import React, { FC } from "react";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import {ShifaScopeTable } from "../../../tables/shifa-scope-table/ShifaScopeTable";
import { InfrastructureConnectionsTable } from "../../../tables/infrastructure-connections-table/InfrastructureConnectionsTable";

const InfrastructureConnectionsStep: FC = () => {
  return (
    <InfrastructureConnectionsTable/>
  );
};

export { InfrastructureConnectionsStep };
