/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {RiskMatrixModel} from '../../core/_models'

type Props = {
  riskMatrix: RiskMatrixModel
}

const RiskMatrixDescriptionCell: FC<Props> = ({riskMatrix}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {riskMatrix.description}
      </a>
      
    </div>
  </div>     

)}
export {RiskMatrixDescriptionCell}
