import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {SiteModel} from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { EuGrantCell } from './EuGrantCell'
import { NationalContribution } from './NationalContribution'

const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'EU.GRANT'/>}  className='min-w-200px' />,
    id: 'EUGRANT',
    Cell:({...props}) => <EuGrantCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'NATIONAL.CONTRIBUTION'/>}  className='min-w-200px' />,
    id: 'NATIONALCONTRIBUTION',
    Cell:({...props}) => <NationalContribution site={props.data[props.row.index]} />,
  }
]

export {sitesColumns}
