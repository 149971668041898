/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PrintedMaterialsModel} from '../../core/_models'

type Props = {
  printedMaterials: PrintedMaterialsModel
}

const PrintedMaterialsLinkCell: FC<Props> = ({printedMaterials}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {printedMaterials.link ? printedMaterials.link : ""}
      </a>
    
    </div>
  </div>     

)}
export {PrintedMaterialsLinkCell}
