import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
import { FormattedMessage } from 'react-intl'

type Props = {
  site: SiteModel
}

const SitePhotosBeforeConstruction: FC<Props> = ({ site }) => {


  return (
    <div className='d-flex align-items-center'>
      {site.criticalPath == true && <span>{<FormattedMessage id='YES' />}</span>}
      {site.criticalPath == false && <span>{<FormattedMessage id='NO' />}</span>}
    </div>
  )
}
export { SitePhotosBeforeConstruction }
