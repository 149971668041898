import { ID, KTSVG } from "_metronic/helpers"
import { useLang } from "_metronic/i18n/Metronici18n"
import { SiteModel } from "app/modules/procurement-management/core/_models"
import { getSitesByProcurementId } from "app/modules/procurement-management/site-list/core/_requests"
import { FC, Fragment, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"

type Props = {
    procurementId: ID,
    open: boolean
}

const SiteList: FC<Props> = (props) => {
    const [procurementId, setProcurementId] = useState<ID | undefined>(undefined);
    const [siteList, setSiteList] = useState<SiteModel[] | undefined>([]);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.open === true) {
            setOpen(true);
            setProcurementId(props.procurementId);
        }
    }, [props.open])

    useEffect(() => {
        if (open === true) {
            getSitesByProcurementId(procurementId).then(data => {
                setSiteList(data)
            })
        }
        else {
            setProcurementId(undefined);
            setSiteList([]);
        }
    }, [open])
    const lang = useLang()
    const langString = lang;
    return (
        <>
            {open &&
                <>
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_user'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='true'
                    >
                        <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable mw-700px'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    {<h2 className='fw-bolder'>{<FormattedMessage id='SITE.LIST' />}</h2>}

                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={() => {
                                            setOpen(false)
                                            setProcurementId(undefined)
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                                    </div>
                                </div>
                                <div className='modal-body scroll-y mx-5 mx-xl-15 my-0'>
                                    <div className='table-responsive'>
                                        {/* begin::Table */}
                                        <table className='table align-middle gs-0 gy-4'>
                                            {/* begin::Table head */}
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4 min-w-125px rounded-start'>{<FormattedMessage id='SITE.TYPE' />}</th>
                                                    <th className='min-w-325px'>{<FormattedMessage id='SITE.NAME' />}</th>
                                                    <th className='min-w-125px'>{<FormattedMessage id='CITY' />}</th>
                                                </tr>
                                            </thead>
                                            {/* end::Table head */}
                                            {/* begin::Table body */}
                                            <tbody>
                                                {siteList && siteList.length !== 0 ? siteList.map((site) => {
                                                    return (
                                                        <Fragment key={site.id}>
                                                            <tr>
                                                                <td>
                                                                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                                    {(langString == "en" && site.siteTypeName === "Yeni İnşaat") ? "New Construction" : 
                                                                    (langString == "en" && site.siteTypeName === "FTÜ Renovasyon") ? "PTR Renovation" : 
                                                                    (langString == "en" && site.siteTypeName === "G/GSM Rehabilitasyon") ? "MHC&E/MHC Rehabilitation":
                                                                    (langString == "en" && site.siteTypeName === "Hastane Rehabilitasyonu") ? "Hospital Rehabilitation" :
                                                                    (langString == "tr" && site.siteTypeName === "FTÜ Renovasyon") ? "FTR Renovasyon" :
                                                                    site.siteTypeName}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                                        {site.nameOfTheProject}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                                        {site.cityName}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                }) :
                                                    <tr aria-colspan={3}>
                                                        <td>
                                                            <div className='d-flex text-center w-00 align-content-center justify-content-center'>
                                                                {<FormattedMessage id="NO.RECORD.FOUND" />}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-backdrop fade show'></div>
                </>
            }
        </>
    )
}

export default SiteList;