import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CommunicationStrategyListWrapper} from './communication-strategy/CommunicationStrategyList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="COMMUNICATION.STRATEGY"/>,
    path: 'communication-strategy',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  }
]

const CommunicationStrategyPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='cs'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'COMMUNICATION.STRATEGY.LIST' })}</PageTitle>
              <CommunicationStrategyListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/communication-strategy/cs' />} />
    </Routes>
  )
}

export default CommunicationStrategyPage
