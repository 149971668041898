import { FormattedMessage } from 'react-intl'
import {EstimatedCostListToolbar} from './EstimatedCostListToolbar'

const EstimatedCostListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="ESTIMATED.COST.SITE.LIST"/>}</h2>
      <div className='card-toolbar'>
        {<EstimatedCostListToolbar />}
      </div>
    </div>
  )
}

export {EstimatedCostListHeader}
