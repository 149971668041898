import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const ContractedConstrucitonCostTry: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.contractedConstructionCostTRY != null && site.contractedConstructionCostTRY != undefined && formatTRY(site.contractedConstructionCostTRY.toFixed(2))}
    </div>
  )
}
export { ContractedConstrucitonCostTry }
