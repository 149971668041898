import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQueryClient } from 'react-query'
import { KTSVG, QUERIES } from '../../../_metronic/helpers'
import { useListView } from './audio_visual-tools/core/ListViewProvider'
import { useQueryResponse } from './audio_visual-tools/core/QueryResponseProvider'
import { deleteAudio_VisualTools } from './audio_visual-tools/core/_requests'
import { toast } from 'react-toastify';

const Audio_VisualToolsDeleteModal = () => {

  const { setItemIdForDelete, itemIdForDelete } = useListView()
  const { query } = useQueryResponse();
  const queryClient = useQueryClient();

  const cancel = () => {
    setItemIdForDelete(undefined)
  };

  const intl = useIntl()

  const submit = useMutation(() => deleteAudio_VisualTools(itemIdForDelete), {
    onSuccess: () => {
      setItemIdForDelete(undefined)
      queryClient.invalidateQueries([`${QUERIES.PROCUREMENT_PLAN_LIST}-${query}`])
      toast.success(intl.formatMessage({ id: 'SUCCESS_TOASTER' }));
    },
    onError: () => {
      toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
    }
  });

  return (
    <>
      <div className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'>

        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{<FormattedMessage id='DELETE.CONFIRM.HEADER' />}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                  setItemIdForDelete(undefined)
                }}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className="modal-body">
              <h6>{<FormattedMessage id='DELETE.CONFIRM.BODY' />}</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={cancel}
              >
                {<FormattedMessage id='NO' />}
              </button>
              <button type="button"
                className="btn btn-danger"
                onClick={async () => await submit.mutateAsync()}>
                {<FormattedMessage id='YES' />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>

  )
}

export { Audio_VisualToolsDeleteModal }
