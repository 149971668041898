import {useQuery, useQueryClient} from 'react-query'
import {EstimatedCostEditModalForm} from './EstimatedCostEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { getEstimatedCostById } from '../core/_requests'

const EstimatedCostEditModalFormWrapper = () => {
  
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: estimatedCost,
    error,
  } = useQuery(
    `${QUERIES.PARAMETER_LIST}-estimatedCost-${itemIdForUpdate}`,
    () => {
      return getEstimatedCostById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  console.log("dsfdsfdsfds",estimatedCost)
  console.log("bbbbbbbbbbbbbbb",itemIdForUpdate)


  if (!itemIdForUpdate) {
    return <EstimatedCostEditModalForm estimatedCost={{ id: undefined }} isEstimatedCostLoading={isLoading} />
  }

  if (estimatedCost && itemIdForUpdate) {
    console.log(estimatedCost)
    return <EstimatedCostEditModalForm estimatedCost={estimatedCost} isEstimatedCostLoading={isLoading} />
  }

  return null
}

export {EstimatedCostEditModalFormWrapper}
