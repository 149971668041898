import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'

import { SiteModel } from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { BuildingNameCell } from './BuildingNameCell'
import { SiteArchitecturalCell } from './SiteArchitecturalCell'
import { ProvisionalAcceptance } from './ProvisionalAcceptance'
import { DefectLiabilityPeriod } from './DefectLiabilityPeriod'
import { SiteBoQCell } from './SiteBoQCell'
import { DefectLiabilityPeriodFinalAcceptance } from './DefectLiabilityPeriodFinalAcceptance'


const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  
  
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SITE.HANDOVER'/>}  className='min-w-200px' />,
    id: 'siteHandover',
    Cell:({...props}) => <SiteArchitecturalCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'EXPECTED.CONSTRUCTION.WORKS.COMPLETION.DATE'/>} className='min-w-200px' />,
    id: 'sboQ',
    Cell:({...props}) => <SiteBoQCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'TECHNICAL.INSPECTION.REMEDY.DEFECTS.AND.CERTIFICATIONS'/>}className='min-w-200px' />,
    id: 'tirdac',
    Cell:({...props}) => <ProvisionalAcceptance site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'DEFECT.LIABILITY.PERIOD'/>}className='min-w-200px' />,
    id: 'defectLiabilityPeriod',
    Cell:({...props}) => <DefectLiabilityPeriod site={props.data[props.row.index]} />,
  },{
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'TECHNICAL.INSPECTION.REMEDY.DEFECTS.AND.CERTIFICATIONS'/>}className='min-w-200px' />,
    id: 'defectLiabilityPeriodFinalAcceptance',
    Cell:({...props}) => <DefectLiabilityPeriodFinalAcceptance site={props.data[props.row.index]} />,
    
  }
  
  
]

export {sitesColumns}
