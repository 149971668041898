import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {VisibilityEventListWrapper} from './visibility-event/VisibilityEventList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="VISIBILITY.EVENTS"/>,
    path: 'visibility-event',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const VisibilityEventPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='ve'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'VISIBILITY.EVENTS.LIST' })}</PageTitle>
              <VisibilityEventListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/visibility-event/ve' />} />
    </Routes>
  )
}

export default VisibilityEventPage
