import { useEffect, useMemo, useState } from 'react'
import { useQueryResponse, useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import Chart from 'react-apexcharts';
import { useFormik } from "formik";
import { useListView } from '../core/ListViewProvider'
import { ContractModel, ListSite, } from "../../../procurement-management/core/_models";
import { getContractBySites, getContractBySites2 } from 'app/modules/procurement-management/contract-list/core/_requests';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { FC } from "react";
import { getProgressPaymentById, getProgressPaymenttoBarchartById } from '../core/_requests';
import { StateSeriesModel } from '../core/_models';
import { useQuery } from 'react-query';
import { QUERIES } from '_metronic/helpers';
import { getLotsByContractId } from 'app/modules/procurement-management/lot-list/core/_requests';
import ReactApexChart from 'react-apexcharts';
import { ThemeModeComponent } from '_metronic/assets/ts/layout/ThemeMode';
import { useThemeMode } from '_metronic/partials/layout/theme-mode/ThemeModeProvider';
import { useLang } from '_metronic/i18n/Metronici18n';

type Props = {
  contractList: Array<ContractModel>;
};

const ProgressPaymentCivilBarchart: FC<Props> = ({ contractList }) => {
  const {mode} = useThemeMode()
  const { setIsChoiceContractPage, setIsChoiceContractTypePage, setContractId, contractId, setProgressPaymentIdForUpdate, setContractName, setContractSiteData } = useListView();
  const { refetch } = useQueryResponse();
  const [sites, setSites] = useState(["domates", "biber", "patlıcan"])
  const [stateSeries, setStateSeries] = useState<StateSeriesModel[]>();
  const [stateSeries2, setStateSeries2] = useState<StateSeriesModel[]>();
  const [stateSeries3, setStateSeries3] = useState<StateSeriesModel[]>();
  const [stateSeries4, setStateSeries4] = useState<StateSeriesModel[]>();
  const [stateLabelsTR, setStateLabelsTR] = useState<string[]>();
  const [stateLabelsEN, setStateLabelsEN] = useState<string[]>();
  const [contracts, setContracts] = useState<ApexAxisChartSeries | ApexNonAxisChartSeries>()
  const [contracts2, setContracts2] = useState<ApexAxisChartSeries | ApexNonAxisChartSeries>()
  const textColor = mode === "dark" ? "white" : "black";
  const lang = useLang()
  const choiceSchema = () => {
    return Yup.object().shape({

    });
  };

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setIsChoiceContractPage(undefined);
  };

  const editSiteSchema = choiceSchema();

  const intl = useIntl()

  const formik = useFormik({
    initialValues: {},
    validationSchema: editSiteSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const contractSiteData = await getContractBySites(contractId);
        console.log("Contract data:", contractSiteData);
        setContractSiteData(contractSiteData)
        setIsChoiceContractPage(undefined)
        setProgressPaymentIdForUpdate(null)
      } catch (ex) {
        console.error(ex);
        toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
      } finally {
        setSubmitting(true);
        cancel(true);
        // toast.success(intl.formatMessage({ id: 'SUCCESS_TOASTER' }));
      }
    },
  });


  const handleChange = async (e: any) => {
    setContractId(e)
    contractList.forEach(contract => {
      if (contract.id == e) {
        if (contract.contractName)
          setContractName(contract.contractName)
      }
    });

    var sites: any[] = [];
    const contractSiteData = await getContractBySites2(e);
    contractSiteData?.forEach((element: ListSite) => {
      sites.push(element.nameOfTheProject)
    });
    sites.sort((a,b) => a.localeCompare(b,'tr'))
    setSites(sites);

    var contracts: any[] = []
    var contracts2: any[] = []
    var siteData: any[] = []
    var stateSeries: StateSeriesModel[] = [];
    var stateSeries2: StateSeriesModel[] = [];

    const progressPaymentConstracts = await getProgressPaymenttoBarchartById(e)
    if (progressPaymentConstracts == null || progressPaymentConstracts == undefined || progressPaymentConstracts.length == 0) {
      setContracts([])
      setContracts2([])
      setStateSeries([])
      setStateSeries2([])
      toast.warning(intl.formatMessage({ id: 'NO_ProgressPayment' }));
    } else {

      const sitesData: { [key: string]: number[] } = {};
      const totalData11: number[] = [];
      const labelsTR: string[] = [];
      const labelsEN: string[] = [];

      // Grafik 3
      progressPaymentConstracts.forEach((contract:any, index: number) => {
        labelsTR.push(`Hakediş ${contract.progressPaymentSequence}`);
        labelsEN.push(`Progress Payment ${contract.progressPaymentSequence}`);
        const totalTotals: number[] = Array(progressPaymentConstracts.length).fill(0);
        contract.sites.forEach((site:any) => {
          if (!sitesData[site.name]) {
            sitesData[site.name] = Array(progressPaymentConstracts.length).fill(0);
          }
          
          sitesData[site.name][contract.progressPaymentSequence - 1] = site.total || 0;
          totalTotals[index] += site.total || 0;
        });

        totalData11[index] = totalTotals[index];
        
      });

      const formattedData = Object.keys(sitesData).map(siteName => ({
        data: sitesData[siteName],
        name: siteName,
        type: "column"
      }));

      formattedData.push({
        data: totalData11,
        name: "Total",
        type: "line"
      });
      setStateSeries3(formattedData)
      setStateLabelsTR(labelsTR);
      setStateLabelsEN(labelsEN);

      // Grafik 4
      const sitesData2: { [key: string]: number[] } = {};
      const totalData111: number[] = [];

      progressPaymentConstracts.forEach((contract:any, index: number) => {
        const manufacturingTotals: number[] = Array(progressPaymentConstracts.length).fill(0);
        contract.sites.forEach((site:any) => {
          if (!sitesData2[site.name]) {
            sitesData2[site.name] = Array(progressPaymentConstracts.length).fill(0);
          }
          sitesData2[site.name][contract.progressPaymentSequence - 1] = site.manufacturingPercentage || 0;
          manufacturingTotals[index] += site.manufacturingPercentage || 0;
        });
        totalData111[index] = manufacturingTotals[index];
      });

      const formattedData2 = Object.keys(sitesData2).map(siteName => ({
        data: sitesData2[siteName],
        name: siteName,
        type: "column"
      }));

      formattedData2.push({
        data: totalData111,
        name: "Total",
        type: "line"
      });
      setStateSeries4(formattedData2)
      /////////////////////////////////////

      progressPaymentConstracts?.forEach((hakedis: any) => {
        var contractMember: any = { name: null, data: [] };
        var contractMember2: any = { name: null, data: [] };
        var stateSeriesMember: any = { name: null, type: null, data: [] };
        var stateSeriesMember2: any = { name: null, type: null, data: [] };

        stateSeriesMember.name = hakedis.progressPaymentSequence
        stateSeriesMember.type = 'column'
        stateSeriesMember2.name = hakedis.progressPaymentSequence
        stateSeriesMember2.type = 'column'
        contractMember.name = hakedis.progressPaymentSequence
        contractMember2.name = hakedis.progressPaymentSequence

        var data: any[] = []
        var data2: any[] = []

        hakedis.sites?.forEach((site: any) => {
          const siteDataMembers: any = { name: null, total: null, progressPaymentSequence: null, manufacturingPercentage:null };
          siteDataMembers.progressPaymentSequence = hakedis.progressPaymentSequence
          siteDataMembers.name = site.name
          siteDataMembers.total = site.total
          siteDataMembers.manufacturingPercentage = site.manufacturingPercentage
          siteData.push(siteDataMembers)
          data.push(site.total)
          data2.push(site.totalPercentage)
        })
        contractMember.data = data
        contractMember2.data = data2
        contracts.push(contractMember)
        contracts2.push(contractMember2)
        stateSeries.push(stateSeriesMember)
        stateSeries2.push(stateSeriesMember2)
      })

      

      setContracts(contracts);

      setContracts2(contracts2)


      // ReactApexChart - One - Start
      var totalData: any[] = []
      var flag = true;

      stateSeries.forEach((i: any) => {
        var data: any[] = []
        if (totalData.length != 0) {////////////////////////fix the total bug
          flag = false
        }
        //totalData= []
        var countForAddingTotal = 0;
        sites.forEach((k: any) => {
          var total = 0
          siteData.forEach((j: any) => {
            if (i.name == j.progressPaymentSequence && j.name == k) {
              var number: any = null;
              number = j.total
              data.push(number)
              total += number
            }
          })
          if (flag) {
            totalData.push(total)
          } else {
            totalData[countForAddingTotal] += total
            countForAddingTotal += 1
          }
        })
        i.data = data
        if (i.name == 'Total') {
          i.data = totalData
        }
      })
      // ReactApexChart - One - End

      // ReactApexChart - Two - Start
      var totalData2: any[] = []
      var flag2 = true;
      stateSeries2.forEach((i: any) => {
        var data: any[] = []
        if (totalData2.length != 0) {
          flag2 = false
        }

        var countForAddingTotal = 0;
        sites.forEach((k: any) => {
          var total = 0
          siteData.forEach((j: any) => {
            if (i.name == j.progressPaymentSequence && j.name == k) {
              var number: any = null;
              number = j.manufacturingPercentage
              data.push(number)
              total += number
            }
          })
          if (flag2) {
            totalData2.push(total)
          } else {
            totalData2[countForAddingTotal] += total
            countForAddingTotal += 1
          }
        })
        i.data = data
        if (i.name == 'Total') {
          i.data = totalData2
        }
      })
      // ReactApexChart - Two - End

      var stateSeriesMember: any = { name: 'Total', type: 'line', data: totalData };
      var stateSeriesMember2: any = { name: 'Total', type: 'line', data: totalData2 };
      stateSeries.push(stateSeriesMember)
      stateSeries2.push(stateSeriesMember2)

      setStateSeries(stateSeries)
      setStateSeries2(stateSeries2)
    }

  }

  var options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    bar: {
      horizontal: true
    },
    xaxis: {
      categories: sites,
      labels: {
        show: true,
        style: {
          colors: textColor
        },
        formatter: function (val:any) {
          return val + ""
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val:any) {
          return val + " €"
        }
      }
    },
    series: contracts
  };

  var options2 = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    bar: {
      horizontal: true
    },
    xaxis: {
      categories: sites,
      labels: {
        show: true,
        style: {
          colors: textColor
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor
        }
      }
    },
    series: contracts2
  };

  var state = {
    series: stateSeries,
    options: {
      title: {
        text: '',
      },
      xaxis: {
        categories: sites,
        labels: {
          rotate: -60, // Etiketleri 45 derece sola döndür
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginLeft: "25%",
            colors: textColor
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: textColor
          }
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      }
    },
  };

  var state2 = {
    series: stateSeries2,
    options: {
      title: {
        text: '',
      },
      xaxis: {
        categories: sites,
        labels: {
          rotate: -60, // Etiketleri 45 derece sola döndür
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginLeft: "25%",
            colors: textColor
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: textColor
          }
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      }
    },
  };

  var state3 = {
    series: stateSeries3,
    options: {
      title: {
        text: '',
      },
      xaxis: {
        categories: lang === 'en'? stateLabelsEN : stateLabelsTR,
        labels: {
          rotate: -60, // Etiketleri 45 derece sola döndür
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginLeft: "25%",
            colors: textColor
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: textColor
          }
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      }
    },
  };

  var state4 = {
    series: stateSeries4,
    options: {
      title: {
        text: '',
      },
      xaxis: {
        categories: lang === 'en'? stateLabelsEN : stateLabelsTR,
        labels: {
          rotate: -60, // Etiketleri 45 derece sola döndür
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            marginLeft: "25%",
            colors: textColor
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: textColor
          }
        }
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
      }
    },
  };

  return (
    <div className='table-responsive'>
      <style>
        {`
          .apexcharts-legend-text {
            color: ${textColor} !important;
          }
        `}
      </style>
      <div style={{ width: "50%", marginLeft: "25%", marginTop: "2.5%", marginBottom: "3%" }}>
        <div
          className=" scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          <select
            {...formik.getFieldProps("contractId")}
            className="form-select form-select-solid fw-bolder"
            name="contractId"
            // value={contractId}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value={""}>{<FormattedMessage id="SELECT.CONTRACT" />}</option>
            {contractList && contractList.map((contract) => (
              contract.id && <option value={contract.id} key={contract.id}> {contract.contractName} </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        {(contracts != null || contracts != undefined) &&
          <div>

            <div style={{ margin: "15px", marginBottom: "5%" }}>
              <h1>{<FormattedMessage id="AMOUNT.CONTRACT.BASED" />}</h1>

                    <div style={{ position: 'relative' }}>
                      <ReactApexChart options={options} series={options.series} type='bar' height={350} />
                            <div style={{
                              position: 'absolute',
                              top: '100%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              fontSize: '14px',
                              fontWeight: 'bold',
                          }}>
                              {<FormattedMessage id="AMOUNT.CONTRACT.BASED" />} (€)
                          </div>
                      </div>
            </div>
            <div style={{ margin: "15px", marginBottom: "5%" }}>
              <h1>{<FormattedMessage id="PERCENTAGE.CONTRACT.BASED" />}</h1>
              <Chart options={options2} series={options2.series} type='bar' height={350} />
            </div>


            {(stateSeries != null || stateSeries != undefined) &&
              <div style={{ margin: "15px", marginBottom: "5%" }}>
                <h1>{<FormattedMessage id="AMOUNT.SITE.BASED" />}</h1>
                <ReactApexChart options={state3.options} series={state3.series} type="line" height={350} />
              </div>
            }
            {(stateSeries2 != null || stateSeries2 != undefined) && 
              <div style={{ margin: "15px", marginBottom: "5%" }}>
                <h1>{<FormattedMessage id="PERCENTAGE.SITE.BASED" />}</h1>
                <ReactApexChart options={state4.options} series={state4.series} type="line" height={350} />
              </div>
            }


          </div>
        }
      </div>
    </div>
  );
}
export { ProgressPaymentCivilBarchart }
