import { FormattedMessage } from 'react-intl'
import {LogFrameListToolbar} from './LogFrameListToolbar'

const LogFrameListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="LOG.FRAME.LIST"/>}</h2>
      {/* <ParametersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<LogFrameListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {LogFrameListHeader}
