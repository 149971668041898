import {Column} from 'react-table'
import {OnlineToolsDescriptionCell} from './OnlineToolsDescriptionCell'
import {OnlineToolsActionsCell} from './OnlineToolsActionsCell'
import {OnlineToolsCustomHeader} from './OnlineToolsCustomHeader'
import {OnlineToolsModel} from '../../core/_models'
import { OnlineToolsVersionDateCell } from './OnlineToolsVersionDateCell'
import { OnlineToolsFileNameCell } from './OnlineToolsFileNameCell'
import { FormattedMessage } from 'react-intl'
import { OnlineToolsLinkCell } from './OnlineToolsLinkCell'


var onlineToolsColumns: ReadonlyArray<Column<OnlineToolsModel>> = [

  {
    Header: (props) => <OnlineToolsCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <OnlineToolsDescriptionCell onlineTools={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <OnlineToolsCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <OnlineToolsVersionDateCell onlineTools={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <OnlineToolsCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <OnlineToolsFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <OnlineToolsCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <OnlineToolsLinkCell onlineTools={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OnlineToolsCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <OnlineToolsActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {onlineToolsColumns}
