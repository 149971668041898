import React, { FC } from "react";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import { ShifaImplementationTable } from "../../../tables/shifa-implementation-table/ShifaImplementationTable";

const ShifaImplementationTableStep: FC = () => {
  return (
    <ShifaImplementationTable/>
  );
};

export { ShifaImplementationTableStep };
