import { FormattedMessage } from 'react-intl'
import {useListView} from '../../core/ListViewProvider'
//import {ProgressPaymentListToolbar} from './ProgressPaymentListToolbar'
// import {ParametersListSearchComponent} from './ParametersListSearchComponent'

const ProgressPaymentListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="PROGRESSPAYMENT_GRAPH"/>}</h2>
      <div className='card-toolbar'>
      </div>
    </div>
  )
}

export {ProgressPaymentListHeader}
