/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { FormattedMessage } from 'react-intl'
import { useThemeMode } from "_metronic/partials/layout/theme-mode/ThemeModeProvider";

type Props = {
  theme: any,
  title: string;
  chartColor: string;
  chartHeight: string;
  cityId: string;
  siteCounts: Array<number>
  siteStatus: Array<string>
};



const MixedWidget18: React.FC<Props> = ({ theme, title, siteCounts, siteStatus, chartColor, chartHeight, cityId }) => {

  const chartRef = useRef<HTMLDivElement | null>(null);
  let totalSiteCount: number = 0;
  siteCounts.forEach(element => {
    totalSiteCount += element
  });
  
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(theme, chartColor, chartHeight, siteCounts, siteStatus)
    );
    if (chart && totalSiteCount > 0) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };

  }, [chartRef, cityId, siteCounts]);

  if (totalSiteCount > 0)
    return (

      <div className={`card card-xl-strech mb-xl-8 my-auto `}>
        {/*begin:: Header */}
        <div className="card-header border-0 min-h-20px">{/* end::Header */}</div>
        {/* begin::Body */}

        {/* begin::Chart */}
        <div className="d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-90 pb-3 ">
          <div className="row">
            <span>{title}</span>
            {/* begin::Chart */}
            <div ref={chartRef} className="mixed-widget-11-chart"></div>
            {/* end::Chart */}
          </div>
        </div>
      </div>


    );
  if (totalSiteCount <= 0) {
    return (
      <div className="d-flex justify-content-center p-10">
        <span>{<FormattedMessage id="EMPTY.SITE" />}</span>
      </div>
    )
  }
  return null

};

const chartOptions = (theme: any, chartColor: string, chartHeight: string, siteCounts: Array<number>, siteStatus: Array<string>): ApexOptions => {

  let fontColor = '';
  if(theme.mode == 'dark' || theme.mode == 'system') {
    fontColor = 'white';
  }
  
  if (theme.mode == 'light') {
    fontColor = 'black';
  }

  return {
    series: siteCounts,
    chart: {
      foreColor:  fontColor,
      fontFamily: "inherit",
      type: "pie",
      width: 500,
      height: 600,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        expandOnClick: true,
      },
    },
    colors: ["#cfaf84", "#6395F2", "#84CF8C", "#d42f2f", "#33FF6B","#4287f5","#69227d","#cbcf00", "#878BB6", "#4ACAB4", "#FF5733", "#E9FF33", "#FF33CA", "#3352FF" , "#FF33BB", "#FF3349", "#8033FF", "#9CFF33", "#0049FF", "#7C00FF", "#FF00DC"],
    labels: siteStatus,
    legend: {
      show: true,
      position: "right",
      fontSize: "14px",
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };
};

export { MixedWidget18 };
