import { FormattedMessage } from 'react-intl'
import {useListView} from '../../core/ListViewProvider'
import {BugReportingListToolbar} from './BugReportingListToolbar'

const BugReportingListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'> <FormattedMessage id ="BUGREPORTING.LIST"/></h2>

      {/* <RFIsListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<BugReportingListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BugReportingListHeader}
