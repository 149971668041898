import axios from 'axios'
import { Contest_SiteEventQueryResponse, Contest_SiteEventModel } from './_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import fileDownload from 'js-file-download'

const API_URL = "https://shifatr.org/api/contest-event"
const API_FILE_URL = "https://shifatr.org/api/contest-event"

const getContest_SiteEvents = async (query: string): Promise<Contest_SiteEventQueryResponse> => {
    return await axios
    .get(`${API_URL}?${query}`)
  }

const createContest_SiteEventFile = (contest_siteEvent: any): Promise<Contest_SiteEventModel | undefined> => {
  return axios

    .post(`${API_FILE_URL}`, contest_siteEvent,{
      headers: {
        "Content-Type": "multipart/form-data",
      }})
    .then((response: Response<Contest_SiteEventModel>) => response.data)
}

const deleteContest_SiteEvent = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_FILE_URL}/${parameterId}`).then(() => { })
}

const getHelpPdf = (): any => {
  return axios
    .get(`${API_URL}/getHelpDocument`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

const getFileById = (id: any, fileName:any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url:`${API_URL}/download-contest-event-file/${id}`,
  }).then((response) => {
    if(response.data)
    {       
      console.log(response.data)
      const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
      window.open(url)
    }

  });
}

const downloadFileById = (id: any, fileName:any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url:`${API_URL}/download-contest-event-file/${id}`,
  }).then((response) => {
    if(response.data)
    {       
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download",`${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });
}
const getChecklistAsPdf = (query: string) => {
  console.log(query)
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/pdfreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (query: string) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/excelreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}


export { downloadFileById, getChecklistAsExcel, getChecklistAsPdf, getFileById, getContest_SiteEvents, createContest_SiteEventFile , deleteContest_SiteEvent, getHelpPdf }