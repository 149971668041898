import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import { SiteModel } from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { ContractDescriptionCell } from './ContractDescriptionCell'
import { ContractSignedAmountTryCell } from './ContractSignedAmountTryCell'
import { ContractSignedAmountEurCell } from './ContractSignedAmountEurCell'
import { InvoiceNoCell } from './InvoiceNoCell'
import { DateOfInvoiceCell } from './DateOfInvoiceCell'
import { InterimInvoiceAmountTryCell } from './InterimInvoiceAmountTryCell'
import { CurrencyRateCell } from './CurrencyRateCell'
import { InterimInvoiceAmountEurCell } from './InterimInvoiceAmountEurCell'


const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACT.DESCRIPTION'/>}  className='min-w-200px' />,
    id: 'CONTRACTDESCRIPTION',
    Cell:({...props}) => <ContractDescriptionCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACT.SIGNED.AMOUNT.TRY'/>}  className='min-w-200px' />,
    id: 'CONTRACTSIGNEDAMOUNTTRY',
    Cell:({...props}) => <ContractSignedAmountTryCell site={props.data[props.row.index]} />,
  }, 

  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTRACT.SIGNED.AMOUNT.EUR'/>}  className='min-w-200px' />,
    id: 'CONTRACTSIGNEDAMOUNTEUR',
    Cell:({...props}) => <ContractSignedAmountEurCell site={props.data[props.row.index]} />,
  }, 

  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'INVOICE.NO'/>}  className='min-w-200px' />,
    id: 'INVOICENO',
    Cell:({...props}) => <InvoiceNoCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'DATE.OF.INVOICE'/>}  className='min-w-200px' />,
    id: 'DATEOFINVOICE',
    Cell:({...props}) => <DateOfInvoiceCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id='INTERIM.INVOICE.AMOUNT.TRY' />} className='min-w-200px' />,
    id: 'INTERIMINVOICEAMOUNTTRY',
    Cell: ({ ...props }) => <InterimInvoiceAmountTryCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CURRENCY RATE'/>}  className='min-w-200px' />,
    id: 'CURRENCYRRATE',
    Cell:({...props}) => <CurrencyRateCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'INTERIM.INVOICE.AMOUNT.EUR'/>}  className='min-w-200px' />,
    id: 'INTERIMINVOICEAMOUNTEUR',
    Cell:({...props}) => <InterimInvoiceAmountEurCell site={props.data[props.row.index]} />,
  }
]

export {sitesColumns}
