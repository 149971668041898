/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {OnlineToolsModel} from '../../core/_models'

type Props = {
  onlineTools: OnlineToolsModel
}

const OnlineToolsVersionDateCell: FC<Props> = ({onlineTools}) => {

  function formatDate(date: string) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
  }

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {onlineTools.versionDate ? formatDate(onlineTools.versionDate) : ""}
      </a>
    
    </div>
  </div>     

)}
export {OnlineToolsVersionDateCell}
