import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import { BugReportingModel, BugReportingModelDTO, BugReportingsQueryResponse} from './_models'

const API_URL = "https://shifatr.org/api/bugreporting"

const getBugReportings = (query: string): Promise<BugReportingsQueryResponse> => {
  return axios
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<BugReportingsQueryResponse>) => d.data)
}

const getBugReportingById = (id: ID): Promise<BugReportingModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: Response<BugReportingModel>) => response.data)
}

const createBugReporting = (BugReporting: BugReportingModelDTO): Promise<BugReportingModelDTO | undefined> => {
  return axios
    .post(API_URL, BugReporting)
    .then((response: Response<BugReportingModelDTO>) => response.data)
}

const updateBugReporting = (BugReporting: BugReportingModelDTO): Promise<BugReportingModel | undefined> => {
  return axios
    .put(`${API_URL}/${BugReporting.id}`, BugReporting)
    .then((response: Response<BugReportingModel>) => response.data)
}

const deleteBugReporting = (BugReportingId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${BugReportingId}`).then(() => {})
}

export {getBugReportings, getBugReportingById, deleteBugReporting, createBugReporting, updateBugReporting}
