import { FC, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import { OnlineToolsModel } from "../core/_models";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
import { ParametersListLoading } from "../components/loading/ParametersListLoading";
// import {createUser, updateUser} from '../core/_requests'
import { useQueryResponse } from "../core/QueryResponseProvider";
import { createOnlineToolsFile } from "../core/_requests";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from 'react-toastify';
import { createFileUpload } from "app/modules/file-upload-page/core/_requests";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  onlineTools: OnlineToolsModel;
};

const editParameterSchema = Yup.object().shape({
  // description: Yup.string()
  //   .required(<FormattedMessage id = 'AUTH.VALIDATION.REQUIRED_FIELD'/> as any),

  // versionDate: Yup.string()
  //   .required(<FormattedMessage id = 'AUTH.VALIDATION.REQUIRED_FIELD'/> as any),

    // onlineToolsFile: Yup.string()
    // .required(<FormattedMessage id = 'AUTH.VALIDATION.REQUIRED_FIELD'/> as any),
});

const OnlineToolsEditModalForm: FC<Props> = ({
  onlineTools,
}) => {
  const intl = useIntl()
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [parameterForEdit] = useState<OnlineToolsModel>({
    ...onlineTools
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };


  const formik = useFormik({
    initialValues: parameterForEdit,
    validationSchema: editParameterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
          values.id = uuidv4();
          let formData = new FormData()
          formData.append("id", values.id)
          formData.append("description", values.description ? values.description : "")
          formData.append("versionDate", values.versionDate ? values.versionDate : "")
          formData.append("link", values.link ? values.link : "")
          formData.append("onlineToolsFile", values.onlineToolsFile ? values.onlineToolsFile : [])
          formData.append("onlineToolsFileName", values.onlineToolsFileName ? values.onlineToolsFileName : "")
          //await createFileUpload(formData);
          await createOnlineToolsFile(formData);

      } catch (ex) {
        console.error(ex);
        toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
      } finally {
        setSubmitting(true);
        cancel(true);
        toast.success(intl.formatMessage({ id: 'SUCCESS_TOASTER' }));
      }
    },
  });

  const handleChange = (e: any) => {
    var allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.pdf'];

    var extension = null;
    if (e.target.files) {
      extension = e.target.files[0].name.substring(e.target.files[0].name.indexOf('.'));
    }

    if (!allowedExtensions.includes(extension)) {
      formik.setFieldValue("onlineToolsFile", null)
      formik.setFieldValue("fileName", null)
      e.target.value = null;
      toast.warning(intl.formatMessage({ id: 'FILE_UPLOAD_TYPE' }));
    }
    else {
      formik.setFieldValue("onlineToolsFile", e.target.files[0])
      formik.setFieldValue("onlineToolsFileName", e.target.files[0].name)
    }
  }

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">{<FormattedMessage id ="DESCRIPTION"/>}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'DESCRIPTION' })}
              {...formik.getFieldProps("description")}
              type="text"
              name="description"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.description && formik.errors.description },
                {
                  "is-valid": formik.touched.description && !formik.errors.description,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{""}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">{<FormattedMessage id ="VERSION.DATE"/>}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <style>{`
                	.custom-datepicker {
                  	cursor: pointer;
               		}
                  .react-datepicker-wrapper {
                    display: flex;
                  }
                  .react-datepicker__input-container .react-datepicker__calendar-icon {
                    padding: 14px 0px 0px 10px;
                  }
                  .react-datepicker__view-calendar-icon input {
                    padding: 10px 10px 10px 25px;
                  }
              	`}</style>
            <div className="mb-3 mb-lg-0">
                  <DatePicker
                  showIcon
                    { ...formik.values.versionDate !== '0001-01-01' ? { ...formik.getFieldProps("versionDate")} : null}
                    selected={selectedDate}
                    onChange={(date: any) => {
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      const formattedDate = `${year}-${month}-${day}`;
                      formik.setFieldValue("versionDate", formattedDate);
                      setSelectedDate(date);
                    }}
                    placeholderText={intl.formatMessage({ id: 'SELECT.DATE' })}
                    dateFormat="yyyy-MM-dd"
                    className={clsx("custom-datepicker form-control form-control-solid", {
                      "is-invalid":
                        formik.touched.versionDate && formik.errors.versionDate,
                      "is-valid":
                        formik.touched.versionDate && !formik.errors.versionDate,
                    })}
              autoComplete="off"
              disabled={formik.isSubmitting }
            />
            {formik.touched.versionDate && formik.errors.versionDate && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{""}</span>
                </div>
              </div>
            )}
            </div>
            {/* end::Input */}
          </div>
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">{<FormattedMessage id ="ONLINE.TOOLS.LINK"/>}</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder={intl.formatMessage({ id: 'ONLINE.TOOLS.LINK' })}
              {...formik.getFieldProps("link")}
              type="text"
              name="link"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.link && formik.errors.link },
                {
                  "is-valid": formik.touched.link && !formik.errors.link,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.description && formik.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{""}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='mb-10 col-12'>
              <label className='fw-bold fs-6 mb-2'> {<FormattedMessage id ="ONLINE.TOOLS.FILE"/>}</label>
              <label
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.onlineToolsFile && formik.errors.onlineToolsFile },
                    {
                      'is-valid': formik.touched.onlineToolsFile && !formik.errors.onlineToolsFile,
                    }
                  )}
                    htmlFor="online-tools-file"
                    style={{ cursor: 'pointer' }}
                  >
                    {formik.values.onlineToolsFile ? formik.values.onlineToolsFile.name :<FormattedMessage id='SELECT.FILE' />}
                  </label>

              <input
                id="online-tools-file"
                style={{ display: 'none' }}
                placeholder='Procurement Plan File'
                onChange={handleChange}
                type='file'
                name='onlineToolsFile'
                autoComplete='off'
                disabled={formik.isSubmitting}
              />
              {formik.touched.onlineToolsFile && formik.errors.onlineToolsFile && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{""}</span>
                  </div>
                </div>
              )}
           </div>
          {/* begin::Actions */}
          <div className="text-center pt-15">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              disabled={formik.isSubmitting}
            >
              {<FormattedMessage id ="DISCARD"/>}
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.touched
              }
            >
              <span className="indicator-label">{<FormattedMessage id ="SUBMIT"/>}</span>
              {(formik.isSubmitting) && (
                <span className="indicator-progress">
                  {<FormattedMessage id ="PLEASE.WAIT"/>}{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting) && <ParametersListLoading />}
    </>
  );
};

export { OnlineToolsEditModalForm };
