import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAuth } from '../../../auth/core/AuthHelpers'

import { City, ID, Response } from "../../../../../_metronic/helpers"
import { UploadedFileModel } from '../../../mhc-checklist-management/checklist-list/core/_models'
// import { SiteModel, SiteQueryResponse } from '../../../site-management/sites-list/core/_models'
import { FileModel } from '../../../file-upload-page/core/_models'
import { SiteModel, SiteQueryResponse } from './_models'
import fileDownload from 'js-file-download'

const API_URL = "https://shifatr.org/api/site"
const API_URL_FILE = "https://shifatr.org/api/file"
const API_URL_IMPLEMENTATION = "https://shifatr.org/api/shifaimplementation"

const getSites = async (query: string): Promise<SiteQueryResponse | undefined> => {
  return await axios
    .get(`${API_URL_IMPLEMENTATION}?${query}`)
    .then((response: Response<SiteQueryResponse>) => response.data)
}

const getCities = async (): Promise<Array<City>|undefined> => {
  return  axios
    .get(`https://shifatr.org/api/parameter/cities`)
    .then((response: Response<Array<City>>) => response.data )
}

const getSiteById = (id: ID): Promise<SiteModel | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    //  .then((response: AxiosResponse<Response<SiteModel>>) => response.data)
    .then((response: Response<SiteModel>) => response.data)
}

const createSite = (parameter: SiteModel): Promise<SiteModel | undefined> => {
  return axios
    .post(API_URL, parameter)
    .then((response: AxiosResponse<Response<SiteModel>>) => response.data)
    .then((response: Response<SiteModel>) => response.data)
}

const updateSite = (parameter: SiteModel): Promise<SiteModel | undefined> => {
  return axios
    .put(`${API_URL}/${parameter.id}`, parameter)
  // .then((response: AxiosResponse<Response<SiteModel>>) => response.data)
  // .then((response: Response<SiteModel>) => response.data)
}

const deleteSelectedSite = (StakeholderIds: Array<ID>): Promise<void> => {
  const requests = StakeholderIds.map((id) => axios.delete(`${API_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const getMajorSites = (): Promise<Array<SiteModel>> | any => {
  return axios
    .get(`${API_URL}/majors`)
    .then((response: Response<Array<SiteModel>>) => response.data)
}

const getMinorsNumberOfDoctorsById = (id: ID): Promise<Array<SiteModel>> | any => {
  return axios
    .get(`${API_URL}/minors/${id}`)
    .then((response: Response<Array<SiteModel>>) => response.data)
}

const getFileDocumentByType = (fileName: any, fileType: any, site: SiteModel) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL_FILE}/${fileType.id}/${site.id}`,
  }).then((response) => {
    if (response.data) {
      // const [, fileName] = response.headers['content-disposition'].split('filename=');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });
}

const getFileDocumentById = (fileName: any, id: ID) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL_FILE}/${id}`,
  }).then((response) => {
    if (response.data) {
      // const [, fileName] = response.headers['content-disposition'].split('filename=');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });
}

const getPhotoFiles = (id: ID): Promise<Array<FileModel>> | any => {
  return axios
    .get(`${API_URL_FILE}/getPhoto/${id}`)
    .then((response: Response<Array<FileModel>>) => response.data)
}

const getFileByType = (fileType: any, site: SiteModel): Promise<FileModel> | any => {
  // console.log("🚀 ~ file: _requests.ts:103 ~ getFileByType ~ fileType", fileType);
  return axios
    .get(`${API_URL_FILE}/byType/${fileType.id}/${site.id}`)
    .then((response: Response<FileModel>) => response.data)
}

const getChecklistAsPdf = (siteTypeId: String) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL_IMPLEMENTATION}/pdfreport?siteType=${siteTypeId}`,
  }).then((response) => {
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (siteTypeId: String) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL_IMPLEMENTATION}/excelreport?siteType=${siteTypeId}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}

const getMinorsProjectTypeById = (id: ID): Promise<Array<SiteModel>> | any => {
  return axios
    .get(`${API_URL}/minors/${id}`)
    .then((response: Response<Array<SiteModel>>) => response.data)
}

// const deleteSelectedSiteModels = (parameterIds: Array<ID>): Promise<void> => {
//   const requests = parameterIds.map((id) => axios.delete(`${API_URL}/${id}`))
//   return axios.all(requests).then(() => {})
export { getCities, getSites, getSiteById, getMinorsProjectTypeById, getFileDocumentById, getMinorsNumberOfDoctorsById, createSite, updateSite, deleteSelectedSite, getMajorSites, getFileDocumentByType, getChecklistAsPdf, getChecklistAsExcel, getFileByType, getPhotoFiles }
