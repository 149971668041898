import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import {SiteModel} from '../../../core/_models'
import { SiteProjectInfoCell } from './SiteProjectInfoCell'
import { SiteGeologicalSurveyReportDateCell } from './SiteGeologicalSurveyReportDateCell'
import { SiteDemolitionApprovalDocumentCell } from './SiteDemolitionApprovalDocumentCell'
import { SitePhotosBeforeConstruction } from './SitePhotosBeforeConstruction'
import { FormattedMessage } from 'react-intl'
import { SiteCurrentStatusOfLandCell } from './SiteCurrentStatusOfLandCell'
import { SiteNumberIdentificationCell } from '../../shifa-scope-table/columns/SiteNumberIdentificationCell'
import { BuildingNameCell } from '../../shifa-scope-table/columns/BuildingNameCell'

const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.SITE.NUMBER.IDENTIFICATION'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <SiteNumberIdentificationCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'PROJECT.STATUS.CONTRACTSTATUS'/>}  className='min-w-200px' />,
    id: 'CONTRACTSTATUS',
    Cell:({...props}) => <SiteCurrentStatusOfLandCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'PROJECT.STATUS.CONTRACTORNAME'/>} className='min-w-200px' />,
    id: 'CONTRACTORNAME',
    Cell:({...props}) => <SiteProjectInfoCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'PROJECT.STATUS.WORKSCOMPLETIONPERCENTAGE'/>}  className='min-w-200px' />,
    id: 'WORKSCOMPLETIONPERCENTAGE',
    Cell:({...props}) => <SiteDemolitionApprovalDocumentCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'PROJECT.STATUS.CRITICALPATH'/>}  className='min-w-200px' />,
    id: 'CRITICALPATH',
    Cell:({...props}) => <SitePhotosBeforeConstruction site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'PROJECT.STATUS.NOTES'/>}  className='min-w-200px' />,
    id: 'NOTES',
    Cell:({...props}) => <SiteGeologicalSurveyReportDateCell site={props.data[props.row.index]} />,
  }
  
  
]

export {sitesColumns}
