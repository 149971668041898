import { SiteModel } from '../../../core/_models'
import { FC ,useState} from 'react'
import { useLang } from '_metronic/i18n/Metronici18n'
import { FormattedMessage } from 'react-intl'

type Props = {
    site: SiteModel
}

const SiteContractStatusCell: FC<Props> = ({site}) => {
    const lang = useLang()
    return(

        <div className='d-flex align-items-center'>
            {site.earthquakeAffectedArea == true && <span>{<FormattedMessage id='YES' />}</span>}
            {site.earthquakeAffectedArea == false && <span>{<FormattedMessage id='NO' />}</span>}
        </div>
    )
}
export { SiteContractStatusCell }
