import { FC } from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

type Props = {
  closeFunction: Function
}

const HelpModalHeader: FC<Props> = ({ closeFunction }) => {
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      Help Page
        {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={closeFunction()}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {HelpModalHeader}
