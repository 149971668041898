import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {MHCListHeader} from './components/header/MHCListHeader'
import {KTCard} from '../../../../_metronic/helpers'
import { Horizontal } from './components/horizontal-stepper/Horizontal'
import { PhotoEditModal } from './parameter-edit-modal/PhotoEditModal'

const ChecklistList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <> 
        <KTCard>
        <MHCListHeader/>
           <Horizontal/>
           {itemIdForUpdate !== undefined && <PhotoEditModal/>}
        </KTCard>
 
    </>
  )
}

const MHCListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ChecklistList/>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {MHCListWrapper}
