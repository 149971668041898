import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {Contest_SiteEventListHeader} from './components/header/Contest_SiteEventListHeader'
import {Contest_SiteEventEditModal} from './contest_site-event-modal/Contest_SiteEventEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { Contest_SiteEventTable } from './table/Contest_SiteEventTable'
import { Contest_SiteEventDeleteModal } from '../Contest_SiteEventDeleteModal'

const Contest_SiteEventList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <Contest_SiteEventListHeader />
        <Contest_SiteEventTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <Contest_SiteEventEditModal />}
      {itemIdForDelete !== undefined && <Contest_SiteEventDeleteModal />}
    </>
  )
}

const Contest_SiteEventListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <Contest_SiteEventList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {Contest_SiteEventListWrapper}
