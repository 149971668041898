import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { cipsColumns } from './columns/_columns'
import { ProjectReportsListModel } from '../core/_models'
import { ProjectReportsLoading } from '../components/loading/ProjectReportsLoading'
import { ProjectReportsListPagination } from '../components/pagination/ProjectReportsListPagination'
import { KTCardBody } from '../../../../../_metronic/helpers'
import { FormattedMessage } from 'react-intl'

const ProjectReportsTable = () => {
  const respData = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => respData, [respData])
  const columns = useMemo(() => cipsColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ProjectReportsListModel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-500' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ProjectReportsListModel>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id="NO.RECORD.FOUND" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ProjectReportsListPagination />
      {isLoading && <ProjectReportsLoading />}
    </KTCardBody>
  )
}

export { ProjectReportsTable }
