/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

type Props = {
  submissionDate?: string
}

const SubmissionDateCell: FC<Props> = ({ submissionDate }) => {

  function formatDate(date: string | undefined) {
    if(date && date != "0001-01-01T00:00:00") {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
  
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
  
        return [day, month, year].join('/');
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='text-gray-800 text-hover-primary mb-1'>
        <span>{formatDate(submissionDate)}</span>
      </div>
    </div>
  )
}
export { SubmissionDateCell }
