import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
import { formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
type Props = {
  site: SiteModel
}
const WorksNewEmhcCell: FC<Props> = ({ site }) => {

  return (
    <div className='d-flex align-items-center'>
      {site.worksForNewConstructionEur != null && site.worksForNewConstructionEur != undefined && formatTRY(site.worksForNewConstructionEur.toFixed(2))}
    </div>
  )
}
export { WorksNewEmhcCell }
