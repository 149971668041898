import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { TotalProgressOverviewLine } from '../../../_metronic/partials/widgets/charts/TotalProgressOverviewLine'
import { DashboardInfo } from '../../../_metronic/partials/widgets/feeds/DashboardInfo'
import { DocumentInfoWidget } from '../../../_metronic/partials/widgets/mixed/DocumentInfoWidget'
import { TotalProgressPercentageWidget2 } from '../../../_metronic/partials/widgets/mixed/TotalProgressPercentageWidget2'
import { StatisticsWidgetMHCSite } from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetMHCSite'
import { StatisticsWidgetEMHCSite } from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetEMHCSite'
import { StatisticsWidgetPTRSite } from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetPTRSite'
import { StatisticsWidgetHospitalSite } from '../../../_metronic/partials/widgets/statistics/StatisticsWidgetHospitalSite'
import { TenderListWidget } from '../../../_metronic/partials/widgets/tables/TenderListWidget'
import { FileControlModel } from '../../modules/file-control-management/file-control-list/core/_models'
import { ProcurementModel } from '../../modules/procurement-management/core/_models'
import { SvgMap } from '../../modules/widgets/components/SvgMap'
import { MixedWidget18 } from '../../../_metronic/partials/widgets/mixed/MixedWidget18'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../_metronic/helpers/crud-helper/consts'
import { getAllCities, getDashboardKPISitesInfo, getSitesByCityId2 } from '../../modules/site-management/sites-list/core/_requests'
import { isNotEmpty } from '../../../_metronic/helpers'
import { AllCitiesResponse, DashboardKPISiteInfo, SiteResponse } from '../../modules/site-management/sites-list/core/_models'
import { ParameterModel } from 'app/modules/parameter-management/parameters-list/core/_models'
import { useThemeMode } from '_metronic/partials'
import { getProcurementsForDashboard } from 'app/modules/procurement-management/procurements-list/core/_requests'
import { getMinorsParameterTypeById } from 'app/modules/parameter-management/parameters-list/core/_requests'
import ReactApexChart from 'react-apexcharts'
import { ThemeModeComponent } from '_metronic/assets/ts/layout/ThemeMode'

// interface selectedLang {
//   selectedLang: string;
// }




type Props = {
  mhcCount: number,
  emhcCount: number,
  ptrCount: number,
  rehabCount: number,
  // procurementList: Array<ProcurementModel>,
  fileControls: Array<FileControlModel>,
  sitesInfoList: SiteResponse,
  kpiSiteInfo: DashboardKPISiteInfo,
  procurementStatusList: Array<ParameterModel>
  cities: Array<AllCitiesResponse>
}

const DashboardPage: React.FC<Props> = ({
  kpiSiteInfo,
  sitesInfoList,
  // procurementList,
  procurementStatusList,
  cities
}) => {
  const intl = useIntl()
  const [cityId, setCityId] = useState<string>("");
  const textColor = ThemeModeComponent.getMode() === "dark" ? "white" : "black";

  type StateSeriesModel = {
    name: string;
    data: number[];
  }

  const [stateSeries, setStateSeries] = useState<StateSeriesModel[]>([]);
  

  const enabledQuery = cityId !== "0" && isNotEmpty(cityId);
  const siteStatusList: Array<string> = [];
  const emhcSitesList: Array<number> = [];
  const mhcSitesList: Array<number> = [];
  const ptrSitesList: Array<number> = [];
  const hospitalSitesList: Array<number> = [];
  let lang = localStorage.getItem('i18nConfig')
  let langString: string = lang ? JSON.parse(lang).selectedLang : null

  const {
    isFetching,
    data: procurementTypes,
    error,
  } = useQuery(
    `${QUERIES.PROCUREMENT_LIST}-all-procurements`,
    () => {
      return getMinorsParameterTypeById("8e131c0a-eed0-49cc-8226-0bba63095714")
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const {
    data: siteStatusWithCity,
  } = useQuery(
    `${QUERIES.SITE_BY_CITY}-${cityId}`,
    () => {
      return getSitesByCityId2(cityId)
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0, enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )


  const {
    isFetching: lodingKPISiteInfo,
    data: kpiSiteInfo2,
  } = useQuery(
    `${QUERIES.KPI_SITE_INFO}-${cityId}`,
    () => {
      return getDashboardKPISitesInfo(cityId)
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0, enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  if (siteStatusWithCity !== undefined) {
    if (kpiSiteInfo2) {
      kpiSiteInfo = kpiSiteInfo2;
    }

    if (langString == "en") {
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? emhcSitesList.push(siteStatusWithCity.unassignedEmhc) : emhcSitesList.push(0);
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? mhcSitesList.push(siteStatusWithCity.unassignedMhc) : mhcSitesList.push(0);
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? ptrSitesList.push(siteStatusWithCity.unassignedPtr) : ptrSitesList.push(0);
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? hospitalSitesList.push(siteStatusWithCity.unassignedHospital) : hospitalSitesList.push(0);
      siteStatusList.push("Design")
      siteStatusWithCity?.allSites.forEach(element => {
        if (element.procurementStatusEnglishName != "Cancellation Notice") {
          siteStatusList.push(element.procurementStatusEnglishName)
          emhcSitesList.push(element.emhcCount)
          mhcSitesList.push(element.mhcCount)
          ptrSitesList.push(element.ptrCount)
          hospitalSitesList.push(element.hospitalCount)
        }
      });

    } else {
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? emhcSitesList.push(siteStatusWithCity.unassignedEmhc) : emhcSitesList.push(0);
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? mhcSitesList.push(siteStatusWithCity.unassignedMhc) : mhcSitesList.push(0);
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? ptrSitesList.push(siteStatusWithCity.unassignedPtr) : ptrSitesList.push(0);
      (siteStatusWithCity != null && siteStatusWithCity != undefined) ? hospitalSitesList.push(siteStatusWithCity.unassignedHospital) : hospitalSitesList.push(0);
      siteStatusList.push("Tasarım Aşamasında")
      siteStatusWithCity?.allSites.forEach(element => {
        if (element.procurementStatusTurkishName != "İhale İptal Edilmiş") {
          siteStatusList.push(element.procurementStatusTurkishName)
          emhcSitesList.push(element.emhcCount)
          mhcSitesList.push(element.mhcCount)
          ptrSitesList.push(element.ptrCount)
          hospitalSitesList.push(element.hospitalCount)
        }
      });

    }
  } else {
    if (langString == "en") {
      (sitesInfoList != null && sitesInfoList != undefined) ? emhcSitesList.push(sitesInfoList.unassignedEmhc) : emhcSitesList.push(0);
      (sitesInfoList != null && sitesInfoList != undefined) ? mhcSitesList.push(sitesInfoList.unassignedMhc) : mhcSitesList.push(0);
      (sitesInfoList != null && sitesInfoList != undefined) ? ptrSitesList.push(sitesInfoList.unassignedPtr) : ptrSitesList.push(0);
      (sitesInfoList != null && sitesInfoList != undefined) ? hospitalSitesList.push(sitesInfoList.unassignedHospital) : hospitalSitesList.push(0);
      siteStatusList.push("Design")
      sitesInfoList?.allSites.forEach(element => {
        if (element.procurementStatusEnglishName != "Cancellation Notice") {
          siteStatusList.push(element.procurementStatusEnglishName)
          emhcSitesList.push(element.emhcCount)
          mhcSitesList.push(element.mhcCount)
          ptrSitesList.push(element.ptrCount)
          hospitalSitesList.push(element.hospitalCount)
        }
      });

    } else {
      (sitesInfoList != null && sitesInfoList != undefined) ? emhcSitesList.push(sitesInfoList.unassignedEmhc) : emhcSitesList.push(0);
      (sitesInfoList != null && sitesInfoList != undefined) ? mhcSitesList.push(sitesInfoList.unassignedMhc) : mhcSitesList.push(0);
      (sitesInfoList != null && sitesInfoList != undefined) ? ptrSitesList.push(sitesInfoList.unassignedPtr) : ptrSitesList.push(0);
      (sitesInfoList != null && sitesInfoList != undefined) ? hospitalSitesList.push(sitesInfoList.unassignedHospital) : hospitalSitesList.push(0);
      siteStatusList.push("Tasarım Aşamasında")
      sitesInfoList?.allSites.forEach(element => {
        if (element.procurementStatusTurkishName != "İhale İptal Edilmiş") {
          siteStatusList.push(element.procurementStatusTurkishName)
          emhcSitesList.push(element.emhcCount)
          mhcSitesList.push(element.mhcCount)
          ptrSitesList.push(element.ptrCount)
          hospitalSitesList.push(element.hospitalCount)
        }
      });
    }
  }



  
  useEffect(() => {
    var stateSeriesList: StateSeriesModel[] = [];
    
    let dataDesignPhase=[]
    dataDesignPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcOngoingCount : 0)
    dataDesignPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcOngoingCount : 0)
    dataDesignPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrOngoingCount : 0)
    dataDesignPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalOngoingCount : 0)

    let dataTendering=[]
    dataTendering.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcTenderCount : 0)
    dataTendering.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcTenderCount : 0)
    dataTendering.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrTenderCount : 0)
    dataTendering.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalTenderCount : 0)

    let dataConstructionPhase=[]
    dataConstructionPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcUnassignedCount : 0)
    dataConstructionPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcUnassignedCount : 0)
    dataConstructionPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrUnassignedCount : 0)
    dataConstructionPhase.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalUnassignedCount : 0)

    let dataProvisional=[]
    dataProvisional.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcProvisionalCount : 0)
    dataProvisional.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcProvisionalCount : 0)
    dataProvisional.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrProvisionalCount : 0)
    dataProvisional.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalProvisionalCount : 0)
    
    let dataCompleted=[]
    dataCompleted.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcCompletedCount : 0)
    dataCompleted.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcCompletedCount : 0)
    dataCompleted.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrCompletedCount : 0)
    dataCompleted.push(kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalCompletedCount : 0)

    var stateSeriesDESIGNPHASE: any = { name: intl.formatMessage({ id: 'DASHBOARD.DESIGN.PHASE' }).replace(':',''), data: dataConstructionPhase };
    var stateSeriesTENDERING: any = { name: intl.formatMessage({ id: 'DASHBOARD.TENDERING' }).replace(':',''), data: dataTendering};
    var stateSeriesCONSTRUCTIONPHASE: any = { name: intl.formatMessage({ id: 'DASHBOARD.CONSTRUCTION.PHASE' }).replace(':',''), data: dataDesignPhase };
    var stateSeriesPROVISIONAL: any = { name: intl.formatMessage({ id: 'DASHBOARD.PROVISIONAL' }).replace(':',''), data: dataProvisional };      
    var stateSeriesEMHCCOMPLETED: any = { name: intl.formatMessage({ id: 'DASHBOARD.EMHC.COMPLETED' }).replace(':',''), data: dataCompleted };

    stateSeriesList.push(stateSeriesDESIGNPHASE)
    stateSeriesList.push(stateSeriesTENDERING)
    stateSeriesList.push(stateSeriesCONSTRUCTIONPHASE)
    stateSeriesList.push(stateSeriesPROVISIONAL)
    stateSeriesList.push(stateSeriesEMHCCOMPLETED)
    setStateSeries(stateSeriesList)
}, [cityId,kpiSiteInfo]);

  var state = {
          
    series: stateSeries,

    options: {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          maxItems: 500,
          hideOverflowingLabels: true,
          total: {
            show:true,
            showAlways:true,
            fontWeight: 900,
            fontSize: '13px',
            color: "black",
          }
        }
      },
    },
    xaxis: {
      categories: [intl.formatMessage({ id: 'DASHBOARD.MHC' }), intl.formatMessage({ id: 'DASHBOARD.EMHC' }), 
      intl.formatMessage({ id: 'DASHBOARD.PTR' }), intl.formatMessage({ id: 'DASHBOARD.HOSPITAL' })
      ],
      labels: {
        show: true,
        style: {
          colors: ThemeModeComponent.getMode() == "dark" ? "white" : "black" 
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: ThemeModeComponent.getMode() == "dark" ? "white" : "black" 
        }
      }
    },
  },
  };


  return (
    <>
      <PageTitle breadcrumbs={[]} description=''>
        Dashboard
      </PageTitle>

      <div>
        <SvgMap setCityId={setCityId} cities={cities} />
        {/* <div className="col-lg-12 col-md-9 col-sm-12">
          <select className="form-control select2" value={cityId} id="kt_select2_1" onChange={(selectedCity) => {
            setCityId(selectedCity.target.value)
          }}>
            <option value="0">Türkiye</option>
            {
              cities.map((City: AllCitiesResponse) => (
                <option value={City.id}>
                  {City.name}
                </option>
              ))
            }
          </select>
        </div> */}
      </div>
      <div className="separator my-10"></div>
      <div className='row'>
        <div className='col-12'>
          <MixedWidget18
            theme={useThemeMode()}
            title={intl.formatMessage({ id: 'MHC.CHART' })}
            chartColor='primary'
            chartHeight='150px'
            cityId={cityId}
            siteCounts={mhcSitesList}
            siteStatus={siteStatusList}
          />
        </div>
        <div className='col-12'>
          <MixedWidget18
            theme={useThemeMode()}
            title={intl.formatMessage({ id: 'EMHC.CHART' })}
            chartColor='primary'
            chartHeight='150px'
            cityId={cityId}
            siteCounts={emhcSitesList}
            siteStatus={siteStatusList}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <MixedWidget18
            theme={useThemeMode()}
            title={intl.formatMessage({ id: 'HOSPITAL.CHART' })}
            chartColor='primary'
            chartHeight='150px'
            cityId={cityId}
            siteCounts={hospitalSitesList}
            siteStatus={siteStatusList}
          />
        </div>
        <div className='col-12'>
          <MixedWidget18
            theme={useThemeMode()}
            title={intl.formatMessage({ id: 'PTR.CHART' })}
            chartColor='primary'
            chartHeight='150px'
            cityId={cityId}
            siteCounts={ptrSitesList}
            siteStatus={siteStatusList}
          />
        </div>
      </div>
      {/* <div className='row g-5 g-xl-8 pt-8 '>
        <DashboardInfo className='mb-5 mb-xl-8' />
      </div> */}
      <div className='row g-5 g-xl-8 pt-8 '>
        <div className='col-xl-3'>
          <StatisticsWidgetMHCSite
            className='card-xl-stretch mb-xl-8'
            svgIcon=''
            color='danger'
            iconColor='white'
            title={intl.formatMessage({ id: 'DASHBOARD.MHC' })}
            titleColor='white'
            descriptionColor='white'
            ongoing={kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcOngoingCount : 0}
            tendering={kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcTenderCount : 0}
            planned={kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcUnassignedCount : 0}
            completed={kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcCompletedCount : 0}
            provisionalAcceptence={kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcProvisionalCount : 0}
            total={kpiSiteInfo !== undefined ? kpiSiteInfo.totalMhcCount : 0}
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidgetEMHCSite
            className='card-xl-stretch mb-xl-8'
            svgIcon=''
            color='primary'
            iconColor='white'
            title={intl.formatMessage({ id: 'DASHBOARD.EMHC' })}
            titleColor='white'
            descriptionColor='white'
            ongoing={kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcOngoingCount : 0}
            tendering={kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcTenderCount : 0}
            planned={kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcUnassignedCount : 0}
            completed={kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcCompletedCount : 0}
            total={kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcCount : 0}
            provisionalAcceptence={kpiSiteInfo !== undefined ? kpiSiteInfo.totalEmhcProvisionalCount : 0}
          />
        </div>

        <div className='col-xl-3 '>
          <StatisticsWidgetPTRSite
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon=''
            color='success'
            iconColor='white'
            titleColor='white'
            descriptionColor='white'
            title={intl.formatMessage({ id: 'DASHBOARD.PTR' })}
            total={kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrCount : 0}
            ongoing={kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrOngoingCount : 0}
            planned={kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrUnassignedCount : 0}
            completed={kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrCompletedCount : 0}
            tendering={kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrTenderCount : 0}
            provisionalAcceptence={kpiSiteInfo !== undefined ? kpiSiteInfo.totalPtrProvisionalCount : 0}
          />
        </div>
        <div className='col-xl-3 '>
          <StatisticsWidgetHospitalSite
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon=''
            color='info'
            iconColor='white'
            titleColor='white'
            descriptionColor='white'
            title={intl.formatMessage({ id: 'DASHBOARD.HOSPITAL' })}
            total={kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalCount : 0}
            ongoing={kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalOngoingCount : 0}
            planned={kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalUnassignedCount : 0}
            completed={kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalCompletedCount : 0}
            tendering={kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalTenderCount : 0}
            provisionalAcceptence={kpiSiteInfo !== undefined ? kpiSiteInfo.totalHospitalProvisionalCount : 0}
          />
        </div>
      </div>

      <div>
        <style>
          {`
            .apexcharts-legend-text {
              color: ${textColor} !important;
            }
          `}
        </style>
        {(
          <div style={{ margin: "15px", marginBottom: "5%" }}>
          <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
        </div>
        )}
      </div>
      

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-4'>

          <TotalProgressOverviewLine className={'card-xl-stretch mb-xl-8'} />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          {/* <TotalProgressPercentageWidget className='card-xl-stretch' chartColor='primary' chartHeight='225px' /> */}
          <TotalProgressPercentageWidget2
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='225px'
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-xl-4'>
          <DocumentInfoWidget
            className='card-xl-stretch mb-5 mb-xl-8'
            fileControls={fileControls}
          />
        </div> */}
        {/* end::Col */}
      </div>

      <div className='g-5 gx-xxl-8  pt-8'>
      <div className='card pt-8' style={{paddingLeft: "10px"}}>
        </div>
        <TenderListWidget className='' procurementTypes={procurementTypes}
          procurementStatusList={procurementStatusList} />
      </div>

      {/* <div className='col-xl-4'>
          <ListsWidget5 className='card-xl-stretch mb-xl-8' />
        </div> */}
    </>
  )
}
export { DashboardPage }

