import { useMemo } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../../core/QueryResponseProvider'
import { sitesColumns } from './columns/_columns'
import { SiteModel } from '../../core/_models'
import { ChecklistListLoading } from '../../components/loading/ChecklistListLoading'
import { ChecklistListPagination } from '../../components/pagination/ChecklistListPagination'
import { KTCardBody, } from '../../../../../../_metronic/helpers'

const ProjectStatusTable = () => {
  const mhcSites = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => mhcSites,[mhcSites])
  // const data1 = sites;
  // const data = data1.filter(x => x.siteTypeName === "New Construction")
  const columns = useMemo(() => sitesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-1'>
      <div className='table-responsive '>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<SiteModel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-500' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SiteModel>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Kayıt bulunanmamıştır.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ChecklistListPagination />
      {isLoading && <ChecklistListLoading />}
    </KTCardBody>
  )
}

export { ProjectStatusTable }
