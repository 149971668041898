import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'

import { SiteLotInfoCell } from './SiteInfoCell'
import { SiteProjectTypeInfoCell } from './SiteProjectTypeInfoCell'
import { SiteIndicativeConstructionAreaCell } from './SiteIndicativeConstructionAreaCell'
import { SiteNotesCell } from './SiteNotesCell'
import { SiteModel } from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { SiteContractStatusCell } from './SiteContractStatusCell'
import { FacilityAllowanceCell } from './FacilityAllowanceCell'
import { ProjectType } from './ProjectType'
import { NumberOfDoctors } from './NumberOfDoctors'
import { ConstructionArea } from './ConstructionArea'
import { NumberOfPtrUnits } from './NumberOfPtrUnits'
import { NumberOfFloors } from './NumberOfFloors'
import { PhaseCell } from './PhaseCell'
import { SiteNumberIdentificationCell } from './SiteNumberIdentificationCell'
import { BuildingNameCell } from './BuildingNameCell'


const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.PHASE'/>}  className='min-w-200px' />,
    id: 'PHASE',
    Cell:({...props}) => <PhaseCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.SITE.NUMBER.IDENTIFICATION'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <SiteNumberIdentificationCell site={props.data[props.row.index]} />,
  }, 

  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.PROVINCE'/>}  className='min-w-200px' />,
    id: 'PROVINCE',
    Cell:({...props}) => <FacilityAllowanceCell site={props.data[props.row.index]} />,
  }, 

  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.DISTRICT'/>}  className='min-w-200px' />,
    id: 'DISTRICT',
    Cell:({...props}) => <SiteLotInfoCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.NEIGHBORHOOD'/>}  className='min-w-200px' />,
    id: 'NEIGHBORHOOD',
    Cell:({...props}) => <SiteProjectTypeInfoCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id='SHIFA.SCOPE.EARTHQUAKE.AFFECTED.AREA' />} className='min-w-200px' />,
    id: 'EARTHQUAKEAFFECTEDAREA',
    Cell: ({ ...props }) => <SiteContractStatusCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.WORKS.TYPE'/>}  className='min-w-200px' />,
    id: 'WORKSTYPE',
    Cell:({...props}) => <SiteIndicativeConstructionAreaCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.PROJECT.TYPE'/>} className='min-w-200px' />,
    id: 'PROJECTTYPE',
    Cell:({...props}) => <ProjectType site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.NUMBEROFDOCTORS'/>} className='min-w-200px' />,
    id: 'NUMBEROFDOCTORS',
    Cell:({...props}) => <NumberOfDoctors site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.CONSTRUCTIONAREA'/>} className='min-w-200px' />,
    id: 'CONSTRUCTIONAREA',
    Cell:({...props}) => <ConstructionArea site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.NUMBEROFPTRUNITS'/>} className='min-w-200px' />,
    id: 'NUMBEROFPTRUNITS',
    Cell:({...props}) => <NumberOfPtrUnits site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.NUMBEROFFLOORS'/>} className='min-w-200px' />,
    id: 'NUMBEROFFLOORS',
    Cell:({...props}) => <NumberOfFloors site={props.data[props.row.index]} />,
  },
  
  
]

export {sitesColumns}
