import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {MHCListWrapper} from './checklist-list/MHCList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title:<FormattedMessage id ="CHECKLIST.SHIFA.IMPLEMENTATION"/>,
    path: 'shifa-implementation',
    isSeparator: false,
    isActive: false,
  },
  {
    title: <FormattedMessage id ="CHECKLIST.SHIFA.IMPLEMENTATION"/>,
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ShifaImplementationPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet/>}>
        <Route
          path='shifaimplementation'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'CHECKLIST.SHIFA.IMPLEMENTATION' })}</PageTitle>
              <MHCListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='shifaimplementation'/>} />
    </Routes>
  )
}

export default ShifaImplementationPage
