import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { CityModel, SiteModel } from '../../procurement-management/core/_models'
import { FileType, FileTypeQueryResponse, FileModel } from './_models'
import { useState } from 'react'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `https://shifatr.org/api/user`
const GET_USERS_URL = `https://shifatr.org/api/users/query`
const GET_FILETYPES_URL = "https://shifatr.org/api/file"
const CHUNK_SIZE = 5 * 1024 * 1024;



const getFileTypes = (query: string): Promise<FileTypeQueryResponse> => {
  var x = axios
    .get(`${GET_FILETYPES_URL}?${query}`)
    .then((response: AxiosResponse<FileTypeQueryResponse>) => response.data)

  return x;
}

const getLots = (query: string): Promise<Array<string>> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((response: AxiosResponse<Array<string>>) => response.data)
}

const getSites = (query: string): Promise<Array<SiteModel>> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((response: AxiosResponse<Array<SiteModel>>) => response.data)
}

const createFileUpload = (file: FormData): Promise<Response<ID> | undefined> => {
  return axios
    .post(GET_FILETYPES_URL, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    .then((response: Response<ID>) => response)
}

const createChunkFileUpload = async (file: any) => {
      return axios.post(`${GET_FILETYPES_URL}/chunkUpload`, file)
        .then((response) => response)
  };



const getCities = (): Promise<Array<CityModel>> => {
  return axios
    .get(`${GET_USERS_URL}`)
    .then((response: AxiosResponse<Array<CityModel>>) => response.data)
}

const downloadFileById = (id: any, fileName: any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${GET_FILETYPES_URL}/${id}`,
  }).then((response) => {
    if (response.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });
}
const deleteFileById = (fileId: ID): Promise<void> => {
  return axios.delete(`${GET_FILETYPES_URL}/file-control/${fileId}`).then(() => { })
}





export { createFileUpload, getFileTypes, getLots, getCities, getSites, downloadFileById, deleteFileById, createChunkFileUpload as createChunkFile }
