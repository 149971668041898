import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {OnlineToolsListHeader} from './components/header/OnlineToolsListHeader'
import {OnlineToolsEditModal} from './online-tools-modal/OnlineToolsEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { OnlineToolsTable } from './table/OnlineToolsTable'
import { OnlineToolsDeleteModal } from '../OnlineToolsDeleteModal'

const OnlineToolsList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <OnlineToolsListHeader />
        <OnlineToolsTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <OnlineToolsEditModal />}
      {itemIdForDelete !== undefined && <OnlineToolsDeleteModal />}
    </>
  )
}

const OnlineToolsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OnlineToolsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OnlineToolsListWrapper}
