import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {CiapListWrapper} from './checklist-list/CiapList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title:<FormattedMessage id ="COSTS.INCURRED.AND.AMOUNTS.PAID"/>,
    path: 'ciap-table',
    isSeparator: false,
    isActive: false,
  },
  {
    title: <FormattedMessage id ="COSTS.INCURRED.AND.AMOUNTS.PAID"/>,
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CiapPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet/>}>
        <Route
          path='ciaptable'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'COSTS.INCURRED.AND.AMOUNTS.PAID' })}</PageTitle>
              <CiapListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='ciaptable'/>} />
    </Routes>
  )
}

export default CiapPage
