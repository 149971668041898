import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {EstimatedCostListHeader} from './components/header/EstimatedCostListHeader'
import {EstimatedCostEditModal} from './log-frame-modal/EstimatedCostEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { EstimatedCostTable } from './table/EstimatedCostTable'

const EstimatedCostList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <EstimatedCostListHeader />
        <EstimatedCostTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <EstimatedCostEditModal />}
      
    </>
  )
}

const EstimatedCostListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EstimatedCostList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EstimatedCostListWrapper}
