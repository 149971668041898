/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG, QUERIES, ROLES, getUserRole } from '../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'

type Props = {
  id: ID,
  site: string,
  estimatedCostTL: number,
  estimatedCostEUR: number,
}

  //Procurement Plan List

const EstimatedCostActionsCell: FC<Props> = ({ id, site, estimatedCostTL, estimatedCostEUR  }) => {
  const { setItemIdForUpdate } = useListView()
  const role = getUserRole();

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
 
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      {(role == ROLES.Superadmin.name ) &&
      <button type='button' className='btn btn-primary' onClick={openEditModal}>
        {/* <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' /> */}
         {(estimatedCostTL!=undefined || estimatedCostEUR!=undefined)? <FormattedMessage id ="ESTIMATED.COST.UPDATE"/>: <FormattedMessage id ="ESTIMATED.COST.ADD"/>  }
      </button>
      }
{/* 
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {<FormattedMessage id="ACTIONS" />}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          {<FormattedMessage id="VIEW" />}
        </div>
      </div> */}

      
    </>
  )
}

export { EstimatedCostActionsCell }
