import { FC ,useState} from 'react'
import {SiteModel} from '../../../core/_models'
type Props = {
    site: SiteModel
}
const DateOfPaymentOrder: FC<Props> = ({site}) => {


return(
  <div className='d-flex align-items-center'>
       <div className='flex-grow-1'>
        {site.dateOfPaymentOrder}
    </div>
</div>
)
}
export {DateOfPaymentOrder }
