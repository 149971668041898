import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PrintedMaterialsListWrapper} from './printed-materials/PrintedMaterialsList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="PRINTED.MATERIAL"/>,
    path: 'printed-material/pm',
    isSeparator: true,
    isActive: false,
  },
]

const PrintedMaterialsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='pm'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'PRINTED.MATERIAL.LIST' })}</PageTitle>
              <PrintedMaterialsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/printed-material/pm' />} />
    </Routes>
  )
}

export default PrintedMaterialsPage
