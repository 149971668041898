import {Column} from 'react-table'
import {FileNameCell} from './FileNameCell'
import {FileActionsCell} from './FileActionsCell'
import {FileCustomHeader} from './FileCustomHeader'
import { GroupCell } from './GroupCell'
import {FileInfoModel} from '../../../core/_models'
import { FormattedMessage } from 'react-intl'

const usersColumns: ReadonlyArray<Column<FileInfoModel>> = [
 {
  Header: (props) => <FileCustomHeader tableProps={props} title= {<FormattedMessage id = 'NAME'/>} className='min-w-200px' />,
  id: 'contractInfo',
  Cell:({...props}) => <FileNameCell fileName={props.data[props.row.index].fileName} />,

},
{
  Header: (props) => <FileCustomHeader tableProps={props} title={<FormattedMessage id = 'GROUP'/>} className='min-w-200px' />,
  id: 'lotDetail',
  Cell:({...props}) => <GroupCell group={props.data[props.row.index].group} />,

},
{
  Header: (props) => (
    <FileCustomHeader tableProps={props}  className='text-end min-w-100px' />
  ),
  id: 'actions',
  Cell: ({...props}) => <FileActionsCell file={props.data[props.row.index]} />,
},
]
export {usersColumns}
