import { Dispatch, SetStateAction } from 'react';
import { ID, Response } from '../../../../../_metronic/helpers';

export type CommunicationStrategyModel = {
  id?: ID
  description?: string | any,
  versionDate?: boolean | any,
  link?: string | any,
  communicationStrategyFile?: Blob | any,
  communicationStrategyFileName?: string | any,
  filePath?: string | any,
  fileType?: string | any,
  createdDate?: string | any,
  createdBy?: string | any,
}
export type CommunicationStrategyQueryResponse = Response<Array<CommunicationStrategyModel>>


export type CommunicationStrategyModelFileModel = {
  CommunicationStrategyModelFile?: Blob | any,
  CommunicationStrategyModelFileName?: string | any,
  filePath?: string | any,
  fileType?: string | any,
}


export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDelete?: ID
  setItemIdForDelete: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  helpPageOn: boolean
  setHelpPageOn: Dispatch<SetStateAction<boolean>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDelete: () => { },
  isAllSelected: false,
  disabled: false,
  setHelpPageOn: () => {},
  helpPageOn: false,
}