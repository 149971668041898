import axios, { AxiosResponse } from 'axios'
import { FileControlQueryResponse, FileControlModel, FileHistoryQueryResponse, FileTypeQueryResponse, FileType } from './_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import fileDownload from 'js-file-download'

const API_URL = "https://shifatr.org/api/file/file-control"
const API_URL_DOWNLOAD = "https://shifatr.org/api/file/"
const API_URL_PROCUREMENT_FILE = "https://shifatr.org/api/file/procurement"


const getFilesForControl = async (query: string): Promise<FileControlQueryResponse> => {

  return await axios
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<FileControlQueryResponse>) => d.data)
}

const getFileTypes = (query: string): Promise<Array<FileType>> => {
  return axios
     .get(`${API_URL}/fileTypes?${query}`)
     .then((response: AxiosResponse<Array<FileType>>) => response.data)
   }

const getFileStatusHistory = async (fileId: ID): Promise<FileHistoryQueryResponse> => {

  return await axios
    .get(`${API_URL}/filehistory/${fileId}`)
}


const updateFileControlStatus = (fileControl: FileControlModel): Promise<FileControlModel | undefined> => {

  return axios
    .post(`${API_URL}`, fileControl)
}

const deleteFileControl = (fileId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${fileId}`).then(() => { })
}

const getChecklistAsPdf = (query: string) => {
  console.log(query)
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/pdfreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (query: string) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/excelreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}

const getFileById = (id: any, fileName:any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url:`${API_URL_PROCUREMENT_FILE}/download-procurement-file/${id}`,
  }).then((response) => {
    if(response.data)
    {       
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download",`${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });
}

const getFileByProcurementId = (id: any, fileName:any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url:`${API_URL_PROCUREMENT_FILE}/download-procurement-file/${id}`,
  }).then((response) => {
    if(response.data)
    {       
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download",`${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });
}

const deleteProcurementFileById = (fileId: ID): Promise<void> => {
  return axios.delete(`${API_URL_PROCUREMENT_FILE}/${fileId}`).then(() => { })
}

export { deleteProcurementFileById, getFileStatusHistory, getFileByProcurementId, getFilesForControl, getFileTypes, updateFileControlStatus, deleteFileControl, getChecklistAsPdf, getChecklistAsExcel,getFileById }
