/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Contest_SiteEventModel} from '../../core/_models'

type Props = {
  contest_SiteEvent: Contest_SiteEventModel
}

const Contest_SiteEventLinkCell: FC<Props> = ({contest_SiteEvent}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {contest_SiteEvent.link ? contest_SiteEvent.link : ""}
      </a>
    
    </div>
  </div>     

)}
export {Contest_SiteEventLinkCell}
