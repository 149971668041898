import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Audio_VisualToolsListWrapper} from './audio_visual-tools/Audio_VisualToolsList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="AUDIO_VISUAL.TOOLS"/>,
    path: 'audiovisual-tool',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Audio_VisualToolsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='avt'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'AUDIO_VISUAL.TOOLS.LIST' })}</PageTitle>
              <Audio_VisualToolsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/audiovisual-tool/avt' />} />
    </Routes>
  )
}

export default Audio_VisualToolsPage
