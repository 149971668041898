import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RiskMatrixListHeader} from './components/header/RiskMatrixListHeader'
import {RiskMatrixEditModal} from './risk-matrix-modal/RiskMatrixEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { RiskMatrixTable } from './table/RiskMatrixTable'
import { RiskMatrixDeleteModal } from '../RiskMatrixDeleteModal'

const RiskMatrixList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <RiskMatrixListHeader />
        <RiskMatrixTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <RiskMatrixEditModal />}
      {itemIdForDelete !== undefined && <RiskMatrixDeleteModal />}
    </>
  )
}

const RiskMatrixListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <RiskMatrixList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {RiskMatrixListWrapper}
