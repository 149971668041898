/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({ id: 'NAV.HOME' })}
        fontIcon='bi-house fs-2'
        bsTitle={intl.formatMessage({id: 'NAV.DASHBOARD'})}
        className='py-2'
      />
      <AsideMenuItem
        to='/bug-reporting'
        title={intl.formatMessage({ id: 'BUGREPORTING' })}
        fontIcon='bi-bug fs-2'
        bsTitle={intl.formatMessage({id: 'BUGREPORTING'})}
        className='py-2'
      />
    </>
  )
}
