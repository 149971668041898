/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { BugReportingListModel, BugReportingModel } from '../../core/_models'

type Props = {
  title: string
}

const BugReportingTitleCell: FC<Props> = ({ title }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{title}</span>
      </div>
    </div>
  )
}
export { BugReportingTitleCell }
