/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { FormattedMessage } from 'react-intl'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const TotalProgressPercentageWidget2: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  return (
<div></div>
    // <div className={`card ${className}`}>
    //   {/* begin::Beader */}
    //   <div className='card-header border-0 py-5'>
    //     <h3 className='card-title align-items-start flex-column'>
    //       <span className='card-label fw-bold fs-3 mb-1'>{<FormattedMessage id = 'TOT.PROGRESS.PERC'/>}</span>
    //     </h3>
    //   </div>
    //   {/* end::Header */}

    //   {/* begin::Body */}
    //   <div className='card-body d-flex flex-column'>
    //     <div className=' pt-5 bg-body flex-grow-1'>
    //       {/* begin::Row */}
    //       <div className='row g-0'>
    //         {/* begin::Col */}
    //         <div style={{marginLeft:"25px"}} className='col mr-8'>
    //           {/* begin::Label */}
    //           <div style={{marginRight:"15px"}} className=' text-muted fw-semibold'>{<FormattedMessage id = 'TOT.PROGRESS.PAYMENT'/>}</div>
    //           {/* end::Label */}

    //           {/* begin::Stat */}
    //           <div className='d-flex align-items-center'>
    //             <div className='fs-4 fw-bold'>€865.613</div>
    //           </div>
    //           {/* end::Stat */}
    //         </div>
    //         {/* end::Col */}

    //         {/* begin::Col */}
    //         <div className='col'>
    //           {/* begin::Label */}
    //           <div className=' text-muted fw-semibold'>{<FormattedMessage id = 'PAYMENT.PROGRESS'/>}</div>
    //           {/* end::Label */}

    //           {/* begin::Stat */}
    //           <div className='fs-4 fw-bold'>€318.100</div>
    //           {/* end::Stat */}
    //         </div>
    //         {/* end::Col */}
    //       </div>
    //       {/* end::Row */}

    //       {/* begin::Row */}

    //       {/* end::Row */}
    //     </div>
    //     <div className='flex-grow-1'>
    //       <div ref={chartRef} className='mixed-widget-4-chart'></div>
    //     </div>

    //     {/* <div className='pt-5'>
    //       <p className='text-center fs-6 pb-5 '>
    //         <span className='badge badge-light-danger fs-8'>Notes:</span>&nbsp; Current sprint
    //         requires stakeholders
    //         <br />
    //         to approve newly amended policies
    //       </p>

    //       <a href='#' className={`btn btn-${chartColor} w-100 py-3`}>
    //         Take Action
    //       </a>
    //     </div> */}
    //   </div>
    //   {/* end::Body */}
    // </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue('--kt-' + chartColor)
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--kt-gray-700')

  return {
    series: [36],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export { TotalProgressPercentageWidget2 }
