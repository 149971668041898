import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import { CostTable } from '../../../tables/cost-table/CostTable'




const CostStep: FC = () => {
  return (
     <CostTable/>
  )
}

export {CostStep}
