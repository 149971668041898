import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const AdvanceAmountCell: FC<Props> = ({ site }) => {


    return (
        <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
                {site.advanceAmountTry}
            </div>
        </div>
    )
}
export { AdvanceAmountCell }
