import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {OnlineToolsListWrapper} from './online-tools/OnlineToolsList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="ONLINE.TOOLS"/>,
    path: 'online-tool',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OnlineToolsPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='ot'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'ONLINE.TOOLS.LIST' })}</PageTitle>
              <OnlineToolsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/online-tool/ot' />} />
    </Routes>
  )
}

export default OnlineToolsPage
