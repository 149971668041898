import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { MAFsListWrapper } from './project-reports-list/ProjectReportsList'


const ProjectReportsPage = () => {
  const intl = useIntl()

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: <FormattedMessage id ="PROJECT.REPORTS.LIST"/>,
      path: 'project-reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='pr'
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'PROJECT.REPORTS.LIST' })}</PageTitle>
              <MAFsListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/project-reports/pr' />} />
    </Routes>
  )
}

export default ProjectReportsPage
