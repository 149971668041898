/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { FILEGROUP } from '_metronic/helpers'
import { FormattedMessage } from 'react-intl'


type Props = {
  group: number
}

const GroupCell: FC<Props> = ({group}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {group !== undefined && group === FILEGROUP.ATTACHMENT ? (
          <FormattedMessage id='BUGREPORTING.ATTACHMENT' />
        ) :(
          <FormattedMessage id='BUGREPORTING.MAIN' />
        )}
      </a>
    </div>
  </div>
)

export {GroupCell}
