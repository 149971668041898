/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  total: number
  ongoing: number
  planned: number
  completed: number
  tendering: number
  provisionalAcceptence: number
  descriptionColor?: string
}

const StatisticsWidgetHospitalSite: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  total,
  ongoing,
  planned,
  completed,
  tendering,
  provisionalAcceptence,
  descriptionColor,
}) => {
  const intl = useIntl()

  return (
    <span className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>

        <div className='row mt-5'>
          <div className={`fw-semibold text-${descriptionColor} col-6`}>
            {intl.formatMessage({ id: 'DASHBOARD.DESIGN.PHASE' })}
          </div>
          <div className='col-6'>
            <span className='symbol symbol-50px'>
              <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
                {planned}
              </span>
            </span>
          </div>
        </div>

        <div className='row mt-5'>
          <div className={`fw-semibold text-${descriptionColor} col-6`}>
            {intl.formatMessage({ id: 'DASHBOARD.TENDERING' })}
          </div>
          <div className='col-6'>
            <span className='symbol symbol-50px'>
              <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
                {tendering}
              </span>
            </span>
          </div>
        </div>

        <div className='row mt-5'>
          <div className={`fw-semibold text-${descriptionColor} col-6`}>
            {intl.formatMessage({ id: 'DASHBOARD.CONSTRUCTION.PHASE' })}
          </div>
          <div className='col-6'>
            <span className='symbol symbol-50px'>
              <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
                {ongoing}
              </span>
            </span>
          </div>
        </div>

        <div className='row  mt-5'>
          <div className={`fw-semibold text-${descriptionColor} col-6`}>
            {intl.formatMessage({ id: 'DASHBOARD.PROVISIONAL' })}
          </div>
          <div className='col-6'>
            <span className='symbol symbol-50px'>
              <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
                {provisionalAcceptence}
              </span>
            </span>
          </div>
        </div>

        <div className='row mt-5'>
          <div className={`fw-semibold text-${descriptionColor} col-6`}>
            {intl.formatMessage({ id: 'DASHBOARD.HOSPITAL.COMPLETED' })}
          </div>
          <div className='col-6'>
            <span className='symbol symbol-50px'>
              <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
                {completed}
              </span>
            </span>
          </div>
        </div>

        <div className='row mt-5'>
          <div className={`fw-semibold text-${descriptionColor} col-6`}>
            {intl.formatMessage({ id: 'DASHBOARD.HOSPITAL.TOTAL' })}
          </div>
          <div className="col-6">
            <span className='symbol symbol-50px'>
              <span className={`symbol-label fs-5 fw-bold bg-light-${color} text-${color}`}>
                {total}
              </span>
            </span>
          </div>

        </div>
      </div>
    </span>

  )
}

export { StatisticsWidgetHospitalSite }
