import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { RiskMatrixListWrapper } from './risk-matrix/RiskMatrixList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="RISK.MATRIX"/>,
    path: 'risk-matrix',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RiskMatrixPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='rm'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'RISK.MATRIX.LIST' })}</PageTitle>
              <RiskMatrixListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/risk-matrix/rm' />} />
    </Routes>
  )
}

export default RiskMatrixPage
