import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const SiteBoQCell: FC<Props> = ({ site }) => {

  var formattedDate = '';

  if (site.expectedConstructionWorksCompletionDate != null || site.expectedConstructionWorksCompletionDate != undefined) {
    formattedDate = site.expectedConstructionWorksCompletionDate === '0001-01-01T00:00:00' ? '' : new Date(site.expectedConstructionWorksCompletionDate).toLocaleDateString('tr-TR');
  }

  return (
    <div className='d-flex align-items-center'>
      {formattedDate}
    </div>
  )
}
export { SiteBoQCell }
