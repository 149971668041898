import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getUserId, isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { EstimatedCostModel } from '../core/_models'
import { v4 as uuidv4 } from 'uuid';
import { useListView } from '../core/ListViewProvider'
import { updateEstimatedCost } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'react-toastify';
import { EstimatedCostListLoading } from './EstimatedCostListLoading'
import clsx from 'clsx'

type Props = {
  isEstimatedCostLoading: boolean
  estimatedCost: EstimatedCostModel
}

const EstimatedCostEditModalForm: FC<Props> = ({ estimatedCost, isEstimatedCostLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [estimatedCostForEdit] = useState<EstimatedCostModel>({
    ...estimatedCost,
    id: estimatedCost.id,
    site: estimatedCost.site,
    estimatedCostTL: estimatedCost.estimatedCostTL,
    estimatedCostEUR: estimatedCost.estimatedCostEUR,
  })

  const editEstimatedCostSchema = Yup.object().shape({
    estimatedCostTL: Yup.number()
      .required('Estimated Cost (TL) is required'),
    estimatedCostEUR: Yup.number()
      .required('Estimated Cost (EUR) is required'),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const intl = useIntl()

  const formik = useFormik({
    initialValues: estimatedCostForEdit,
    validationSchema: editEstimatedCostSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateEstimatedCost(values)
        }
      } catch (ex) {
        console.error(ex)
        toast.error(intl.formatMessage({ id: 'ERROR_TOASTER' }));
      } finally {
        setSubmitting(true)
        cancel(true)
        toast.success(intl.formatMessage({ id: 'SUCCESS_TOASTER' }));
      }
    },
  })
console.log("-------------------",formik.values)
console.log("---------fpfpfp-----",formik.getFieldProps("site").value)


  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>

            <label className="fw-bold fs-6 mb-2">{<FormattedMessage id='ESTIMATED.COST.SITE' />}</label>
            <input
              {...formik.getFieldProps('site')}
              type="text"
              name="site"
              className={clsx(
                "form-control form-control-solid mb-6 mb-lg-0",
                { "is-invalid": formik.touched.site && formik.errors.site },
                {
                  "is-valid": formik.touched.site && !formik.errors.site,
                }
              )}
              autoComplete="off"
              disabled
            />
            <br></br>
            <label className="fw-bold fs-6 mb-2">{<FormattedMessage id='ESTIMATED.COST.TL' />}</label>
            <input
              {...formik.getFieldProps('estimatedCostTL')}
              type="number"
              name="estimatedCostTL"
              className={clsx(
                "form-control form-control-solid mb-6 mb-lg-0",
                { "is-invalid": formik.touched.estimatedCostTL && formik.errors.estimatedCostTL },
                {
                  "is-valid": formik.touched.estimatedCostTL && !formik.errors.estimatedCostTL,
                }
              )}
              autoComplete="off"
            />
            <br></br>
            <label className="fw-bold fs-6 mb-2">{<FormattedMessage id='ESTIMATED.COST.EUR' />}</label>
            <input
              {...formik.getFieldProps('estimatedCostEUR')}
              type="number"
              name="estimatedCostEUR"
              className={clsx(
                "form-control form-control-solid mb-6 mb-lg-0",
                { "is-invalid": formik.touched.estimatedCostTL && formik.errors.estimatedCostTL },
                {
                  "is-valid": formik.touched.estimatedCostTL && !formik.errors.estimatedCostTL,
                }
              )}
              autoComplete="off"
            />



        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isEstimatedCostLoading}
          >
            {<FormattedMessage id ="DISCARD"/>}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={ formik.isSubmitting || !formik.isValid || isEstimatedCostLoading}
          >
            <span className='indicator-label'>{<FormattedMessage id ="SUBMIT"/>}</span>
            {(formik.isSubmitting || isEstimatedCostLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isEstimatedCostLoading) && <EstimatedCostListLoading />}
    </>
  )
}

export { EstimatedCostEditModalForm }





