import {LargeNumberLike} from 'crypto';
import {Dispatch, SetStateAction} from 'react';
import internal from 'stream';
import {ID, Response} from '../../../../../_metronic/helpers';
import { ListSite } from 'app/modules/procurement-management/core/_models';


export type ProgressPaymentReportModel = {
  id?: ID,
  progressPaymentId?: ID,
  createDate?: string,
  no?: number,
  contractName?: string,
  lastProgressPaymentTotalAmount?: number
  thisProgressPaymentTotalAmount?: number
  totalAmount?: number
  vat?: number
  priceDiffAmount?: number
  progressDoneWithContractCost?: number,
  accrualRate?: number
  corporationIncometax?: number
  stampDuty?: number
  vatWithholding?: number
  ssCut?: number
  authorityEquipmentRent?: number
  lateFee?: number
  avansPaymentOffset?: number
  priceDifferenceGuaranteeDeductionForCurrentProgressPayment?: number
  forContractMatterThreePercent?: number
  sumOfCutsAndOffsetFees?: number
  totalAmountToContractor?: number
  jobStartDate?: string
  jobEndDate?: string
  paymentDate?: string
  approvalDate?: string
}

export type ProgressPaymentUploadModel = {
  id?: ID,
  progressPaymentId?: ID,
  dateOfDocument?: string,
  fileName?: string,
  files?: Array<FileInfoModel>,
  uploadedFiles?: Array<any>,
}

export type  FileInfoModel = {
  fileId?: ID,
  fileName?: string,
  group?: number
}

export interface ModelforBarchart{
  name?: string,
  data?: number[]
}

export type  BarChartContractModel = {
  sites?: BarChartSiteModel[],
  contractName?: string,
  progressPaymentSequence?: number,
  topTotal?: number
}
export type StateSeriesModel = {
  name: string;
  type: string;
  data: number[];
}


export type  BarChartSiteModel = {
  name?: string,
  total?: number | undefined
  totalPercentage?: number | undefined
  manufacturingPercentage?: number | undefined
}

export type  BarChartSeriesModel = {
  name?: string,
  data?:
  | (number | null)[]
  | {
      x: any;
      y: any;
      fillColor?: string;
      strokeColor?: string;
      meta?: any;
      goals?: any;
    }[]
  | [number, number | null][]
  | [number, (number | null)[]][]
}

export type ProgressPaymentModel = {
  id?: ID,
  procurementRtpeId?: ID,
  contractId?: ID,
  siteId?: ID,
  siteName?: string,
  contractPrice?: number,
  progressPaymentSequence?: number,

  staticPercentage?: number
  architecturalPercentage?: number
  infrastructurePercentage?: number
  mechanicalPercentage?: number
  electricalPercentage?: number
  landscapePercentage?: number
  constructionPWRPercentage?: number
  otherPercentage?: number

  staticAmount?: number
  architecturalAmount?: number
  infrastructureAmount?: number
  mechanicalAmount?: number
  electricalAmount?: number
  landscapeAmount?: number
  constructionPWRAmount?: number
  otherAmount?: number

  //user input (column b)
  staticProductionRealizedPercentage?: number
  architecturalProductionRealizedPercentage?: number
  infrastructureProductionRealizedPercentage?: number
  mechanicalProductionRealizedPercentage?: number
  electricalProductionRealizedPercentage?: number
  landscapeProductionRealizedPercentage?: number
  constructionPWRProductionRealizedPercentage?: number
  otherProductionRealizedPercentage?: number


  staticTotalProductionRealizedAmount?: number
  architecturalTotalProductionRealizedAmount?: number
  infrastructureTotalProductionRealizedAmount?: number
  mechanicalTotalProductionRealizedAmount?: number
  electricalTotalProductionRealizedAmount?: number
  landscapeTotalProductionRealizedAmount?: number
  constructionPWRTotalProductionRealizedAmount?: number
  otherTotalProductionRealizedAmount?: number

  //before
  beforeStaticTotalProductionPercentage?: number
  beforeArchitecturalTotalProductionPercentage?: number
  beforeInfrastructureTotalProductionPercentage?: number
  beforeMechanicalTotalProductionPercentage?: number
  beforeElectricalTotalProductionPercentage?: number
  beforeLandscapeTotalProductionPercentage?: number
  beforeConstructionPWRTotalProductionPercentage?: number
  beforeOtherTotalProductionPercentage?: number


  beforeStaticAmount?: number
  beforeArchitecturalAmount?: number
  beforeInfrastructureAmount?: number
  beforeMechanicalAmount?: number
  beforeElectricalAmount?: number
  beforeLandscapeAmount?: number
  beforeconstructionPWRAmount?: number
  beforeOtherAmount?: number


  //this
  thisStaticTotalProductionPercentage?: number
  thisArchitecturalTotalProductionPercentage?: number
  thisInfrastructureTotalProductionPercentage?: number
  thisMechanicalTotalProductionPercentage?: number
  thisElectricalTotalProductionPercentage?: number
  thisLandscapeTotalProductionPercentage?: number
  thisconstructionPWRTotalProductionPercentage?: number
  thisOtherTotalProductionPercentage?: number

  thisStaticAmount?: number
  thisArchitecturalAmount?: number
  thisInfrastructureAmount?: number
  thisMechanicalAmount?: number
  thisElectricalAmount?: number
  thisLandscapeAmount?: number
  thisconstructionPWRAmount?: number
  thisOtherAmount?: number
  thisProgressPaymentTotalAmount?: number

  progressPaymentReportTotalAmount?: number
  progressPaymentReportTotalAmountToContractor?: number
  progressPaymentReportPaymentDate?: string
  progressPaymentReportApprovalDate?: string
  createDate?: string
  progressDoneWithContractCost?: number,
  //supply and good
  date?: string
  amount?: string
  filePath?: string

}
export type ProgressPaymentQueryResponse = Response<Array<ProgressPaymentModel>>


export type ProgressPaymentBeforeValues = {

  contractPrice?: number

  thisProgressPaymentSequence?: number,
  staticPercentage?: number
  architecturalPercentage?: number
  infrastructurePercentage?: number
  mechanicalPercentage?: number
  electricalPercentage?: number
  landscapePercentage?: number
  constructionPWRPercentage?: number
  otherPercentage?: number
  

  beforeStaticTotalProductionPercentage?: number
  beforeArchitecturalTotalProductionPercentage?: number
  beforeInfrastructureTotalProductionPercentage?: number
  beforeMechanicalTotalProductionPercentage?: number
  beforeElectricalTotalProductionPercentage?: number
  beforeLandscapeTotalProductionPercentage?: number
  beforeConstructionPWRTotalProductionPercentage?: number
  beforeOtherTotalProductionPercentage?: number
  beforeStaticAmount?: number
  beforeArchitecturalAmount?: number
  beforeInfrastructureAmount?: number
  beforeMechanicalAmount?: number
  beforeElectricalAmount?: number
  beforeLandscapeAmount?: number
  beforeconstructionPWRAmount?: number
  beforeOtherAmount?: number
}

export const initialQueryResponse = {
  refetch: () => {
  }, isLoading: false, query: ''
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>

  progressPaymentIdForUpdate?: ID
  setProgressPaymentIdForUpdate: Dispatch<SetStateAction<ID>>
  progressPaymentIdForReportUpdate?: ID
  setProgressPaymentIdForReportUpdate: Dispatch<SetStateAction<ID>>



  isReportPage?: ID
  setIsReportPage: Dispatch<SetStateAction<ID>>
  isUploadPage?: ID
  setIsUploadPage: Dispatch<SetStateAction<ID>>
  isChoiceContractTypePage?: ID
  setIsChoiceContractTypePage: Dispatch<SetStateAction<ID>>
  isChoiceContractPage?: ID
  setIsChoiceContractPage: Dispatch<SetStateAction<ID>>
  progressPaymentId?: ID
  setProgressPaymentId: Dispatch<SetStateAction<ID>>
  procurementTypeId?: ID
  setProcurementTypeId: Dispatch<SetStateAction<ID>>,
  contractId?: ID
  setContractId: Dispatch<SetStateAction<ID>>,
  contractSiteData?: any
  setContractSiteData: Dispatch<SetStateAction<any>>,
  contractName: string
  setContractName: Dispatch<SetStateAction<string>>,
  itemIdForDelete?: ID
  setItemIdForDelete: Dispatch<SetStateAction<ID>>
  itemProgressPaymentSequenceDelete?: any
  setItemProgressPaymentSequenceDelete: Dispatch<SetStateAction<any>>
  siteTypeForUpdate: string
  setSiteTypeForUpdate: Dispatch<SetStateAction<string>>
  siteTypeForFormik: string
  setSiteTypeForFormik: Dispatch<SetStateAction<string>>
  isAllSelected: boolean
  disabled: boolean

  fileModelList: Array<FileInfoModel>
  setFileModelList: Dispatch<SetStateAction<Array<FileInfoModel>>>
  mainFile: FileInfoModel
  setMainFile: Dispatch<SetStateAction<FileInfoModel>>
  helpPageOn: boolean
  setHelpPageOn: Dispatch<SetStateAction<boolean>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {
  },
  onSelectAll: () => {
  },
  clearSelected: () => {
  },
  setItemIdForUpdate: () => {
  },
  setProgressPaymentIdForUpdate: () => {
  },
  setProgressPaymentIdForReportUpdate: () => {
  },
  setItemIdForDelete: () => {
  },
  setItemProgressPaymentSequenceDelete: () => {
  },
  setIsReportPage: () => {
  },
  setIsUploadPage: () => {
  },
  setIsChoiceContractTypePage: () => {
  },
  setIsChoiceContractPage: () => {
  },
  siteTypeForUpdate: "",
  setSiteTypeForUpdate: () => {
  },
  contractName: "",
  setContractName: () => {
  },
  setProgressPaymentId: () => {
  },
  setContractId: () => {
  },
  setContractSiteData: () => {
  },
  siteTypeForFormik: "",
  setSiteTypeForFormik: () => {
  },
  isAllSelected: false,
  disabled: false,
  setFileModelList: () => {
  },
  fileModelList: [],
  mainFile: {
    fileId: "",
    fileName: "",
    group: 0
  },
  setMainFile: () => {
  },
  setHelpPageOn: () => {
  },
  helpPageOn: false,
  setProcurementTypeId: () => {
  }
}











