import axios from 'axios'
import { OnlineToolsQueryResponse, OnlineToolsModel } from './_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import fileDownload from 'js-file-download'

const API_URL = "https://shifatr.org/api/online-tool"
const API_FILE_URL = "https://shifatr.org/api/online-tool"

const getOnlineToolss = async (query: string): Promise<OnlineToolsQueryResponse> => {
    return await axios
    .get(`${API_URL}?${query}`)
  }

const createOnlineToolsFile = (onlineTools: any): Promise<OnlineToolsModel | undefined> => {
  return axios

    .post(`${API_FILE_URL}`, onlineTools,{
      headers: {
        "Content-Type": "multipart/form-data",
      }})
    .then((response: Response<OnlineToolsModel>) => response.data)
}

const deleteOnlineTools = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_FILE_URL}/${parameterId}`).then(() => { })
}

const getHelpPdf = (): any => {
  return axios
    .get(`${API_URL}/getHelpDocument`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

const getFileById = (id: any, fileName:any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url:`${API_URL}/download-online-tool-file/${id}`,
  }).then((response) => {
    if(response.data)
    {       
      console.log(response.data)
      const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
      window.open(url)
    }

  });
}

const downloadFileById = (id: any, fileName:any) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url:`${API_URL}/download-online-tool-file/${id}`,
  }).then((response) => {
    if(response.data)
    {       
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download",`${fileName}`);
      document.body.appendChild(link);
      link.click();
    }

  });  
}
const getChecklistAsPdf = (query: string) => {
  console.log(query)
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/pdfreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (query: string) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/excelreport?${query}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}


export { downloadFileById, getFileById, getOnlineToolss, createOnlineToolsFile , deleteOnlineTools, getHelpPdf, getChecklistAsExcel, getChecklistAsPdf }