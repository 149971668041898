import { FormattedMessage } from "react-intl"
import {KTSVG, ROLES, getUserRole, stringifyRequestQuery} from "../../../../../../_metronic/helpers"
import {useListView} from '../../core/ListViewProvider'
import {EstimatedCostListFilter} from './EstimatedCostListFilter'
import { useQueryRequest } from "../../core/QueryRequestProvider"
import { useState, useMemo, useEffect } from "react"

const EstimatedCostListToolbar = () => {
  const role = getUserRole();
    const {setItemIdForUpdate, setHelpPageOn} = useListView()
  const openAddParameterModal = () => {
    setItemIdForUpdate(null)
  }
  const {state} = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])
  const openHelpPage = () => {
    setHelpPageOn(true)
  }

  

  return (
    <div className='d-flex justify-content-end' data-kt-users-table-toolbar='base'>
      {/* <EstimatedCostListFilter /> */}

      {/* {(role == ROLES.Superadmin.name ) &&
      <button type='button' className='btn btn-primary' onClick={openAddParameterModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {<FormattedMessage id ="ESTIMATED.COST.ADD"/>}
      </button>
      } */}
      
    </div>
  )
}

export {EstimatedCostListToolbar}
