/* eslint-disable jsx-a11y/anchor-is-valid */
import { deleteFileById, downloadFileById } from 'app/modules/file-upload-page/core/_requests'
import {FC, useEffect} from 'react'
import { FormattedMessage } from 'react-intl'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import { KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../core/ListViewProvider'
import { FileInfoModel } from '../../../core/_models'

type Props = {
  file: FileInfoModel
}

const FileActionsCell: FC<Props> = ({file}) => {
  const {setFileModelList, fileModelList, itemIdForUpdate} = useListView()
  
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  
  const downloadFile = () => {
    downloadFileById(file.fileId,file.fileName)
  }

  const deleteItem = async () => {
    if(file.fileId)
       await deleteFileById(file.fileId)
    setFileModelList(fileModelList.filter(x => x.fileName !== file.fileName))
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {<FormattedMessage id ="ACTIONS"/>}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {itemIdForUpdate && <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => downloadFile()}
          >
            {<FormattedMessage id ="FILE.DOWNLOAD"/>}
            {/* {"Download File"} */}
          </a>
        </div>}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => deleteItem()}
          >
            {<FormattedMessage id ="DELETE"/>}
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {FileActionsCell}
