import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {LogFrameListHeader} from './components/header/LogFrameListHeader'
import {LogFrameEditModal} from './log-frame-modal/LogFrameEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { LogFrameTable } from './table/LogFrameTable'
import { LogFrameDeleteModal } from '../LogFrameDeleteModal'

const LogFrameList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <LogFrameListHeader />
        <LogFrameTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <LogFrameEditModal />}
      {itemIdForDelete !== undefined && <LogFrameDeleteModal />}
    </>
  )
}

const LogFrameListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <LogFrameList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {LogFrameListWrapper}
