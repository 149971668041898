import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLang } from '_metronic/i18n/Metronici18n'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { KTSVG, initialQueryState, QUERIES, SITETYPES, District, } from "../../../../../../_metronic/helpers"
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { useQuery } from 'react-query'
import { getCities } from '../../core/_requests'


const CiapFilter = () => {
    const { query } = useQueryResponse()
    const { state, updateState } = useQueryRequest()
    const { isLoading } = useQueryResponse()

    const [phase, setPhase] = useState<string | undefined>();
    const [district, setDistrict] = useState<string | undefined>();
    const [selectedWorksType, setSelectedWorksType] = useState<string[]>([]);
    const [earthquakeAffectedArea, setEarthquakeAffectedArea] = useState<string | undefined>();
    const [cityId, setcityId] = useState<string | undefined>();

    const options = [
        { value: "NEW MHC", label: "NEW MHC" },
        { value: "NEW E/MHC", label: "NEW E/MHC" },
        { value: "PTR Unit", label: "PTR Unit" },
        { value: "Hospital Rehabilitation", label: "Hospital Rehabilitation" },
        { value: "MHC/EMHC Rehabilitation", label: "MHC/EMHC Rehabilitation" }
    ];

    const defaultState = {
        phase: "",
        cityId: "",
        district: undefined,
        earthquakeAffectedArea: "",
        selectedWorksType: []
    };

    const handleOptionClick = (optionValue: string) => {
        setSelectedWorksType(prevSelectedWorksType =>
            prevSelectedWorksType.includes(optionValue)
                ? prevSelectedWorksType.filter(value => value !== optionValue)
                : [...prevSelectedWorksType, optionValue]
        );
    };
    // Data Reads

    const {
        data: citiesResponse,
    } = useQuery(
        `${QUERIES.CITY_LIST}-city-list-${query}`,
        () => {

            return getCities()
        },
        { cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false }
    )

    useEffect(() => {
        MenuComponent.reinitialization()
        console.log(cityId)

    }, [cityId])

    const resetData = () => {
        setPhase(defaultState.phase);
        setcityId(defaultState.cityId);
        setDistrict(defaultState.district);
        setEarthquakeAffectedArea(defaultState.earthquakeAffectedArea);
        setSelectedWorksType(defaultState.selectedWorksType);
        updateState({ filter:{}, ...initialQueryState })
    }

    const filterData = () => {
        var earthquakeArea: boolean | undefined = undefined;
        if (earthquakeAffectedArea === "true") {
            earthquakeArea = true;
        } else if (earthquakeAffectedArea === "false") {
            earthquakeArea = false;
        }
        updateState(
            {
                filter: {
                    phase: phase, cityId: cityId, district: district,
                    worksType: selectedWorksType, earthquakeAffectedArea: earthquakeArea
                },
                ...initialQueryState
            }
        )
    }

    const lang = useLang()
    const intl = useIntl();
    return (
        <>
            {/* begin::Filter Button */}
            <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                {<FormattedMessage id="FILTER" />}
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
                {/* begin::Header */}
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>{<FormattedMessage id="FILTER.OPTIONS" />} </div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className='separator border-gray-200'></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    <div className="row ">

                        <div className="col-6 mb-7">
                            {/* begin::Phase */}
                            <div className='mb-10'>
                                <label className='form-label fs-6 fw-bold'>{<FormattedMessage id="CHOOSE.CONTRACT" />} :</label>
                                <select
                                    className='form-select form-select-solid fw-bolder'
                                    data-kt-select2='true'
                                    name='phase'
                                    data-hide-search='true'
                                    onChange={(e) => setPhase(e.target.value)}
                                    value={phase}
                                >
                                    <option value={""} >{<FormattedMessage id="CHOOSE.CONTRACT" />} </option>
                                </select>
                            </div>
                            {/* end::Phase */}
                        </div>
                    </div>

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                        <button
                            type='button'
                            disabled={isLoading}
                            onClick={resetData}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                        >
                            {<FormattedMessage id="DISCARD" />}
                        </button>
                        <button
                            disabled={isLoading}
                            type='button'
                            onClick={filterData}
                            className='btn btn-primary fw-bold px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='filter'
                        >
                            {<FormattedMessage id="FILTER" />}
                        </button>
                    </div>
                    {/* end::Actions */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::SubMenu */}
        </>

    )
}

export { CiapFilter }
