import {FC, useEffect,useState} from 'react'
import {HelpModalHeader} from './ProjectReportsHelpModalHeader'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useQuery } from 'react-query';
import { getHelpPdf } from '../core/_requests';
import { KTSVG } from '_metronic/helpers';

type Props = {
  closeFunction: Function
}

const HelpModal: FC<Props> = ({ closeFunction }) => {

  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: {numPages: number}) {
    setNumPages(numPages);
  }

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `help`,
    () => {
      return getHelpPdf()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const goToPrevPage = () => {
    setPageNumber(pageNumber <= 1 ? 1 : pageNumber-1)
  }

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered modal-lg '>
          {/* begin::Modal content */}
          <div className='modal-content '>
            <HelpModalHeader closeFunction={closeFunction} />
            {/* begin::Modal body */}
            <div className='modal-body col-12'>
              {numPages > 1 &&
                <>
                  <div className="d-flex flex-row justify-content-center mb-3">
                    <button className="btn btn-primary float-left mx-5 h-50px"onClick={goToPrevPage}><KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2' /></button>
                    <button className="btn btn-primary float-left mx-5 h-50px"onClick={goToNextPage}><KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' /></button>
                  </div>
                </>
              }
              <div className="d-flex flex-row justify-content-center">
                <Document file={response} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}
export {HelpModal}
