import { FormattedMessage, useIntl } from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {InformationCampaignListWrapper} from './information-campaign/InformationCampaignList'

const parametersBreadcrumbs: Array<PageLink> = [
  {
    title: <FormattedMessage id ="INFORMATION.CAMPAIGN"/>,
    path: 'information-campaign',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const InformationCampaignPage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='ic'
          element={
            <>
              <PageTitle breadcrumbs={parametersBreadcrumbs}>{intl.formatMessage({ id: 'INFORMATION.CAMPAIGN.LIST' })}</PageTitle>
              <InformationCampaignListWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/information-campaign/ic' />} />
    </Routes>
  )
}

export default InformationCampaignPage
