import {Column} from 'react-table'
import {SiteCustomHeader} from './SiteCustomHeader'
import { SiteArchitecturalCell} from './SiteArchitecturalCell'
import { SiteStructuralCell } from './SiteStructuralCell'
import { SiteMechanicalCell } from './SiteMechanicalCell'
import { SiteElectricalCell } from './SiteElectricalCell'
import { SiteTechnicalSpecificationsCell } from './SiteTechnicalSpecificationsCell'
import { SiteBoQCell } from './SiteBoQCell'
import { SiteModel } from '../../../core/_models'
import { FormattedMessage } from 'react-intl'
import { SiteNumberIdentificationCell } from '../../shifa-scope-table/columns/SiteNumberIdentificationCell'
import { BuildingNameCell } from '../../shifa-scope-table/columns/BuildingNameCell'

const sitesColumns: ReadonlyArray<Column<SiteModel>> = [
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.SITE.NUMBER.IDENTIFICATION'/>}  className='min-w-200px' />,
    id: 'SITENUMBERIDENTIFICATION',
    Cell:({...props}) => <SiteNumberIdentificationCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SHIFA.SCOPE.BUILDING.NAME'/>}  className='min-w-200px' />,
    id: 'BUILDINGNAME',
    Cell:({...props}) => <BuildingNameCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'SITE.HANDOVER'/>}  className='min-w-200px' />,
    id: 'siteHandover',
    Cell:({...props}) => <SiteArchitecturalCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONSTRUCTION.PERMITS.OBTAINED'/>}  className='min-w-200px' />,
    id: 'constructionPermitsObtained',
    Cell:({...props}) => <SiteStructuralCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONSTRUCTION.WORKS.DURATION'/>} className='min-w-200px' />,
    id: 'constructionWorksDuration',
    Cell:({...props}) => <SiteMechanicalCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONSTRUCTION.WORKS.CONTRACTED.COMPLETION.DATE'/>}  className='min-w-200px' />,
    id: 'constructionWorksContractedCompletionDate',
    Cell:({...props}) => <SiteElectricalCell site={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'CONTINGENCY.IN.TIME.OR.TIME.EXTENSION'/>}  className='min-w-200px' />,
    id: 'contingencyInTimeOrTimeExtension',
    Cell:({...props}) => <SiteTechnicalSpecificationsCell site={props.data[props.row.index]} />,
  }, 
  {
    Header: (props) => <SiteCustomHeader tableProps={props} title={<FormattedMessage id = 'EXPECTED.CONSTRUCTION.WORKS.COMPLETION.DATE'/>} className='min-w-200px' />,
    id: 'sboQ',
    Cell:({...props}) => <SiteBoQCell site={props.data[props.row.index]} />,
  },
]

export {sitesColumns}
