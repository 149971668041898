import React, { FC } from "react";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import { ProjectStatusTable } from "../../../tables/project-status-table/ProjectStatusTable";

const ProjectStatusStep: FC = () => {
  return (
    <ProjectStatusTable/>
  )
}

export { ProjectStatusStep };
