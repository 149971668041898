import {Column} from 'react-table'
import {VisibilityEventDescriptionCell} from './VisibilityEventDescriptionCell'
import {VisibilityEventActionsCell} from './VisibilityEventActionsCell'
import {VisibilityEventCustomHeader} from './VisibilityEventCustomHeader'
import {VisibilityEventModel} from '../../core/_models'
import { VisibilityEventVersionDateCell } from './VisibilityEventVersionDateCell'
import { VisibilityEventFileNameCell } from './VisibilityEventFileNameCell'
import { FormattedMessage } from 'react-intl'
import { VisibilityEventLinkCell } from './VisibilityEventLinkCell'


var visibilityEventColumns: ReadonlyArray<Column<VisibilityEventModel>> = [

  {
    Header: (props) => <VisibilityEventCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <VisibilityEventDescriptionCell visibilityEvent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <VisibilityEventCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <VisibilityEventVersionDateCell visibilityEvent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <VisibilityEventCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <VisibilityEventFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => <VisibilityEventCustomHeader tableProps={props} title={<FormattedMessage id ="LINK.UC"/>} className='min-w-250px' />,
    id: 'link',
    Cell: ({...props}) => <VisibilityEventLinkCell visibilityEvent={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <VisibilityEventCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <VisibilityEventActionsCell id={props.data[props.row.index].id} link={props.data[props.row.index].link} filePath={props.data[props.row.index].filePath } />,
  }
]


export {visibilityEventColumns}
