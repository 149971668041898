import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const NumberOfFloors: FC<Props> = ({ site }) => (

    <div className='d-flex align-items-center'>
        {site.numberOfFloors}
    </div>

)
export { NumberOfFloors }
