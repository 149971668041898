import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const SiteStructuralCell: FC<Props> = ({ site }) => {

  var formattedDate = '';

  if (site.constructionPermitsObtained != null || site.constructionPermitsObtained != undefined) {
    formattedDate = site.constructionPermitsObtained === '0001-01-01T00:00:00' ? '' : new Date(site.constructionPermitsObtained).toLocaleDateString('tr-TR');
  }

  return (
    <div className='d-flex align-items-center'>
      {formattedDate}
    </div>
  )
}

export { SiteStructuralCell }
