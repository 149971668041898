/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {InformationCampaignModel} from '../../core/_models'

type Props = {
  informationCampaign: InformationCampaignModel
}

const InformationCampaignDescriptionCell: FC<Props> = ({informationCampaign}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {informationCampaign.description}
      </a>
      
    </div>
  </div>     

)}
export {InformationCampaignDescriptionCell}
