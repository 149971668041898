import { useEffect, useMemo, useState } from 'react'
import { useTable, ColumnInstance, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../../core/QueryResponseProvider'
import { sitesColumns } from './columns/_columns'
import { SiteModel } from '../../core/_models'
import { ChecklistListLoading } from '../../components/loading/ChecklistListLoading'
import { ChecklistListPagination } from '../../components/pagination/ChecklistListPagination'
import { KTCardBody } from '../../../../../../_metronic/helpers'
import { formatEUR, formatGlobal, formatTRY } from '_metronic/partials/widgets/tables/TenderListWidget'
import { useIntl } from 'react-intl'

const CostTable = () => {
  const sites = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data1 = useMemo(() => sites, [sites])
  const data = data1;
  const columns = useMemo(() => sitesColumns, [])
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  })
  // const [totalEstimatedProcurementCostTRY, setTotalEstimatedProcurementCostTRY] = useState(0)
  // const [totalEstimatedProcurementCostEUR, setTotalEstimatedProcurementCostEUR] = useState(0)
  // const [totalContractedConstructionCostTRY, setTotalContractedConstructionCostTRY] = useState(0)
  // const [totalExchangeRateAtContractSignatureDate, setTotalExchangeRateAtContractSignatureDate] = useState(0)
  // const [totalContractedConstructionCostEUR, setTotalContractedConstructionCostEUR] = useState(0)
  // const [totalcontractedConstructionCostPerM2EUR, setTotalcontractedConstructionCostPerM2EUR] = useState(0)
  // const [totalBudgetEUR, setTotalBudgetEUR] = useState(0)
  const intl = useIntl()

  useEffect(() => {
    // const totalestimatedProcurementCostTRY = data.reduce((acc, site) => {
    //   return acc + (site.estimatedProcurementCostTRY || 0)
    // }, 0)
    // setTotalEstimatedProcurementCostTRY(totalestimatedProcurementCostTRY)

    // const totalestimatedProcurementCostEUR = data.reduce((acc, site) => {
    //   return acc + (site.estimatedProcurementCostEUR || 0)
    // }, 0)
    // setTotalEstimatedProcurementCostEUR(totalestimatedProcurementCostEUR)

    // const totalcontractedConstructionCostTRY = data.reduce((acc, site) => {
    //   return acc + (site.contractedConstructionCostTRY || 0)
    // }, 0)
    // setTotalContractedConstructionCostTRY(totalcontractedConstructionCostTRY)

    // const totalexchangeRateAtContractSignatureDate = data.reduce((acc, site) => {
    //   return acc + (site.exchangeRateAtContractSignatureDate || 0)
    // }, 0)
    // setTotalExchangeRateAtContractSignatureDate(totalexchangeRateAtContractSignatureDate)

    // const totalcontractedConstructionCostEUR = data.reduce((acc, site) => {
    //   return acc + (site.contractedConstructionCostEUR || 0)
    // }, 0)
    // setTotalContractedConstructionCostEUR(totalcontractedConstructionCostEUR)

    // const totalcontractedConstructionCostPerM2EUR = data.reduce((acc, site) => {
    //   return acc + (site.contractedConstructionCostPerM2EUR || 0)
    // }, 0)
    // setTotalcontractedConstructionCostPerM2EUR(totalcontractedConstructionCostPerM2EUR)

    // const totalbudgetEUR = data.reduce((acc, site) => {
    //   return acc + (site.budgetEUR || 0)
    // }, 0)
    // setTotalBudgetEUR(totalbudgetEUR)

  }, [data])
  return (
    <KTCardBody className='py-1'>

      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 '>
              {headers.map((column: ColumnInstance<SiteModel>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-500' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SiteModel>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={13}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Kayıt bulunanmamıştır.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <hr /> */}
        {/* <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
           <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 '>
              <th>
                {intl.formatMessage({id: 'TOTAL.ESTIMATED.PROCUREMENT.COST.TRY'})}
              </th>
              <th>
                {intl.formatMessage({id: 'TOTAL.ESTIMATED.PROCUREMENT.COST.EUR'})}
              </th>
              <th>
                {intl.formatMessage({id: 'TOTAL.CONTRACTED.CONSTRUCTION.COST.TRY'})}
              </th>
              <th>
                {intl.formatMessage({id: 'TOTAL.CONTRACTED.CONSTRUCTION.COST.EUR'})}
              </th>
              <th>
                {intl.formatMessage({id: 'TOTAL.CONTRACTED.CONSTRUCTION.COST/M2.EUR'})}
              </th>
              <th>
                {intl.formatMessage({id: 'TOTAL.BUDGET.EUR'})}
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-500'>
            <tr>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalEstimatedProcurementCostTRY !== null && totalEstimatedProcurementCostTRY !== undefined &&
                    formatTRY(totalEstimatedProcurementCostTRY.toFixed(2))
                  }
                </div>
              </td>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalEstimatedProcurementCostEUR !== null && totalEstimatedProcurementCostEUR !== undefined &&
                    formatEUR(totalEstimatedProcurementCostEUR.toFixed(2))
                  }
                </div>
              </td>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalContractedConstructionCostTRY !== null && totalContractedConstructionCostTRY !== undefined &&
                    formatEUR(totalContractedConstructionCostTRY.toFixed(2))
                  }
                </div>
              </td>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalContractedConstructionCostEUR !== null && totalContractedConstructionCostEUR !== undefined &&
                    formatEUR(totalContractedConstructionCostEUR.toFixed(2))
                  }
                </div>
              </td>

              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalcontractedConstructionCostPerM2EUR !== null && totalcontractedConstructionCostPerM2EUR !== undefined &&
                    formatEUR(totalcontractedConstructionCostPerM2EUR.toFixed(2))
                  }
                </div>
              </td>
              <td className='text-end min-w-100px'>
                <div className='d-flex align-items-center'>
                  {totalBudgetEUR !== null && totalBudgetEUR !== undefined &&
                    formatEUR(totalBudgetEUR.toFixed(2))
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table> */}
        <div style={{margin:"20px"}}></div>
      </div>
      <ChecklistListPagination />
      {isLoading && <ChecklistListLoading />}
    </KTCardBody>
  )
}

export { CostTable }
