import {Column} from 'react-table'
import {RiskMatrixDescriptionCell} from './RiskMatrixDescriptionCell'
import {RiskMatrixActionsCell} from './RiskMatrixActionsCell'
import {RiskMatrixCustomHeader} from './RiskMatrixCustomHeader'
import {RiskMatrixModel} from '../../core/_models'
import { RiskMatrixVersionDateCell } from './RiskMatrixVersionDateCell'
import { RiskMatrixFileNameCell } from './RiskMatrixFileNameCell'
import { FormattedMessage } from 'react-intl'


var riskMatrixsColumns: ReadonlyArray<Column<RiskMatrixModel>> = [

  {
    Header: (props) => <RiskMatrixCustomHeader tableProps={props} title={<FormattedMessage id ="DESCRIPTION"/>} className='min-w-650px' />,
    id: 'description',
    Cell: ({...props}) => <RiskMatrixDescriptionCell riskMatrix={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <RiskMatrixCustomHeader tableProps={props} title={<FormattedMessage id ="VERSION.DATE.UC"/>} className='min-w-250px' />,
    id: 'versionDate',
    Cell: ({...props}) => <RiskMatrixVersionDateCell riskMatrix={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <RiskMatrixCustomHeader tableProps={props} title={<FormattedMessage id ="FILE.NAME"/>} className='min-w-250px' />,
    id: 'fileName',
    Cell: ({...props}) => <RiskMatrixFileNameCell filePath={props.data[props.row.index].filePath} />,
  },
  {
    Header: (props) => (
      <RiskMatrixCustomHeader tableProps={props}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <RiskMatrixActionsCell id={props.data[props.row.index].id} filePath={props.data[props.row.index].filePath } />,
  }
]


export {riskMatrixsColumns}
