import { FC } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
  site: SiteModel
}
const FinalDesignTenderDossierSubmissionToMoh: FC<Props> = ({ site }) => {

  var formattedDate = '';

  if(site.finalDesignAndTenderDossierSubmissionToMoH != null || site.finalDesignAndTenderDossierSubmissionToMoH != undefined){
     formattedDate = site.finalDesignAndTenderDossierSubmissionToMoH === '0001-01-01T00:00:00' ? '' : new Date(site.finalDesignAndTenderDossierSubmissionToMoH).toLocaleDateString('tr-TR');
  }

  return (
    <div className='d-flex align-items-center'>
      {formattedDate}
    </div>
  )
}
export { FinalDesignTenderDossierSubmissionToMoh }
