import { ParameterModel } from 'app/modules/parameter-management/parameters-list/core/_models'
import { getMinorsParameterTypeById } from 'app/modules/parameter-management/parameters-list/core/_requests'
import { getContracts, getContractsByStakeholderId } from 'app/modules/procurement-management/contract-list/core/_requests'
import { getLotsByContractId } from 'app/modules/procurement-management/lot-list/core/_requests'
import { getSitesByLotId } from 'app/modules/procurement-management/site-list/core/_requests'
import {useEffect, useState} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { useLang } from '_metronic/i18n/Metronici18n'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {getInstituionId, getUserId, getUserRole, initialQueryState, isNotEmpty, KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const ProjectReportsFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()

  const userId = getUserId();
  const role = getUserRole();
  const instituionId = getInstituionId();

  // const [subject, setSubject] = useState<string | undefined> ();
  const [reportType, setReportType] = useState<string | undefined> ();
  const [reportNo, setReportNo] = useState<string | undefined> ();

  // const [siteId, setSiteId] = useState<string | undefined> ();
  // const [MAFTypeId, setMAFTypeId] = useState<string | undefined> ();

  const enabledQueryAllContractList: boolean = (true) //role === "CEB"
  //const enabledQueryStakeholderContractList: boolean = (role === "Vendor" || role === "Authority")
  // const enabledQueryLotList: boolean = (contractId !== undefined && contractId !== "");
  // const enabledQuerySiteList: boolean = (lotId !== undefined && lotId !== "");

  // Data Reads
  /*const {
    data: stakeholderContracts,
  } = useQuery(
    `${QUERIES.CONTRACT_LIST}-contracts-for-file-upload-${instituionId}`,
    () => {
      return getContractsByStakeholderId(instituionId)
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      enabled: enabledQueryStakeholderContractList
    }
  )*/

  // const {
  //   data: allContracts,

  // } = useQuery(
  //   `${QUERIES.CONTRACT_LIST}-contracts-for-file-upload-${userId}`,
  //   () => {
  //     return getContracts()
  //   },
  //   {
  //     cacheTime: 0,
  //     onError: (err) => {
  //       console.error(err)
  //     },
  //     enabled: enabledQueryAllContractList
  //   }
  // )
  
  // const {
  //   data: lots,
  // } = useQuery(
  //   `${QUERIES.LOT_LIST}-lots-for-MAF-${contractId}`,
  //   () => {
  //     return getLotsByContractId(contractId)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQueryLotList,
  //     onError: (err) => {
  //       console.error(err)
  //     },
  //   }
  // )

  // const {
  //   data: sites,
  // } = useQuery(
  //   `${QUERIES.SITE_LIST}-sites-for-MAF-${lotId}`,
  //   () => {
  //     return getSitesByLotId(lotId)

  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuerySiteList,
  //     onError: (err) => {
  //       console.error(err)
  //     },
  //   }
  // )

  // const {
  //   data: MAFTypes,
  // } = useQuery(
  //   `${QUERIES.PARAMETER_LIST}-parameters-${"d1d314cd-dca6-478f-b1cc-6a30ea89409a"}`,
  //   () => {
  //     return getMinorsParameterTypeById("d1d314cd-dca6-478f-b1cc-6a30ea89409a") as Array<ParameterModel>
  //   },
  //   {
  //     cacheTime: 0,
  //     onError: (err) => {
  //       console.error(err)
  //     },
  //   }
  // )

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setReportType("");
    setReportNo("");
  }

  const filterData = () => {
    updateState({
      filter: { reportType: reportType, reportNo: reportNo}
    })
  }

  const lang = useLang();
  const intl = useIntl();

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        {<FormattedMessage id ="FILTER"/>}
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>{<FormattedMessage id ="FILTER.OPTIONS"/>}</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className="row ">
          <div className="col-6">
              {/* begin::Report Type */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>{<FormattedMessage id ="REPORT.CHOOSE.TYPE"/>} :</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  name='reportType'
                  data-hide-search='true'
                  onChange={(e) => setReportType(e.target.value)}
                  value={reportType}
                >
                  <option value={""} key="">
                    {intl.formatMessage({ id: "REPORT.CHOOSE.TYPE" })}
                  </option>
                  <option value="Expenditure Verification Report" key="Expenditure Verification Report">
                    {intl.formatMessage({ id: "EXPENDITURE.VERIFICATION.REPORT" })}
                  </option>
                  <option value="SGA" key="SGA">SGA</option>
                  <option value="GA" key="GA">GA</option>
                  <option value="other" key="other">{intl.formatMessage({ id: "OTHER" })}</option>
                </select>
              </div>
              {/* end::Report Type */}
            </div>
            <div className="col-6">
              {/* begin::Report No */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>{<FormattedMessage id ="REPORT.NO"/>} :</label>
                <input
                  placeholder={intl.formatMessage({ id: 'REPORT.NO' })}
                  type="text"
                  name="reportNo"
                  className={"form-control form-control-solid mb-3 mb-lg-0"}
                  autoComplete="off"
                  onChange={(e) => setReportNo(e.target.value)}
                  value={reportNo}
                />
              </div>
              {/* end::Report No */}
            </div>

            
          </div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              {<FormattedMessage id ="DISCARD"/>} 
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              {<FormattedMessage id ="FILTER"/>} 
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ProjectReportsFilter}
