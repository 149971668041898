/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Audio_VisualToolsModel} from '../../core/_models'

type Props = {
  audio_VisualTools: Audio_VisualToolsModel
}

const Audio_VisualToolsLinkCell: FC<Props> = ({audio_VisualTools}) => {

  return(
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a  className='text-gray-800 text-hover-primary mb-1'>
        {audio_VisualTools.link ? audio_VisualTools.link : ""}
      </a>
    
    </div>
  </div>     

)}
export {Audio_VisualToolsLinkCell}
