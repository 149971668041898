import { FC, useState } from 'react'
import { SiteModel } from '../../../core/_models'
type Props = {
    site: SiteModel
}
const NaturalGas: FC<Props> = ({ site }) => {

    var formattedDate = '';

    if (site.naturalGas != null || site.naturalGas != undefined) {
        formattedDate = site.naturalGas === '0001-01-01T00:00:00' ? '' : new Date(site.naturalGas).toLocaleDateString('tr-TR');
    }

    return (
        <div className='d-flex align-items-center'>
            {formattedDate}
        </div>
    )

}
export { NaturalGas }
