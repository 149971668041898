import axios from 'axios'
import fileDownload from 'js-file-download'
import { FinancialOverviewGraphicModel } from './_models'

const API_URL = "https://shifatr.org/api/financialoverview"

const getFinancialOverviewGraphic = async (lang:any): Promise<FinancialOverviewGraphicModel> => {
    return await axios.get(API_URL, {
        params: {
            lang: lang
        }
    });
}

const getFinancialOverviewProgressPayment = async (lang:any): Promise<FinancialOverviewGraphicModel> => {
    return await axios.get(API_URL+"/progresspayment", {
        params: {
            lang: lang
        }
    });
}

export {getFinancialOverviewGraphic, getFinancialOverviewProgressPayment  }