import { Dispatch, SetStateAction } from 'react';
import { ID, Response } from '../../../../../_metronic/helpers';

export type OnlineToolsModel = {
  id?: ID
  description?: string | any,
  versionDate?: boolean | any,
  link?: string | any,
  onlineToolsFile?: Blob | any,
  onlineToolsFileName?: string | any,
  filePath?: string | any,
  fileType?: string | any,
  createdDate?: string | any,
  createdBy?: string | any,
}
export type OnlineToolsQueryResponse = Response<Array<OnlineToolsModel>>


export type OnlineToolsModelFileModel = {
  OnlineToolsModelFile?: Blob | any,
  OnlineToolsModelFileName?: string | any,
  filePath?: string | any,
  fileType?: string | any,
}


export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDelete?: ID
  setItemIdForDelete: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  helpPageOn: boolean
  setHelpPageOn: Dispatch<SetStateAction<boolean>>
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDelete: () => { },
  isAllSelected: false,
  disabled: false,
  setHelpPageOn: () => {},
  helpPageOn: false,
}