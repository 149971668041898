import React, { FC } from "react";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { Field, ErrorMessage } from "formik";
import { DesignPhaseTable } from "../../../tables/design-phase-table/DesignPhaseTable";

const DesignPhase: FC = () => {
  return (
    <DesignPhaseTable/>
  )
};

export { DesignPhase };
