import axios, { AxiosResponse } from 'axios'
import { AllCitiesResponse, DashboardKPISiteInfo, GetSiteCountModel, SiteModel, SiteQueryResponse, SiteResponse } from './_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import fileDownload from 'js-file-download'

const API_URL = "https://shifatr.org/api/site"

const getSites = async (query: string): Promise<SiteQueryResponse | undefined> => {
  return await axios
    .get(`${API_URL}?${query}`)
    .then((response: Response<SiteQueryResponse>) => response.data)

}

const getSitesForMap = async (): Promise<Array<SiteModel> | undefined> => {
  return await axios
    .get(`${API_URL}/sitesformap`)
    .then((response: Response<Array<SiteModel>>) => response.data)

}

const getMHCSiteById = (id: ID): Promise<SiteModel | undefined> => {
  return axios
    .get(`${API_URL}/mhc/${id}`)
    .then((response: Response<SiteModel>) => response.data)
}
const getPTRSiteById = (id: ID): Promise<SiteModel | undefined> => {
  return axios
    .get(`${API_URL}/ptr/${id}`)
    .then((response: Response<SiteModel>) => response.data)
}
const getEMHCSiteById = (id: ID): Promise<SiteModel | undefined> => {
  return axios
    .get(`${API_URL}/emhc/${id}`)
    .then((response: Response<SiteModel>) => response.data)
}
const getHospitalSiteById = (id: ID): Promise<SiteModel | undefined> => {
  return axios
    .get(`${API_URL}/hospital/${id}`)
    .then((response: Response<SiteModel>) => response.data)
}

const createSite = (parameter: SiteModel): Promise<SiteModel | undefined> => {
  return axios
    .post(API_URL, parameter)
    .then((response: AxiosResponse<Response<SiteModel>>) => response.data)
    .then((response: Response<SiteModel>) => response.data)
}

const updateSite = (parameter: SiteModel): Promise<SiteModel | undefined> => {
  return axios
    .put(`${API_URL}/${parameter.id}`, parameter)
}

const deleteMHCSite = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/mhc/${parameterId}`).then(() => { })
}

const deleteEMHCSite = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/emhc/${parameterId}`).then(() => { })
}

const deletePTRSite = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/ptr/${parameterId}`).then(() => { })
}

const deleteHospitalSite = (parameterId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/hospital/${parameterId}`).then(() => { })
}

const getSitesCount = (): Promise<GetSiteCountModel | undefined> => {
  return axios.get(`${API_URL}/sitecount`)
  .then((response: Response<GetSiteCountModel>) => response.data)
}

const getDashboardKPISitesInfo = (cityId: string): Promise<DashboardKPISiteInfo | undefined> => {
  return axios.get(`${API_URL}/siteinfo/${cityId}`)
  .then((response: Response<DashboardKPISiteInfo>) => response.data)
}

const getSitesByCityId = (parameterId: ID): Promise<SiteResponse | undefined> => {
  return axios.get(`${API_URL}/sitesbycity/${parameterId}`)
  .then((response: Response<SiteResponse>) => response.data)
}
const getSitesByCityId2 = (parameterId: ID): Promise<SiteResponse | undefined> => {
  return axios.get(`${API_URL}/sitesbycity2/${parameterId}`)
  .then((response: Response<SiteResponse>) => response.data)
}

const getAllCities = (): Promise<Array<AllCitiesResponse >| undefined> => {
  return axios.get(`${API_URL}/allcities`)
  .then((response: Response<Array<AllCitiesResponse>>) => response.data)
}

const getHelpPdf = (id: number): any => {
  return axios
    .get(`${API_URL}/getHelpDocument/${id}`, {responseType: 'blob'})
    .then((d: any) => d.data)
}

const getChecklistAsPdf = (query: string, siteType:string) => {
  console.log(query)
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/pdfreport?${query}&sitetype=${siteType}`,
  }).then((response) => {
    console.log(response)
    fileDownload(response.data, "Report.pdf", "application/pdf");
  })
}

const getChecklistAsExcel = (query: string, siteType: string) => {
  return axios({
    responseType: "blob",
    method: "GET",
    url: `${API_URL}/excelreport?${query}&sitetype=${siteType}`,
  }).then((response) => {
    fileDownload(response.data, "Report.xlsx");
  })
}

export { getSites,getSitesByCityId,getSitesByCityId2, getSitesCount, getEMHCSiteById, getMHCSiteById, getHospitalSiteById, getPTRSiteById, 
         createSite, updateSite, deleteMHCSite, deleteEMHCSite, deleteHospitalSite, deletePTRSite, getSitesForMap,  getDashboardKPISitesInfo,getAllCities, getHelpPdf, getChecklistAsPdf, getChecklistAsExcel }