import React, { FC, useEffect, useState } from 'react'
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import { TurkeyMapSVG } from './TurkeyMapSVG';
import './SvgMap.css';
import { useIntl } from 'react-intl'
import { AllCitiesResponse } from 'app/modules/site-management/sites-list/core/_models';
import { getDashboardKPISitesInfo } from 'app/modules/site-management/sites-list/core/_requests';
import { ThemeModeComponent } from "../../../../_metronic/assets/ts/layout/ThemeMode";
import { useThemeMode } from '_metronic/partials/layout/theme-mode/ThemeModeProvider';

type Props = {
    setCityId: React.Dispatch<React.SetStateAction<string>>,
    cities: Array<AllCitiesResponse>

}

const SvgMap: React.FC<Props> = ({ setCityId, cities }) => {
    const trEN = useIntl()
    let isCitySelected: boolean = false;
    const {mode} = useThemeMode()
    const [cityName, setCityName] = useState<string>(trEN.formatMessage({ id: "CHOOSE.A.PROVINCE" }));
    const [permanentCityName, setPermanentCityName] = useState<string>(trEN.formatMessage({ id: "CHOOSE.A.PROVINCE" }));
    const [cityIdState, setCityIdState] = useState<string>("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupLocation, setPopupLocation] = useState([0,0])
    const [total_New_Construction, setTotal_New_Construction] = useState(0)
    const [total_MHC_EMHC_Rehabilitation, setTotal_MHC_EMHC_Rehabilitation] = useState(0)
    const [total_PTR_Renovation, setTotal_PTR_Renovation] = useState(0)
    const [total_Hospital_Rehabilitation, setTotal_Hospital_Rehabilitation] = useState(0)
    console.log("cityId:", cityIdState)
    useEffect(() => {

    }, [setCityId, setCityName]);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };



    const HandleLocationClick = async (location: any) => {
        let siteInfo = await getDashboardKPISitesInfo(location.target.id)
        if(siteInfo?.totalMhcCount != undefined && siteInfo.totalMhcCount != null) {
            setTotal_New_Construction(siteInfo?.totalMhcCount)
        }

        if(siteInfo?.totalEmhcCount != undefined && siteInfo.totalEmhcCount != null) {
            setTotal_MHC_EMHC_Rehabilitation(siteInfo?.totalEmhcCount)
        }

        if(siteInfo?.totalPtrCount != undefined && siteInfo.totalPtrCount != null) {
            setTotal_PTR_Renovation(siteInfo?.totalPtrCount)
        }

        if(siteInfo?.totalHospitalCount != undefined && siteInfo.totalHospitalCount != null) {
            setTotal_Hospital_Rehabilitation(siteInfo?.totalHospitalCount)
        }

        setCityId(location.target.id);
        setCityIdState(location.target.id);
        setPermanentCityName(location.target.attributes[1].nodeValue);
        isCitySelected = true;
        const element = document.getElementById(location.target.id);
        setPopupLocation([location.clientX, location.clientY])
        if (element) {
            openPopup();
        } else {
            console.error(`Element with ID ${location.target.id} not found.`);
        }
    };
    

    const HandleMouseHover = (location: any) => {
        setCityName(location.target.attributes[1].nodeValue)
    }

    const ResetCityName = () => {
        setCityName(permanentCityName)
    }
    const intl = useIntl()

    function turkishToLowerCase(str: string): string {
        const turkishChars: { [key: string]: string } = {
            'A': 'a',
            'B': 'b',
            'C': 'c',
            'Ç': 'ç',
            'D': 'd',
            'E': 'e',
            'F': 'f',
            'G': 'g',
            'Ğ': 'ğ',
            'H': 'h',
            'I': 'ı',
            'İ': 'i',
            'J': 'j',
            'K': 'k',
            'L': 'l',
            'M': 'm',
            'N': 'n',
            'O': 'o',
            'Ö': 'ö',
            'P': 'p',
            'R': 'r',
            'S': 's',
            'Ş': 'ş',
            'T': 't',
            'U': 'u',
            'Ü': 'ü',
            'V': 'v',
            'Y': 'y',
            'Z': 'z'
        };
        return str.replace(/[A-ZÇĞIİÖŞÜ]/g, function (char) {
            return turkishChars[char] || char.toLowerCase();
        });
    }

    const customStyle = {
        backgroundImage: 'linear-gradient(to bottom, #ff0000 0%, #ff0000 33%, #ffffff 33%, #ffffff 100%)',
    };

    return (

        <div>
            {isPopupOpen && (
                <div className="popup" style={{left:popupLocation[0], top:popupLocation[1]+100, backgroundColor: mode == "dark" ? "#13263C" : "white"}}>
                <div className="popup-content">
                    <span className="close" onClick={closePopup}>&times;</span>
                    <p>{intl.formatMessage({ id: 'DASHBOARD.MHC' })}: {total_New_Construction}</p>
                    <p>{intl.formatMessage({ id: 'DASHBOARD.EMHC' })}: {total_MHC_EMHC_Rehabilitation }</p>
                    <p>{intl.formatMessage({ id: 'DASHBOARD.PTR' })}: {total_PTR_Renovation}</p>
                    <p>{intl.formatMessage({ id: 'DASHBOARD.HOSPITAL' })}: {total_Hospital_Rehabilitation}</p>
                </div>
                </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <h1 style={{ padding: 20, textAlign: "center" }}>
                    {cityName}
                </h1>
                {
                    cityName !== trEN.formatMessage({ id: "CHOOSE.A.PROVINCE" }) && cityName !== "Türkiye" &&
                    <div style={{ paddingTop: "14px" }}>
                        {["Kahramanmaraş", "Şanlıurfa", "Gaziantep", "Kilis", "Hatay", "Osmaniye", "Adana", "Diyarbakır", "Elazığ", "Malatya", "Adıyaman"].includes(cityName) &&
                            <svg width="50" height="50">
                                <rect x="10" y="10" width="20" height="20" fill="yellow" />
                            </svg>
                        }
                        {["Mersin", "Samsun", "Konya", "Ankara", "İzmir", "Bursa", "Sakarya", "Kocaeli", "İstanbul"].includes(cityName) &&
                            <svg width="50" height="50">
                                <rect x="10" y="10" width="20" height="20" fill="green" />
                            </svg>
                        }
                        {["Kayseri","Mardin","Batman"].includes(cityName) &&
                            <svg width="50" height="50">
                                <rect x="10" y="10" width="20" height="20" fill="blue" />
                            </svg>
                        }
                    </div>
                }
            </div>

            <div style={{ marginBottom: "10px" }}>
                <select style={{
                    backgroundImage: ["1", "2", "21", "23", "27", "31", "44", "46", "63", "79", "80"].includes(cityIdState) ? 
                    'linear-gradient(to right, transparent 46%, yellow 46%, yellow 53%, transparent 53%)' : 
                    ["33", "55", "42", "6", "35", "16", "54", "41", "34"].includes(cityIdState) ?
                    'linear-gradient(to right, transparent 46%, green 46%, green 53%, transparent 53%)' :
                    ["38","72","47"].includes(cityIdState) ?
                     'linear-gradient(to right, transparent 46%, blue 46%, blue 53%, transparent 53%)' :
                    'linear-gradient(to right, transparent 0%, white 0%, white 0%, transparent 0%)',
                    backgroundSize: '340px 55%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left'
                }}
                    className="form-control select2" value={cityIdState} id="kt_select2_1" onChange={(selectedCity) => {
                        setCityId(selectedCity.target.value);
                        setCityIdState(selectedCity.target.value);
                        const selectedCityName = selectedCity.target.selectedOptions[0].text;
                        if (selectedCity.target.value === "0") {
                            const defaultCityName = trEN.formatMessage({ id: "CHOOSE.A.PROVINCE" });
                            setPermanentCityName(defaultCityName);
                            setCityName(defaultCityName);
                        } else {
                            const selectedCityName: string = selectedCity.target.selectedOptions[0].text;
                            const formattedCityName: string = selectedCityName.charAt(0).toUpperCase() + turkishToLowerCase(selectedCityName.slice(1));
                            const cityNameWithoutStar = formattedCityName.replace("*", "");
                            setPermanentCityName(cityNameWithoutStar);
                            setCityName(cityNameWithoutStar);
                        }
                    }}>
                    <option style={customStyle} value="0">Türkiye</option>
                    {
                        cities.map((City: AllCitiesResponse) => (
                            <option
                                key={City.id} value={City.id} >

                                <span>
                                    {City.name}
                                    {["1", "2", "21", "23", "27", "31", "44", "46", "63", "79", "80", "38","72","47"].includes(String(City.id)) &&
                                        <span>*</span>
                                    }
                                </span>

                            </option>
                        ))
                    }
                </select>
            </div>

            <SVGMap map={TurkeyMapSVG} role="radiogroup" onLocationClick={HandleLocationClick} onLocationMouseOver={HandleMouseHover} onLocationMouseOut={ResetCityName} />
            <svg width="100%" height="50">
                <rect x="10" y="10" width="20" height="20" fill="green" />
                <text x="40" y="30" fill={mode === "dark" ? "white" : "black"}> {intl.formatMessage({ id: 'MAP.LEGEND.PROJECT.CITY' })}</text>
            </svg>
            <svg width="100%" height="50">
                <rect x="10" y="10" width="20" height="20" fill="yellow" />
                <text  x="40" y="30" fill={mode === "dark" ? "white" : "black"}> {intl.formatMessage({ id: 'MAP.LEGEND.EARTHQUAKE.CITY' })}</text>
            </svg>
            <svg width="100%" height="50">
                <rect x="10" y="10" width="20" height="20" fill="blue" />
                <text  x="40" y="30" fill={mode === "dark" ? "white" : "black"}> {intl.formatMessage({ id: 'DISASTER.ZONE' })}</text>
            </svg>
            {/* <svg width="100%" height="50">
                <rect x="10" y="10" width="20" height="20" fill="orange" />
                <text x="40" y="30" fill="black">{intl.formatMessage({ id: 'MAP.LEGEND.EARTHQUAKEANDPROJECT.CITY' })}</text>
            </svg> */}
        </div>
    )
}
export { SvgMap }