import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {Audio_VisualToolsListHeader} from './components/header/Audio_VisualToolsListHeader'
import {Audio_VisualToolsEditModal} from './audio_visual-tools-modal/Audio_VisualToolsEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { Audio_VisualToolsTable } from './table/Audio_VisualToolsTable'
import { Audio_VisualToolsDeleteModal } from '../Audio_VisualToolsDeleteModal'

const Audio_VisualToolsList = () => {
  const {itemIdForUpdate, itemIdForDelete, helpPageOn, setHelpPageOn} = useListView()
  // const close = () => {
  //   setHelpPageOn(false)
  // }
  return (
    <>
      <KTCard>
        <Audio_VisualToolsListHeader />
        <Audio_VisualToolsTable />
      </KTCard>
      {/* {helpPageOn && <HelpModal closeFunction={() => close} />} */}
      {itemIdForUpdate !== undefined && <Audio_VisualToolsEditModal />}
      {itemIdForDelete !== undefined && <Audio_VisualToolsDeleteModal />}
    </>
  )
}

const Audio_VisualToolsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <Audio_VisualToolsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {Audio_VisualToolsListWrapper}
