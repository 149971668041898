import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import { getChecklistAsPdf, getChecklistAsExcel } from '../../core/_requests'
import { MHCListFilter } from './MHCListFilter'
import { CiapFilter } from './CiapFilter'


const CiapListToolbar = () => {

  const onDownloadPdf = () => {
    getChecklistAsPdf("69a24f46-f098-4c4f-81a0-ab73fc424f84")
  }
  const onDownloadExcel = () => {
    getChecklistAsExcel("69a24f46-f098-4c4f-81a0-ab73fc424f84")
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <CiapFilter/>
      {/* <MHCListFilter /> */}
      { <button type='button' className='btn btn-primary me-3' onClick={onDownloadPdf}> {<FormattedMessage id = 'DOWNLOAD.CHECKLIST.PDF'/>} </button> }
      <button type='button' className='btn btn-primary' onClick={onDownloadExcel}> {<FormattedMessage id = 'DOWNLOAD.CHECKLIST.EXCEL'/>} </button>
    </div>
  )
}

export {CiapListToolbar}
