import { FormattedMessage } from 'react-intl'
import {Audio_VisualToolsListToolbar} from './Audio_VisualToolsListToolbar'

const Audio_VisualToolsListHeader = () => {

  return (
    <div className='card-header border-0 pt-6'>
      <h2 className='fw-bolder'>{<FormattedMessage id ="AUDIO_VISUAL.TOOLS.LIST"/>}</h2>
      {/* <ParametersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {<Audio_VisualToolsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {Audio_VisualToolsListHeader}
