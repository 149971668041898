import axios, { AxiosResponse } from 'axios'
import { BarChartContractModel, ProgressPaymentBeforeValues, ProgressPaymentModel, ProgressPaymentQueryResponse, ProgressPaymentReportModel } from './_models'
import { ID, Response } from "../../../../../_metronic/helpers"
import fileDownload from 'js-file-download'

const API_URL_PROGRESS_PAYMENT = "https://shifatr.org/api/progress-payment"
const API_URL_PROGRESS_PAYMENT_FileUpload = "https://shifatr.org/api/file/procurement"

const API_URL_PROGRESS_PAYMENT_REPORT = "https://shifatr.org/api/progress-payment-report"

const getProgressPayments = async (query: string): Promise<ProgressPaymentQueryResponse | undefined> => {
  return  await axios
    .get(`${API_URL_PROGRESS_PAYMENT}/list?${query}`)
    .then((response: Response<ProgressPaymentQueryResponse>) => response.data)

}

const getProgressPaymentById = async (progressPaymetnId: ID): Promise<ProgressPaymentModel | undefined> => {
  return  await axios
    .get(`${API_URL_PROGRESS_PAYMENT}/${progressPaymetnId}`)
    .then((response: Response<ProgressPaymentModel>) => response.data)

}

const getProgressPaymenttoBarchartById = async (progressPaymetnId: ID): Promise<BarChartContractModel[] | undefined> => {
  return  await axios
    .get(`${API_URL_PROGRESS_PAYMENT}/contract-barchart/${progressPaymetnId}`)
    .then((response: Response<BarChartContractModel[]>) => response.data)

}














const getProgressPaymentsByContractId = async (progressPaymentId: ID, progressPaymentSequence:number): Promise<ProgressPaymentModel[] | undefined> => {
  try {
    const response = await axios.get(`${API_URL_PROGRESS_PAYMENT}/contract-site/${progressPaymentId}/${progressPaymentSequence}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching progress payments:', error);
    return undefined;
  }
}

const getProgressPaymentBeforeValues = async (contractId: ID): Promise<ProgressPaymentBeforeValues | undefined> => {
  return  await axios
    .get(`${API_URL_PROGRESS_PAYMENT}/before/${contractId}`)
    .then((response: Response<ProgressPaymentBeforeValues>) => response.data)

}

const updateProgressPaymentReport = (progressPaymentReport: ProgressPaymentReportModel): Promise<ProgressPaymentReportModel | undefined> => {
  return axios
    .put(`${API_URL_PROGRESS_PAYMENT_REPORT}/${progressPaymentReport.id}`, progressPaymentReport)
}

const getProgressPaymentReportById = async (progressPaymentId: ID): Promise<ProgressPaymentReportModel | undefined> => {
  return  await axios
    .get(`${API_URL_PROGRESS_PAYMENT_REPORT}/${progressPaymentId}`)
    .then((response: Response<ProgressPaymentReportModel>) => response.data)

}

export {getProgressPaymentsByContractId,getProgressPaymentReportById,getProgressPaymentById, getProgressPayments, 
  updateProgressPaymentReport, getProgressPaymentBeforeValues,getProgressPaymenttoBarchartById}