import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import { FileInfoModel, initialListView, ListViewContextProps } from './_models'


const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [progressPaymentIdForUpdate, setProgressPaymentIdForUpdate] = useState<ID>(initialListView.progressPaymentIdForUpdate)
  const [progressPaymentIdForReportUpdate, setProgressPaymentIdForReportUpdate] = useState<ID>(initialListView.progressPaymentIdForReportUpdate)
  const [isReportPage, setIsReportPage] = useState<ID>(initialListView.isReportPage)
  const [isUploadPage, setIsUploadPage] = useState<ID>(initialListView.isUploadPage)
  const [progressPaymentId, setProgressPaymentId] = useState<ID>(initialListView.progressPaymentId)
  const [isChoiceContractTypePage, setIsChoiceContractTypePage] = useState<ID>(initialListView.isChoiceContractTypePage)
  const [isChoiceContractPage, setIsChoiceContractPage] = useState<ID>(initialListView.isChoiceContractPage)
  const [itemIdForDelete, setItemIdForDelete] = useState<ID>(initialListView.itemIdForDelete)
  const [itemProgressPaymentSequenceDelete, setItemProgressPaymentSequenceDelete] = useState<any>(initialListView.itemProgressPaymentSequenceDelete)
  const [procurementTypeId, setProcurementTypeId] = useState<ID>(initialListView.procurementTypeId)
  const [contractId, setContractId] = useState<ID>(initialListView.contractId)
  const [contractSiteData, setContractSiteData] = useState<any>(initialListView.contractSiteData)
  const [siteTypeForUpdate, setSiteTypeForUpdate] = useState<string>(initialListView.siteTypeForUpdate)
  const [siteTypeForFormik, setSiteTypeForFormik] = useState<string>(initialListView.siteTypeForFormik)
  const [contractName, setContractName] = useState<string>(initialListView.contractName)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [fileModelList, setFileModelList] = useState<Array<FileInfoModel>>(initialListView.fileModelList)
  const [mainFile, setMainFile] = useState<FileInfoModel>(initialListView.mainFile)
  const [helpPageOn, setHelpPageOn] = useState<boolean>(initialListView.helpPageOn)

  return (
    <ListViewContext.Provider
        value={{
        fileModelList, 
        setFileModelList,
        mainFile, 
        setMainFile,
        siteTypeForUpdate, 
        setSiteTypeForUpdate,
        isChoiceContractTypePage, 
        setIsChoiceContractTypePage,
        isChoiceContractPage, 
        setIsChoiceContractPage,
        progressPaymentIdForUpdate,
        setProgressPaymentIdForUpdate,
        progressPaymentIdForReportUpdate, 
        setProgressPaymentIdForReportUpdate,
        contractName, 
        setContractName,
        itemIdForDelete, 
        setItemIdForDelete,
        itemProgressPaymentSequenceDelete,
        setItemProgressPaymentSequenceDelete,
        siteTypeForFormik, 
        setSiteTypeForFormik,
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        progressPaymentId, 
        setProgressPaymentId,
        procurementTypeId, 
        setProcurementTypeId,
        contractId, 
        setContractId,
        contractSiteData,
        setContractSiteData,
        disabled,
        isAllSelected,
        isReportPage, 
        setIsReportPage,
        isUploadPage,
        setIsUploadPage,
        helpPageOn,
        setHelpPageOn,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
