import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { QUERIES } from '../../../../../../../_metronic/helpers'
import { getMinorsParameterTypeById } from '../../../../../parameter-management/parameters-list/core/_requests'
import { useQueryResponse } from '../../../core/QueryResponseProvider'
import { SiteModel } from '../../../core/_models'


type Props = {
    site: SiteModel
}

const SiteProjectTypeInfoCell: FC<Props> = ({ site }) => (

    <div className='d-flex align-items-center'>
        {site.neighborhood}
    </div>

)
export { SiteProjectTypeInfoCell }
